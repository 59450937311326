import { useEffect } from 'react';

// * Use to track the vertical scrolling position of an element
// * Touch added to the liteners for touchscreen devices

// pass in action to perform if elem is not null
const useElemPositionListener = (elem, actionPerformed) => {
  useEffect(() => {
    if (elem !== null) { 
      window.addEventListener("scroll", actionPerformed);
      window.addEventListener("touchmove", actionPerformed);

      // cleans up the event listeners when returning
      return () => {
        window.removeEventListener("scroll", actionPerformed);
        window.removeEventListener("touchmove", actionPerformed);
      }
    }
  }, []);
}

export default useElemPositionListener;