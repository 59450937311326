import React from 'react';
import Calculator from './Calculator';
import './calculator.css';

function AffordabilityCalculatorModal(props) {
    const lang = document.documentElement.lang;
    const { localeValue } = props;
    const btnText = localeValue === "en" ? "Help" : "Ayuda";
    const openATHelpModal = () => {
        props.openATHelpModal(true);
    }
    return (
        <div className="calculator-modal">
            <div className="calculator-modal__utilities">
                <button
                    type="button"
                    className="calculator-modal__utilities-button"
                    onClick={openATHelpModal}
                >
                    {btnText}
                </button>
            </div>
            <Calculator
                closeCici={props.closeCici}
                isCiciClose={props.isCiciClose}
                close={props.close}
                tool={props.onHelpClick}
                contactUs={props.contactUs}
                sendMessage={props.sendMessage}
                sendLoggingEventToBackend={props.sendLoggingEventToBackend}
                sendEventToBackend={props.sendEventToBackend}
            />
        </div>
    )
}

export default AffordabilityCalculatorModal;