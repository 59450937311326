import React from "react";
import useDollarAmountFormatter from "../hooks/useDollarAmountFormatter";
import config from "./config";
import { configURLs } from "../Constants";
import useConsumerName from "./hooks/useConsumerName";
import useAppId from "./hooks/useAppId";
import useAffordabilityDetermination from "./hooks/useAffordabilityDetermination";

function ReviewApp(props) {
  const { getFormattedDollarAmount } = useDollarAmountFormatter();
  const lang = document.documentElement.lang;
  const year = props.selectedYear || "2024";
  const { AppConsumerName, setAppConsumerName } = useConsumerName();
  const { AppId, SetAppId } = useAppId();

  const today = new Date();
  const cutoffDate = new Date(today.getFullYear(), 10, 30);
  const showEditIcon = today <= cutoffDate;

  const { calculations, setCalculations } = props;
  const { localeValue } = props;


  let contributionsCollectedDisplayText;

  switch (props.contributionsCollected) {
    case 26:
      contributionsCollectedDisplayText = localeValue &&
        config.copy[localeValue].review &&
        config.copy[localeValue].review.every2Weeks;
      break;
    case 52:
      contributionsCollectedDisplayText = localeValue &&
        config.copy[localeValue].review &&
        config.copy[localeValue].review.weekly;
      break;
    case 24:
      contributionsCollectedDisplayText = localeValue &&
        config.copy[localeValue].review &&
        config.copy[localeValue].review.twiceAMonth;
      break;
    case 12:
      contributionsCollectedDisplayText = localeValue &&
        config.copy[localeValue].review &&
        config.copy[localeValue].review.monthly;
      break;
    case 4:
      contributionsCollectedDisplayText = localeValue &&
        config.copy[localeValue].review &&
        config.copy[localeValue].review.quarterly;
      break;
    case 1:
      contributionsCollectedDisplayText = localeValue &&
        config.copy[localeValue].review &&
        config.copy[localeValue].review.yearly;
      break;
    default:
      contributionsCollectedDisplayText = "Error";
  }

  const formatCurrencyWithoutTrailingZeros = (amount) => {
    let numberValue = parseFloat(amount.replace(/[$,]/g, ""));
    return `$${numberValue.toLocaleString(undefined, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    })}`;
  };

  const affordabilityIndividualResult = useAffordabilityDetermination(
    props.affordabilityIndividual,
    "individual",
    props.localeValue
  );

  const affordabilityFamilyResult = useAffordabilityDetermination(
    props.affordabilityFamily,
    "family",
    props.localeValue
  );

  const addToCalculationObj = () => {
    const newCalculation = {
      name: AppConsumerName,
      applicationId: AppId,
      householdIncome: props.annualIncome,
      amount: props.yesNoSelection === "yes" ? props.selfContribution : props.familyContribution,
      frequency: contributionsCollectedDisplayText,
      planYear: year,
      affordabilityDetermination: props.yesNoSelection === "yes" ?
        affordabilityIndividualResult.component :
        affordabilityFamilyResult.component,
      isEmployee: props.yesNoSelection === "yes" ? true : false,
    };
    setCalculations((prevCalculationObj) => [
      ...prevCalculationObj,
      newCalculation,
    ]);
  };

  const handleClick = () => {
    addToCalculationObj();
    props.stepUp();
  };

  function lowercaseFirstLetter(string) {
    if (!string) return string;
    return string.charAt(0).toLowerCase() + string.slice(1);
  }

  const displayText = lowercaseFirstLetter(contributionsCollectedDisplayText);

  const title = localeValue && config.copy[localeValue].review && config.copy[localeValue].review.heading;
  const copy = localeValue && config.copy[localeValue].review && config.copy[localeValue].review.paragraph1;
  const coverageYearTitle = localeValue && config.copy[localeValue].review && config.copy[localeValue].review.item5;
  /** year */
  const householdIncomeTitle = localeValue && config.copy[localeValue].review && config.copy[localeValue].review.item1 + " " + year
  const householdIncome = formatCurrencyWithoutTrailingZeros(props.annualIncome);
  const coverageCostTitle = localeValue && config.copy[localeValue].review && config.copy[localeValue].review.item3;
  const getIsEmployeee = () => {
    if(props.yesNoSelection === "yes"){
      if(props.localeValue === "es") return `${AppConsumerName} es la empleada`;
      return `${AppConsumerName} is the employee`
    }
    if(props.localeValue === 'es') return `${AppConsumerName} no es la empleada`;
    return `${AppConsumerName} is not the employee`;
  }
  const getAmount = () => {
    if(props.yesNoSelection === "yes"){
      if(props.localeValue === "es") return `${formatCurrencyWithoutTrailingZeros(props.selfContribution)} para la cobertura solo para el empleado`;
      return `${formatCurrencyWithoutTrailingZeros(props.selfContribution)} for employee-only coverage`;
    }
    if(props.localeValue === 'es') return `${formatCurrencyWithoutTrailingZeros(props.familyContribution)}  para la cobertura familiar`;
    return `${formatCurrencyWithoutTrailingZeros(props.familyContribution)} for family coverage`;
  }
  const getFrequency = () => {
    if(props.localeValue === "es") return `Pagado ${displayText}`;
    return `Paid ${displayText}`
  }
  const calculateButtonText = localeValue && config.copy[localeValue].utilities && config.copy[localeValue].utilities.calculate
  const calculateButtonClickHandler = handleClick;
  const backButtonText = localeValue && config.copy[localeValue].utilities && config.copy[localeValue].utilities.back;
  const backButtonClickHandler = props.stepDown;
  const coverageYearEditButtonHandler = () => props.goToStep(8);
  const householdIncomeEditButtonHandler = () => props.goToStep(3);
  const coverageCostEditButtonHandler = () => props.goToStep(9);

  return (
    <ReviewAppUiComponent
    title={ title }
    copy1={ copy }
    coverageYearTitle={ coverageYearTitle }
    coverageYear={ year }
    householdIncomeTitle={ householdIncomeTitle }
    householdIncome={ householdIncome }
    coverageCostTitle={ coverageCostTitle }
    isEmployee={ getIsEmployeee() }
    amount={ getAmount() }
    frequency={ getFrequency() }
    coverageYearEditButtonHandler={coverageYearEditButtonHandler}
    householdIncomeEditButtonHandler={householdIncomeEditButtonHandler}
    coverageCostEditButtonHandler={coverageCostEditButtonHandler}
    calculateButtonText={ calculateButtonText }
    calculateButtonClickHandler={ calculateButtonClickHandler }
    backButtonText={ backButtonText }
    backButtonClickHandler={ backButtonClickHandler } 
  />
  )
}

function ReviewAppUiComponent({
  title,
  calculateButtonText,
  calculateButtonClickHandler,
  backButtonText,
  backButtonClickHandler,
  coverageYearEditButtonHandler,
  householdIncomeEditButtonHandler,
  coverageCostEditButtonHandler,
  copy1,
  coverageYearTitle,
  coverageYear,
  householdIncomeTitle,
  householdIncome,
  coverageCostTitle,
  isEmployee,
  amount,
  frequency
}) {
  const editIconSource = configURLs.url.BASE_URL + "/images/botImages/edit-icon.svg"
  return (
    <div className="calculator-modal__review">
      <h2 className="calculator-modal__review-title">{title}</h2>
      <div className="calculator-modal__review-copy">
        {copy1}
      </div>

      {/* coverage year */}
      <div className="calculator-modal__review-item">
        <div className="calculator-modal__review-item-details">
          <div className="calculator-modal__review-item-name">{coverageYearTitle}</div>
          <div className="calculator-modal__review-item-value">{coverageYear}</div>
        </div>
        <div className="calculator-modal__review-item-actions">
          <button className="calculator-modal__review-edit-button" type="button" onClick={coverageYearEditButtonHandler}>
            <img src={editIconSource} alt="edit icon" />
          </button>
        </div>
      </div>

      {/* household income */}
      <div className="calculator-modal__review-item">
        <div className="calculator-modal__review-item-details">
          <div className="calculator-modal__review-item-name">{householdIncomeTitle}</div>
          <div className="calculator-modal__review-item-value">{householdIncome}</div>
        </div>
        <div className="calculator-modal__review-item-actions">
          <button className="calculator-modal__review-edit-button" type="button" onClick={householdIncomeEditButtonHandler}>
            <img src={editIconSource} alt="edit icon" />
          </button>
        </div>
      </div>

      {/* coverage cost */}
      <div className="calculator-modal__review-item">
        <div className="calculator-modal__review-item-details">
          <div className="calculator-modal__review-item-name">{coverageCostTitle}</div>
          <div className="calculator-modal__review-item-value">{isEmployee}</div>
          <div className="calculator-modal__review-item-value">{amount}</div>
          <div className="calculator-modal__review-item-value">{frequency}</div>
        </div>
        <div className="calculator-modal__review-item-actions">
          <button className="calculator-modal__review-edit-button" type="button" onClick={coverageCostEditButtonHandler}>
            <img src={editIconSource} alt="edit icon" />
          </button>
        </div>
      </div>

      <div className="calculator-modal__buttons-container">
        <button
          onClick={calculateButtonClickHandler}
          type="button"
          className="calculator-modal__button calculator-modal__button--primary"
        >
          {calculateButtonText}
        </button>
        <button
          onClick={backButtonClickHandler}
          type="button"
          className="calculator-modal__button calculator-modal__button--secondary"
        >
          {backButtonText}
        </button>
      </div>
    </div>
  )
}

export default ReviewApp;
