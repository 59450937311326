import { useEffect } from "react";
import React from 'react';
import translations from "../translation";

function Sb260WizardOptInResultZeroPremiumPage({ onNext , lang, sendLoggingEventToBackend}) {
    const { zeroPremiumheading, zeroPremiumIntro, zeroPremiumBody , nonZeroPremiumCloseText} = translations[lang];

    useEffect(() => {
        sendLoggingEventToBackend({
            intentName: "PremiumZeroDollarSuccess",
            text: "PremiumZeroDollarSuccess"
            });
    },[sendLoggingEventToBackend])
    
    return (
        <div className="h-100 d-flex flex-column">
            <h2>{zeroPremiumheading}</h2>
             <p className='pb-5'>{zeroPremiumBody}</p>
            <div className='form-group mt-auto'>
                <button className='btn btn-block ac-pushButton' onClick={onNext}>{nonZeroPremiumCloseText}</button>
            </div>
        </div>
    )
}

export default Sb260WizardOptInResultZeroPremiumPage;