import React from 'react';
import PlanDetailsSection from '../components/PlanDetails/PlanDetails';
import translations from '../translation';

function Sb260WizardKeepPlanDetailsPage({ onNext, onBack, data, lang }) {
  const { keepheading, keepintro, keepPlanContinue, backText } = translations[lang];
  const plan = data && data.plan ? data.plan : '';


  return (
    <div className="h-100 d-flex flex-column">
      <h2>{keepheading}</h2>
      <p>{keepintro}</p>
      <PlanDetailsSection plan={plan} data={data} lang={lang} />
      <div className="form-group mt-auto">
        <button className="btn btn-block ac-pushButton mt-auto" onClick={onNext}>
          {keepPlanContinue}
        </button>
        <button className="btn btn-block btn-outline ac-pushButton" onClick={onBack}>
          {backText}
        </button>
      </div>
    </div>
  );
}

export default Sb260WizardKeepPlanDetailsPage;