import React, {useState, useEffect} from 'react';
import Tooltip from './Tooltip';
import config from './config';
import sendEventToDataLayer from "../datalayer-event-logger-for-transactions/sendEventToDataLayer";

function Intro(props) {

    const HOUSEHOLD_INCOME_TOOLTIP_TEXT = config.copy[document.documentElement.lang].intro.bullet1TooltipContent;
    const MINIMUM_VALUE_STANDARD_TOOLTIP_TEXT = config.copy[document.documentElement.lang].intro.bullet2TooltipContent;
    const lang = document.documentElement.lang;

    return (
        <>
            <h4 className="row">
                <span className="col-12 d-flex align-items-center font-weight-bold">
                    {config.copy[document.documentElement.lang].intro.heading}
                </span>
            </h4>
            <p className='font-weight-bold'>
                {config.copy[document.documentElement.lang].intro.subHeading}
            </p>
            <ul>
                <li id="bullet1">{config.copy[document.documentElement.lang].intro.bullet1}
                <Tooltip 
                    content={HOUSEHOLD_INCOME_TOOLTIP_TEXT} 
                    useDefaultIcon={true} 
                    maxWidth="300"
                    tooltipType="householdIncome"
                ></Tooltip>

                </li>
                <li id="bullet2">{config.copy[document.documentElement.lang].intro.bullet2}
                <Tooltip 
                    content={MINIMUM_VALUE_STANDARD_TOOLTIP_TEXT} 
                    useDefaultIcon={true} 
                    placement={"left"}
                    maxWidth="200"
                    mobilePlacement={"left"} 
                    tooltipType="householdIncomeStandard" >
                </Tooltip></li>
                <li>{config.copy[document.documentElement.lang].intro.bullet3}</li>
            </ul>

            <p>{config.copy[document.documentElement.lang].intro.outro}</p>
           
            <div className='form-group mt-auto'>
                <button onClick={() => { props.stepUp();
                    sendEventToDataLayer({
                        event: "chatbot",
                        eventDetails: {
                        category: "CiCi",
                        action: "Affordability Tool Clicks",
                        label: "Get Started"
                      }
                    });
                    props.sendLoggingEventToBackend({
                      intentName: lang ==="en" ? "affordabilityToolStarted": "affordabilityToolStartedSpanish",
                      text: lang === "en" ?"Affordability Tool Started": "Affordability Tool Started Spanish"
                    });
                 }} type="button" className='btn btn-block ac-pushButton style-default'>{config.copy[document.documentElement.lang].utilities.getStarted}</button>
            </div>
        </>
    )
}

export default Intro;