import React, { useEffect } from 'react';
import PlanCard from '../components/PlanCard/PlanCard';
import sendEventToDataLayer from '../../datalayer-event-logger-for-transactions/sendEventToDataLayer';
import { configURLs } from '../../Constants';
import sb260Config from '../config';
import translations from '../translation';

function Sb260WizardCancelPlanDetailsPage({ onBack, data, setCurrentPage, lang }) {
    const { cancelHeading, cancelIntro, cancelSubmitText, backText } = translations[lang];
    const getPlanLogoPath = (plan) => {
        if(plan.toLowerCase().indexOf('aetna') !== -1){
            return `${configURLs.url.BASE_URL}${sb260Config.PLAN_LOGO_URLS.AETNA}`;
        }
        if(plan.toLowerCase().indexOf('ambetter') !== -1){
            return `${configURLs.url.BASE_URL}${sb260Config.PLAN_LOGO_URLS.AMBETTER}`;
        }
        if(plan.toLowerCase().indexOf('anthem') !== -1){
            return `${configURLs.url.BASE_URL}${sb260Config.PLAN_LOGO_URLS.ANTHEM}`;
        }
        if(plan.toLowerCase().indexOf('balance') !== -1){
            return `${configURLs.url.BASE_URL}${sb260Config.PLAN_LOGO_URLS.BALANCE}`;
        }
        if(plan.toLowerCase().indexOf('blue') !== -1){
            return `${configURLs.url.BASE_URL}${sb260Config.PLAN_LOGO_URLS.BLUE_SHIELD}`;
        }
        if(plan.toLowerCase().indexOf('health-net') !== -1 || plan.toLowerCase().indexOf('healthnet') !== -1 || plan.toLowerCase().indexOf('health net') !== -1){
            return `${configURLs.url.BASE_URL}${sb260Config.PLAN_LOGO_URLS.HEALTH_NET}`;
        }
        if(plan.toLowerCase().indexOf('kaiser') !== -1){
            return `${configURLs.url.BASE_URL}${sb260Config.PLAN_LOGO_URLS.KAISER}`;
        }
        if(plan.toLowerCase().indexOf('la-care') !== -1 || plan.toLowerCase().indexOf('lacare') !== -1 || plan.toLowerCase().indexOf('la care') !== -1){
            return `${configURLs.url.BASE_URL}${sb260Config.PLAN_LOGO_URLS.LA_CARE}`;
        }
        if(plan.toLowerCase().indexOf('molina') !== -1){
            return `${configURLs.url.BASE_URL}${sb260Config.PLAN_LOGO_URLS.MOLINA}`;
        }
        if(plan.toLowerCase().indexOf('oscar') !== -1){
            return `${configURLs.url.BASE_URL}${sb260Config.PLAN_LOGO_URLS.OSCAR}`;
        }
        if(plan.toLowerCase().indexOf('sharp') !== -1){
            return `${configURLs.url.BASE_URL}${sb260Config.PLAN_LOGO_URLS.SHARP}`;
        }
        if(plan.toLowerCase().indexOf('valley') !== -1 || plan.toLowerCase().indexOf('vhp') !== -1){
            return `${configURLs.url.BASE_URL}${sb260Config.PLAN_LOGO_URLS.VHP}`;
        }
        if(plan.toLowerCase().indexOf('western') !== -1){
            return `${configURLs.url.BASE_URL}${sb260Config.PLAN_LOGO_URLS.WESTERN}`;
        }
        return `${configURLs.url.BASE_URL}${sb260Config.PLAN_LOGO_URLS.DEFAULT}`;
    }

    useEffect(() => {
            sendEventToDataLayer({
                event: "chatbot",
                eventDetails: {
                    name: "cici_sb260_cancel_authentication_success"
                }
    }
    )
    },[]);

    return (
        <div className="h-100 d-flex flex-column">
            <h2>{cancelHeading}</h2>
            <p>{cancelIntro}</p>
            <PlanCard planLogoPath={getPlanLogoPath(data.plan)}>
                <div className="m-auto"><strong>{data.plan}</strong></div>
            </PlanCard>
            <div className='form-group mt-auto'>
                <button className='btn btn-block ac-pushButton' type='button' onClick={() => {
                    setCurrentPage(3);
                }}>{cancelSubmitText}</button>
               {/* <button className='btn btn-block ac-pushButton' type='button' onClick={handleCancelClick}>{submitText}</button> */}
                <button className='btn btn-block btn-outline ac-pushButton' type='button' onClick={onBack}>{backText}</button>
            </div>
        </div>
    )
}

export default Sb260WizardCancelPlanDetailsPage;