import React, { useState , useEffect, useRef} from 'react';
import { configURLs } from "../Constants";
import { Tooltip as BootstrapTooltip, OverlayTrigger } from 'react-bootstrap';
import config from './config';
import { getLocaleValue } from './localeUtils';

function Tooltip(props) {
  const { content, children, width, height, placement, mobilePlacement } = props;
  const [showTooltip, setShowTooltip] = useState(false);
  const [iconActive, setIconActive] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768); 
  const { mobileMaxWidth, mobileMarginLeft } = props;
  const tooltipIconRef = useRef(null);
  const [localeValue, setLocaleValue] = useState(getLocaleValue());

  useEffect(() => {
    const newLocaleValue = getLocaleValue();
    setLocaleValue(newLocaleValue);
  }, [document.cookie])

  useEffect(() => {
    const handleDocumentClick = (e) => {
      if (showTooltip && !tooltipIconRef.current.contains(e.target)) {
        toggleTooltip();
      }
    };

    document.addEventListener('click', handleDocumentClick);

    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, [showTooltip]);


  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  
  const toggleTooltip = () => {
    setShowTooltip(!showTooltip);
    setIconActive(!showTooltip); 
  };

  
  const tooltipClass = `width-${props.maxWidth} margin-${props.marginLeft}`;
  const { innerWidth } = window;
  const { tooltipType } = props;
 // const lang = document.documentElement.lang;

  console.log("width", innerWidth, tooltipType, localeValue);
  
  const placementConfig = {
    'en': {
        'householdIncome': {
          'right': { widths: [ 800, 820, 768, 1280, 768, 2560, 912] },
          'left': { widths: [ 540, 1021, 1536] , condition: w => w > 1024 },
          'bottom': { widths: [414, 390, 393, 412, 1024, 360, 375, 880, 430] }
        },
      'householdIncomeStandard': {
        'right': { widths: [ 412]},
        'left': { widths: [375, 414, 390, 393, 912, 540, 375, 1021, 1536, 1280, 1024, 2560]},
        'top': { widths:[360, 820] }
      },
      'householdIncome1': {
        'left': { widths: [ 1024, 393, 1920, 1536]},
        'bottom': { widths:[ 1021,  280,  540, 1280, 2560, 1536, 375, 390, 360], condition: w => w > 1024 },
        'right': { widths: [ 430, 414, 800, 412, 768, 820, 1024, 912]},
      },
      'healthcontribution': {
        'right': { widths: [ 2327, 2560, 550, 430]},
        'left': { widths: [ 1536, 800, 540, 912] , condition: w => w > 1400},
        'top': { widths:[310, 375, 414, 390, 393, 360, 1021,  280 , 820, 768, 1024, 612, 344] }
      },
      'healthcontributionFamily':{
        'right': { widths: [ 2560]},
        'left': { widths: [540, 1536, 800, 430, 550, 612, 412] , condition: w => w > 1400},
        'top': { widths:[310, 375, 414, 390, 393, 360, 1021,  280 , 820, 768, 1024] }
      },
      'appIntro': {
        'right': { widths: [ 412, 414, 430]},
        'left': { widths: [540, 768, 820,   1024, 2560, 1536] , condition: w => w > 1400},
        'top': { widths:[310, 375,  390, 393, 360, 1021,  280 ,] }
      }

    },
    'es': {
        'householdIncome': {
            'right': { widths: [1280, 2560, 540, 760, 1536, 1485] , condition: w => w > 1035},
            'left': { widths: [414, 390, 393, 412, 1021] },
            'top': { widths: [375, 360, 280] },
            'bottom': { widths: [820, 912, 1024], condition: w => w > 800 || w <= 1024 }
        },
        'householdIncomeStandard': {
          'right': { widths: [ 540, 760, 414, 412 ] },
          'left': { widths:[ 375, 390, 393, 912, 820, 1021,1024, 1280, 2560 , 1536] },
          'top': { widths: [360 , 280] },
        },
        'householdIncome1': {
          'right': { widths: [2560, 540, 768 , 912, 1024], condition: w => w > 1024},
          'left': { widths:  [ 1280, 820,   360, 375, 1536] },
          'top': { widths: [] },
          'bottom': { widths: [  390, 393,  280, 414, 412] }
        },
        'healthcontribution': {
          'right': { widths: [1280,  912,  1024, 768, 820 ]},
          'left': { widths: [540, 1024, 2560 , 1536]},
          'top': { widths: [280] },
          'bottom': { widths: [ 375, 414, 390, 393, 412, 360 ], condition: w => w >= 360 || w < 768}
        },
        'healthcontributionFamily':{
          'right': { widths: [ 1536, 2560, 2327]},
          'left': { widths: [540,  1536, 430, 538,912, 1288] , condition: w => w > 1400},
          'top': { widths:[310, 375, 414, 390, 393, 360, 1021,  280 , 820, 768, 1024] }
        },
        'appIntro': {
          'right': { widths: [ ]},
          'left': { widths: [540, 768, 820,   1024, 2560, 1536,  414, 430, 1288, 2337] , condition: w => w > 1400},
          'top': { widths:[310, 375,  390, 393, 360, 1021,  280 ,412] }
        }
    }
};

function determinePlacement(lang, tooltipType, innerWidth) {
    const langConfig = placementConfig[lang];
    if (!langConfig) return isMobile && mobilePlacement ? mobilePlacement : placement;

    const typeConfig = langConfig[tooltipType];
    if (!typeConfig) return isMobile && mobilePlacement ? mobilePlacement : placement;

    for (const [placement, config] of Object.entries(typeConfig)) {
        const meetsWidthCondition = config.widths.includes(innerWidth);
        const meetsCustomCondition = config.condition ? config.condition(innerWidth) : false;

        if (meetsWidthCondition || meetsCustomCondition) {
            return placement;
        }
    }

    return isMobile && mobilePlacement ? mobilePlacement : placement;
}

const finalPlacement = determinePlacement(localeValue, tooltipType, innerWidth);

  return (
    <>
      <OverlayTrigger
        show={showTooltip}
        delay={{ hide: 500 }}
        html={true}
        placement={finalPlacement}
        overlay={(props) => (
          <BootstrapTooltip
            {...props}
            className={`calculator-modal__tooltip ${tooltipClass} ${localeValue}-tooltip`}
          >
            <span
              className="tooltip-content"
              dangerouslySetInnerHTML={{ __html: content }}
            ></span>
          </BootstrapTooltip>
        )}
      >
        <span
          ref={tooltipIconRef} 
          tabIndex={0}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            toggleTooltip();
          }}
          style={{ maxHeight: "10px", maxWidth: "10px" }}
        >
          &nbsp;
          {iconActive ? (
            <img
              src={configURLs.url.BASE_URL + "/images/botImages/tooltip-active-icon.svg"}
              style={{ maxHeight: "10px", maxWidth: "10px", display: "inline",   top: "20px", verticalAlign: "middle"}}
            />
          ) : (
            props.useDefaultIcon ? (
              <img
                src={configURLs.url.BASE_URL + "/images/botImages/tooltip-icon.svg"}
                style={{ maxHeight: "10px", maxWidth: "10px", display: "inline",   top: "20px", verticalAlign: "middle"}}
              />
            ) : (
              props.children
            )
          )}
        </span>
      </OverlayTrigger>
    </>
  );
}

export default Tooltip;


