import React, { useEffect, useRef, useState } from "react";
import Tooltip from "./Tooltip";
import useDollarAmountFormatter from "../hooks/useDollarAmountFormatter";
import config from "./config";

function HouseholdIncome(props) {
  const { getFormattedDollarAmount } = useDollarAmountFormatter();
  const didMountRef1 = useRef(false);
  const [isValid, setIsValid] = useState(true);
  const [disabled, setDisabled] = useState(true);
  const lang = document.documentElement.lang;
  const year = props.selectedYear || "2024";

  const [inputValue, setInputValue] = useState(
    props.income && props.income !== "" ? `$${props.income}` : ""
  );

  const { localeValue } = props;

  let householdIncomeConfig = {};
  if (config.copy[localeValue] && config.copy[localeValue].householdIncome) {
    householdIncomeConfig = config.copy[localeValue].householdIncome;
  }

  useEffect(() => {
    if (didMountRef1.current) {
      validate();
    } else {
      didMountRef1.current = true;
      if (props.income && props.income !== "" && !isNaN(Number(props.income))) {
        const formattedIncome = getFormattedDollarAmount(props.income);
        setInputValue(
          formattedIncome.startsWith("$")
            ? formattedIncome
            : "$" + formattedIncome
        );
        validate();
      }
    }
  }, [props.income]);

  const validate = () => {
    if (_isValid() && inputValue !== "") {
      setIsValid(true);
      setDisabled(false);
    } else {
      setIsValid(false);
      setDisabled(true);
    }
  };

  const _isValid = () => {
    if (typeof props.income !== "string") {
      return false;
    }
    const numericValue = props.income.replace(/[^0-9.-]/g, "");
    if (numericValue.endsWith(".") || numericValue === "") {
      return true;
    }
    if (isNaN(numericValue)) {
      return false;
    }

    return true;
  };

  const handleBlur = () => {
    if (inputValue !== "" && _isValid()) {
      const formattedAmount = getFormattedDollarAmount(inputValue);
      if (!formattedAmount.startsWith("$")) {
        setInputValue("$" + formattedAmount);
      } else {
        setInputValue(formattedAmount);
      }
    } else if (inputValue === "") {
      setInputValue("");
    }
  };

  const handleChange = (e) => {
    const rawValue = e.target.value.replace(/^\$/, "");
    let numericValue = rawValue.replace(/[^0-9.-]/g, "");
    if (rawValue === ".") {
      setInputValue("$.");
      props.setIncome(".");
      return;
    }

    if (numericValue || numericValue === "0") {
      const formattedAmount = getFormattedDollarAmount(numericValue);
      setInputValue(
        formattedAmount.startsWith("$")
          ? formattedAmount
          : "$" + formattedAmount
      );
      props.setIncome(numericValue);
      validate();
    } else if (numericValue === "") {
      setInputValue("");
      props.setIncome("");
      setDisabled(true);
    }
    validate();
  };

  const handleFocus = (e) => {
    let value = e.target.value;
    if (value.trim() !== "" && !value.startsWith("$")) {
      value = "$" + value;
    }
    setInputValue(value);
  };

  const tooltipContent = {
    en: "Include the income for you, your spouse, and anyone you claim as a dependent when you file taxes. The amount needs to include all income you get like salary, self-employment, interest, dividends, alimony, and Social Security.",
    es: "Incluye tus ingresos, los de tu cónyuge y los de todas las personas que reclames como dependientes cuando declares impuestos. La cantidad debe incluir todos los ingresos que recibes, como sueldos, ingresos de trabajo por cuenta propia, intereses, dividendos, pensión alimenticia, y Seguro Social.",
  }[lang] || '';
  

  const incomeInputClasses = isValid
    ? "form-control"
    : "form-control is-invalid";
  const nextInputClasses = disabled
    ? "btn btn-block ac-pushButton style-default btn-disabled"
    : "btn btn-block ac-pushButton style-default ";
  const englishLabelId = "incomeLabelEn";
  const spanishLabelId = "incomeLabelEs";

  return (
    <div className="calculator-modal__household-income">
      <div className="calculator-modal__screen-header">
        <h2>{
              localeValue &&
              config.copy[localeValue].householdIncome &&
              config.copy[localeValue].householdIncome.heading
            }</h2>
      </div>
      <div className="calculator-modal__screen-body">
          <label
            htmlFor="income"
            id={props.localeValue === "en" ? englishLabelId : spanishLabelId}
            style={{ width: "100%", whiteSpace: "normal" }}
          >
            <span>
              <>
                {props.localeValue === "en"
                  ? `What's your total estimated household income for ${year}`
                  : `¿Cuál es el total estimado de los ingresos de tu hogar para ${year}`}
                ?
                <Tooltip
                  content={tooltipContent}
                  useDefaultIcon={true}
                  placement={"bottom"}
                  mobilePlacement={"bottom"}
                  marginLeft="50"
                  maxWidth="500"
                  tooltipType="householdIncome1"
                ></Tooltip>
              </>
            </span>
          </label>
          <input
            id="income"
            className={incomeInputClasses}
            type={"text"}
            value={inputValue}
            placeholder={
              localeValue &&
              config.copy[localeValue].householdIncome &&
              config.copy[localeValue].householdIncome.incomePlaceholder
            }
            onChange={handleChange}
            onBlur={handleBlur}
            onFocus={handleFocus}
          ></input>
          <div className="invalid-feedback">
            {localeValue &&
              config.copy[localeValue].householdIncome &&
              config.copy[localeValue].householdIncome.invalidFeedback}
          </div>
      </div>
      <div className="form-group mt-auto row">
      <div className='col-12 mb-2'>
          <button
            onClick={props.stepUp}
            type="button"
            className={nextInputClasses}
            disabled={disabled}
          >
            {" "}
            {localeValue &&
              config.copy[localeValue].utilities &&
              config.copy[localeValue].utilities.next}
          </button>
          </div>
          <div className='col-12 mb-2'>
          <button
            onClick={props.stepDown}
            type="button"
            className="btn btn-block btn-outline ac-pushButton style-default "
          >
            {" "}
            {localeValue &&
              config.copy[localeValue].utilities &&
              config.copy[localeValue].utilities.back}
          </button>
          </div>
      </div>
    </div>
  );
}

export default HouseholdIncome;
