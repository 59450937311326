import React from 'react';

const BubbleText = ({message}) => {
  return ( 
    <div className="bubble-text">
      <span>{message}</span>
    </div>
  );
}

export default BubbleText;
