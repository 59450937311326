import React, { useState, useEffect } from "react";
import config from "./config";

function YearOfCoverageApp(props) {
  const lang = document.documentElement.lang;
  const [selectedOption, setSelectedOption] = useState("");
  const [isValid, setIsValid] = useState(true);
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const comparisonYear =
    currentDate.getMonth() === 0 ? currentYear - 1 : currentYear;
  const { localeValue } = props;

  const handleDropdownChange = (event) => {
    const value = event.target.value;
    setSelectedOption(value);
    props.setSelectedYear(Number(value));
    setIsValid(_isValid(value));
  };

  const _isValid = (value) => {
    if (isNaN(value) || value === "") {
      return false;
    }
    return true;
  };

  useEffect(() => {
    setSelectedOption(props.yearOfCoverage);
  }, [props.yearOfCoverage]);

  const incomeInputClasses = isValid
    ? "form-control-affordability"
    : "form-control is-invalid";
  const nextInputClasses = _isValid(selectedOption)
    ? "btn btn-block ac-pushButton style-default button-margin"
    : "btn btn-block ac-pushButton style-default btn-disabled button-margin";
  const nextButtonDisabled = !_isValid(selectedOption);

  return (
    <div className="calculator-modal__coverage-year">
      <div className="calculator-modal__screen-header">
        <h2>{localeValue &&
                config.copy[localeValue].yearOfCoverage &&
                config.copy[localeValue].yearOfCoverage.heading}</h2>
      </div>
      <div className="calculator-modal__screen-body">
        <div className="form-group">
          <label htmlFor="income" style={{ width: "100%" }}>
            <span>
              {" "}
              {localeValue &&
                config.copy[localeValue].yearOfCoverage &&
                config.copy[localeValue].yearOfCoverage.label}
            </span>
          </label>
          <select
            id="yearOfCoverage"
            value={selectedOption}
            onChange={handleDropdownChange}
            className={`form-control-affordability mt-2 ${incomeInputClasses}`}
          >
            <option value="" disabled hidden>
              {localeValue &&
                config.copy[localeValue].yearOfCoverage &&
                config.copy[localeValue].yearOfCoverage
                  .yearOfCoveragePlaceholder}
            </option>

            <option value="2023" style={{ padding: "5px" }}>
              2023
            </option>
            <option value="2024" style={{ padding: "5px" }}>
              2024
            </option>
          </select>
          <div className="invalid-feedback">
            {localeValue &&
              config.copy[localeValue].yearOfCoverage &&
              config.copy[localeValue].yearOfCoverage.invalidFeedback}
          </div>
        </div>
      </div>
      <div className="calculator-modal__buttons-container">
          <button
            onClick={props.stepUp}
            type="button"
            className="calculator-modal__button calculator-modal__button--primary"
            disabled={nextButtonDisabled}
          >
           {localeValue &&
              config.copy[localeValue].utilities &&
              config.copy[localeValue].utilities.next}
          </button>
          <button
            onClick={props.stepDown}
            type="button"
            className="calculator-modal__button calculator-modal__button--secondary"
          >
           {localeValue &&
              config.copy[localeValue].utilities &&
              config.copy[localeValue].utilities.back}
          </button>
      </div>
    </div>
  );
}

export default YearOfCoverageApp;