import React from 'react';
import './AffordabilityToolModal.css';

function AffordabilityToolExitModal({
  isFixed,
  closeHandler,
  title,
  copy,
  exitButtonText,
  exitHandler,
  returnButtonText,
  returnHandler
}){
  const atModalClasses = isFixed ? "atModal atModal--fixed" : "atModal"
  return (
    <div className={atModalClasses}>
      <div className="atModal__modal">
        <div className="atModal__header">
          <button className="material-icons atModal__close"
            id="ATWarning__close"
            onClick={closeHandler}>
            close
          </button>
        </div>
        <div className="atModal__body">
        <div>
          <div className="text-left">
            <span className='atModal__modal-title'>{title}</span>
            <div className='atModal__modal-content'>
              {copy}
            </div>
          </div>
          <div className="atModal__btn-container">
            {/* Exit without saving button */}
            <button 
              className="calculator-modal__button calculator-modal__button--secondary" 
              onClick={exitHandler}
            >
              {exitButtonText}
            </button>
            {/* Return to tool button */}
            <button 
              className="calculator-modal__button calculator-modal__button--primary" 
              onClick={returnHandler}
            >
              {returnButtonText}
            </button>
          </div>
        </div>
        </div>
      </div>
    </div>
  );
}

// class AffordabilityToolModal extends Component {
//   render() {
//     const lang = document.documentElement.lang;
//     let modalContent;
//     let modalTitle;
//     let atModalFixedClass = this.props.isFixed === true ? " atModal--fixed " : ""; 

//     if (this.props.modalType === 'ATWarning') {
//       modalTitle = lang === "en" ? "Before You Exit" : "Antes de que salgas.";
//       modalContent = (
//         <div>
//           <div className="text-left">
//             <span className='atModal__modal-title'>{modalTitle}</span>
//             <div className='atModal__modal-content'>
//               {lang === "en" ? 
//               <p>If you leave now, you’ll lose any information you entered.</p>
//               : 
//               <p>
//               Si sales ahora, perderás toda la información que ingresaste.
//               </p>}
//             </div>
//           </div>
//           <div className="atModal__btn-container">
//             <button className="btn btn-outline-primary at__btn-outline-primary" onClick={this.props.existWithoutSavingButtonClick}
//               onKeyPress={(e) => { this.props.handleKeyPress(e, this.props.existWithoutSavingButtonClick) }}>
//               {lang === "en" ? "Exit tool without saving" : "Salir sin guardar" }
//             </button>
//             <button className="btn btn-primary at__btn" onClick={this.props.deactivateModals}
//               onKeyPress={(e) => { this.props.handleKeyPress(e, this.props.deactivateModals) }}>
//               { lang === "en" ? "Return to tool" : "Volver a la herramienta" }
//             </button>
//           </div>
//         </div>
//       );
//     }

//     return (
//       <div className={"atModal" + atModalFixedClass + this.props.status}>
//         <div className="atModal__modal">
//           <div className="atModal__header">
//             <span className="material-icons atModal__close" tabIndex={0} id={this.props.modalType + "__close"}
//               onClick={this.props.deactivateModals}>
//               close
//             </span>
//           </div>
//           <div className="atModal__body">
//             {modalContent}
//           </div>
//         </div>
//       </div>
//     );
//   }
// }

export default AffordabilityToolExitModal;
