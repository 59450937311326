import React, { useEffect, useState } from "react";
import setSendBox from "botframework-webchat-core/lib/actions/setSendBox";
import getAutoCompleteSuggestions from "./getAutoCompleteSuggestions";
import getMatchedIdealQuestions from "./getMatchedIdealQuestions";

const USE_AUTO_COMPLETE_EN = true;
const USE_AUTO_COMPLETE_ES = false;

/**
 * - displays suggestions when userInput is valid
 * - scrolls cici window to the end after displaying suggestions
 * - closes when a suggestion is clicked
 * - uses the static idealQuestions or uses the cognitive search results as suggestions
 * @param {*} userInput
 * @param {*} lang
 * @param {*} idealQuestions
 * @param {*} ciciStore
 * @returns AutoCompleteSuggestions Component
 */
function AutoCompleteSuggestions({ userInput, lang, idealQuestions, ciciStore }) {
  const [suggestions, setSuggestions] = useState([]);
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    async function getSuggestions() {
      if (lang === "es") {
        if (USE_AUTO_COMPLETE_ES) {
          setSuggestions(await getAutoCompleteSuggestions(userInput));
        } else {
          setSuggestions(getMatchedIdealQuestions(userInput, idealQuestions));
        }
      } else {
        if (USE_AUTO_COMPLETE_EN) {
          setSuggestions(await getAutoCompleteSuggestions(userInput));
        } else {
          setSuggestions(getMatchedIdealQuestions(userInput, idealQuestions));
        }
      }
      scrollCiciToEnd();
    }
    function isUserInputValid() {
      if (!userInput) return false;
      if (userInput === "") return false;
      return true;
    }
    if (!isUserInputValid()) {
      setIsVisible(false);
      return;
    }
    setIsVisible(true);
    getSuggestions();
  }, [userInput, setIsVisible, idealQuestions, lang]);

  function scrollCiciToEnd() {
    if (document.querySelector('ul[role="list"]') && document.querySelector('ul[role="list"]').lastChild) {
      document.querySelector('ul[role="list"]').lastChild.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }

  function clickHandler(suggestion) {
    setIsVisible(false);
    ciciStore.dispatch({
      type: "WEB_CHAT/SEND_MESSAGE",
      payload: {
        text: suggestion,
      },
    });
    ciciStore.dispatch(setSendBox(""));
    window.dataLayer.push({
      event: "dotComClick",
      eventDetails: {
        category: "CiCi",
        action: "Autocomplete Clicks",
        label: suggestion,
      },
    });
  }
  return (
    <React.Fragment>
      {suggestions.length > 0 && isVisible ? (
        <div>
          {lang === "en" ? <div className="trending-topics-title">Suggestions</div> : null}
          {suggestions.map((suggestion) => (
            <button className="suggested-item" key={suggestion} onClick={() => clickHandler(suggestion)}>
              {suggestion}
            </button>
          ))}
        </div>
      ) : null}
    </React.Fragment>
  );
}

export default AutoCompleteSuggestions;
