import React, { useState, useEffect } from 'react';
import RadioButtons from '../components/RadioButton';
import translations from '../translation';

function Sb260WizardSourceOfHealthCoveragePage({ onBack, onOptOut, onContinue, lang, eventName, sendLoggingEventToBackend }) {
  const { sourceOfHealthHeading, sourceOfHealthIntro, sourceOfHealthSubmitText, backText } = translations[lang];
  const [pleaseSpecifyClass, setpleaseSpecifyClass] = useState("form-control");
  const [errors, setErrors] = useState([]);
  const [selectedOption, setSelectedOption] = useState('');
  const [otherText, setOtherText] = useState('');
  const radioOptionsEn = [
    { label: "A plan through a current employer or union - of yours or a family member's", value: '01' },
    { label: 'Returning to Medi-Cal or Medicaid', value: '02' },
    { label: 'Medicare', value: '03' },
    { label: 'I will not have any health coverage', value: '04' },
    { label: 'Prefer not to answer', value: '06' },
    { label: 'Other', value: '05' },
  ];
  const radioOptionsEs = [
    { label: 'Un plan a través de un empleador o sindicato, suyo o de un familia', value: '01' },
    { label: 'Regresando a Medi-Cal o Medicaid', value: '02' },
    { label: 'Medicare', value: '03' },
    { label: 'No tendré ninguna cobertura de salud', value: '04' },
    { label: 'Prefiere no decir', value: '06' },
    { label: 'Otro', value: '05' },
  ];
  const radioOptions = lang === 'es' ? radioOptionsEs : radioOptionsEn;
  const pleaseSpecifyText = lang === 'es' ? 'Por favor especifica:' : 'Please specify:';
  const [showError, setShowError] = useState(false);
  const [disableContinue, setDisableContinue] = useState(false);

  useEffect(() => {
    if (eventName === 'SHOW_SERVICE_TIMEOUT_ERROR') {
      setSelectedOption('');
      setOtherText('');
      setShowError(true);
      setDisableContinue(true);
      setErrors([
        lang === "en"
        ? "Oops, something went wrong. Please try again or use the 'Help' option for support."
        : "Ups, algo salió mal. Por favor, inténtalo de nuevo o usa la opción de 'Ayuda' para obtener soporte."
      ]);
      
    } else {
      setShowError(false);
      setDisableContinue(false);
      setErrors([]);
    }
  }, [eventName]);

  useEffect(()=> {
    sendLoggingEventToBackend({
      intentName: "CancelConfirmPlanDetails",
      text: "CancelConfirmPlanDetails"
      });
  }, [sendLoggingEventToBackend])

  const handleOptOut = () => {
    if (eventName === 'SHOW_SERVICE_TIMEOUT_ERROR') {
      setShowError(true);
      setErrors([
        lang === "en"
        ? "Oops, something went wrong. Please come back later or use the \"Help\" option for support."
        : "Ups, algo salió mal. Por favor, inténtalo de nuevo o usa la opción de 'Ayuda' para obtener soporte."
      ]);
      
      return;
    }
  
    if (selectedOption === '') {
      setShowError(true);
      setErrors(["Oops! You forgot to enter your source of health coverage. Type your answer in the box to continue."]);
    } else {
      setErrors([]);
      onOptOut(selectedOption);
    }
  };
  

  return (
    <div className="h-100 d-flex flex-column">
      <h2>{sourceOfHealthHeading}</h2>
      <p>{sourceOfHealthIntro}</p>
      {showError && (
        <div className="wizard__error-message">
          <div className="wizard__error-message-left-container">
            <img className="wizard__error-message-icon" src="/cici-assets/images/botImages/Red-Exclamation.svg" alt="Error Icon" />
          </div>
          <div className="wizard__error-message-right-container">
            {errors.length > 0 ? errors[0] : "Oops! You forgot to enter your source of health coverage. Type your answer in the box to continue."}
          </div>
        </div>
      )}

      <RadioButtons
        options={radioOptions}
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
      />
      {selectedOption === '05' && (
        <div className="form-group mt-3">
          <label htmlFor="otherText">{pleaseSpecifyText}</label>
          <input
            type="text"
            className={`form-control ${errors.length > 0 ? 'is-invalid' : ''}`}
            id="otherText"
            value={otherText}
            onChange={(e) => {
              setOtherText(e.target.value);
              setShowError(false);
              if (e.target.value === '') {
                setpleaseSpecifyClass('is-invalid');
              } else {
                setpleaseSpecifyClass('is-valid');
              }
            }}
          />
        </div>
      )}
      <div className="form-group mt-auto">
        <button className="btn btn-block ac-pushButton" type="button" onClick={handleOptOut} disabled={disableContinue}>
          {sourceOfHealthSubmitText}
        </button>
        <button className="btn btn-block btn-outline ac-pushButton" type="button" onClick={onBack}>
          {backText}
        </button>
      </div>
    </div>
  );
}

export default Sb260WizardSourceOfHealthCoveragePage;