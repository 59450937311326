import React, { useEffect, useRef, useState } from "react";
import Tooltip from "./Tooltip";
import config from "./config";
import useDollarAmountFormatter from "../hooks/useDollarAmountFormatter";
import useConsumerName from "./hooks/useConsumerName";

function HealthContributionCostApp(props) {
  const didMount = useRef(false);
  const [isFamilyCostValid, setIsFamilyCostValid] = useState(true);
  const [isEmployeeCostValid, setIsEmployeeCostValid] = useState(true);
  const lang = document.documentElement.lang;
  const { getFormattedDollarAmount } = useDollarAmountFormatter();
  const [
    displayEmployeeContributionForIndividual,
    setDisplayEmployeeContributionForIndividual,
  ] = useState("");
  const [
    displayEmployeeContributionForFamily,
    setDisplayEmployeeContributionForFamily,
  ] = useState("");
  const [employerCollectionFrequency, setEmployerCollectionFrequency] =
    useState(0);
  const [hasInteracted, setHasInteracted] = useState(false);
  const [selectedOption, setSelectedOption] = useState(() => {
    const storedOption = sessionStorage.getItem("selectedOption");
    return storedOption && storedOption !== "undefined" ? storedOption : "";
  });
  const [isAlignMarginsChecked, setIsAlignMarginsChecked] = useState(() => {
    const storedState = sessionStorage.getItem("isAlignMarginsChecked");
    return storedState === "true";
  });
  const { AppConsumerName, setAppConsumerName } = useConsumerName();
  const [isEmployeeVisible, setIsEmployeeVisible] = useState(false);
  const [isFamilyVisible, setIsFamilyVisible] = useState(false);
  const [isFrequencyVisible, setIsFrequencyVisible] = useState(false);
  const [frequencyOption, setFrequencyOption] = useState(props.frequencySelection || "");
  const [renderKey, setRenderKey] = useState(Math.random());
  const { localeValue } = props;

  useEffect(() => {
    sessionStorage.setItem("isAlignMarginsChecked", isAlignMarginsChecked);
  }, [isAlignMarginsChecked]);

  useEffect(() => {
    if (props.frequencySelection) {
      setFrequencyOption(props.frequencySelection);
      const frequencyNumber = labelToNumberMapping(props.frequencySelection);
      setEmployerCollectionFrequency(frequencyNumber);
    }
  }, [props.frequencySelection]);
  
  useEffect(() => {
    setFrequencyOption(props.frequencySelection);
  }, [props.frequencySelection]);

  useEffect(() => {
    setIsEmployeeCostValid(true);
    setIsFamilyCostValid(true);
    const storedOption = sessionStorage.getItem("selectedOption");
    if (storedOption && storedOption !== "undefined") {
      const frequency = labelToNumberMapping(storedOption);
      setEmployerCollectionFrequency(frequency);
    }
  }, []);

  

  useEffect(() => {
    if (props.yesNoSelection) {
      setIsEmployeeVisible(props.yesNoSelection === "yes");
      setIsFamilyVisible(props.yesNoSelection === "no");
      setIsFrequencyVisible(true);
    }
  }, [props.yesNoSelection]);
  
  

  useEffect(() => {
    if (
      props.employeeContributionForIndividual &&
      isCostValid(props.employeeContributionForIndividual)
    ) {
      const formattedValue =
        "$" + getFormattedDollarAmount(props.employeeContributionForIndividual);
      setDisplayEmployeeContributionForIndividual(formattedValue);
    }

    if (
      props.employeeContributionForFamily &&
      isCostValid(props.employeeContributionForFamily)
    ) {
      const formattedValue =
        "$" + getFormattedDollarAmount(props.employeeContributionForFamily);
      setDisplayEmployeeContributionForFamily(formattedValue);
    }

    sessionStorage.removeItem("hasInteracted");
  }, [
    props.employeeContributionForIndividual,
    props.employeeContributionForFamily,
    getFormattedDollarAmount,
  ]);

  const labelToNumberMapping = (label) => {
    switch (label) {
      case "Every 2 weeks":
        return 26;
      case "Weekly":
        return 52;
      case "Twice a month":
        return 24;
      case "Monthly":
        return 12;
      case "Quarterly":
        return 4;
      case "Yearly":
        return 1;
      default:
        return 0;
    }
  };

   const isCostValid = (cost) => {
    const numericValue = cost.replace(/[^0-9.-]/g, "");
    if (isNaN(numericValue) || numericValue === "") {
      return false;
    }
    return true;
  };

  const handleChangeIndividual = (e) => {
    const rawValue = e.target.value;
    props.setEmployeeContributionForIndividual(
      rawValue.startsWith("$") ? rawValue.slice(1) : rawValue
    );
    setDisplayEmployeeContributionForIndividual(rawValue);
  };

  const handleChangeFamily = (e) => {
    const rawValue = e.target.value;
    props.setEmployeeContributionForFamily(
      rawValue.startsWith("$") ? rawValue.slice(1) : rawValue
    );
    setDisplayEmployeeContributionForFamily(rawValue);
  };

  const handleFocus = (e) => {
    const value = e.target.value.trim();
    if (value !== "" && !value.startsWith("$")) {
      e.target.value = "$" + value;
    }
  };

  const handleBlur = (e) => {
    const cleanedValue = e.target.value.replace(/[^0-9.-]/g, "").trim();

    if (cleanedValue === "") {
      if (e.target.id === "contribution-per-paycheck-individual-in-app") {
        props.setEmployeeContributionForIndividual(cleanedValue);
        setDisplayEmployeeContributionForIndividual("");
      } else if (e.target.id === "contribution-per-paycheck-family") {
        props.setEmployeeContributionForFamily(cleanedValue);
        setDisplayEmployeeContributionForFamily("");
      }
    } else {
      if (e.target.id === "contribution-per-paycheck-individual-in-app") {
        props.setEmployeeContributionForIndividual(cleanedValue);
        setDisplayEmployeeContributionForIndividual(
          "$" + getFormattedDollarAmount(cleanedValue)
        );
      } else if (e.target.id === "contribution-per-paycheck-family") {
        props.setEmployeeContributionForFamily(cleanedValue);
        setDisplayEmployeeContributionForFamily(
          "$" + getFormattedDollarAmount(cleanedValue)
        );
      }
    }
  };

  const isDropdownValid = () => {
    return employerCollectionFrequency > 0;
  };




  const _isValid = () => {
    const isSelectedYes = props.yesNoSelection === "yes";
    const isSelectedNo = props.yesNoSelection === "no";
    const dropdownValid = employerCollectionFrequency > 0;
    const isEmployeeInputFilled = !!props.employeeContributionForIndividual;
    const isFamilyInputFilled = !!props.employeeContributionForFamily;
  
    if (isSelectedYes) {
      return dropdownValid && isEmployeeInputFilled;
    } else if (isSelectedNo) {
      return dropdownValid && isFamilyInputFilled;
    } else {
      return false;
    }
  };

  const handleYesNoChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);
    props.setYesNoSelection(selectedValue);
    setIsEmployeeVisible(selectedValue === "yes");
    setIsFamilyVisible(selectedValue === "no");
    setIsFrequencyVisible(true);
    setFrequencyOption("");
    setEmployerCollectionFrequency(0);
    setHasInteracted(false);
    let intentName = selectedValue === 'yes'? props.localeValue === 'en' ? 'EmployeeOnlyCalculation' : 'EmployeeOnlyCalculationSpanish' 
                                            : props.localeValue === 'en' ? 'FamilyOnlyCalculation' : 'FamilyCalculationSpanish'
    let text = selectedValue === 'yes'?  props.localeValue === 'en' ? 'Employee Only Calculation' : 'Employee Only Calculation Spanish' 
                                        : props.localeValue === 'en' ? 'Family Only Calculation' : 'Family Calculation Spanish'
    props.sendLoggingEventToBackend({
      intentName: intentName,
      text: text
    });
};



const handleFrequencyChange = (event) => {
  const selectedFrequency = event.target.value;
  const frequencyNumber = labelToNumberMapping(selectedFrequency);
  setFrequencyOption(selectedFrequency);
  setEmployerCollectionFrequency(frequencyNumber); 
  props.setEmployerCollectionFrequency(frequencyNumber); 
  props.setFrequencySelection(selectedFrequency); 
};



  const MINIMUM_VALUE_STANDARDS_TOOLTIP_TEXT_ES =
  "\"Solo para el empleado” también se podría referir como cobertura \“individual”\."
  const MINIMUM_VALUE_STANDARDS_TOOLTIP_TEXT_EN =
  "\"Employee-only\" may also be referred to as \"Single\"."
  const TOOLTIP_TEXT_HEALTHCONTRIBUTION_FAMILY_EN =
    "Amount may vary by number of family members. Enter the amount for your family size.";
  const TOOLTIP_TEXT_HEALTHCONTRIBUTION_FAMILY_ES =
    "El costo podría variar según el número de miembros de la familia. Ingresa el costo para el tamaño de tu familia.";

  const employeeInputClasses = isEmployeeCostValid
    ? "form-control"
    : "form-control is-invalid";
  const familyInputClasses = isFamilyCostValid
    ? "form-control"
    : "form-control is-invalid";
  const nextInputClasses = _isValid()
    ? "btn btn-block ac-pushButton style-default button-margin"
    : "btn btn-block ac-pushButton style-default btn-disabled button-margin";
  const tooltipText =
    props.localeValue === "es"
      ? MINIMUM_VALUE_STANDARDS_TOOLTIP_TEXT_ES
      : MINIMUM_VALUE_STANDARDS_TOOLTIP_TEXT_EN;
  const tooltipText1 =
    props.localeValue === "es"
      ? TOOLTIP_TEXT_HEALTHCONTRIBUTION_FAMILY_ES
      : TOOLTIP_TEXT_HEALTHCONTRIBUTION_FAMILY_EN;
  const dropdownClasses =
    hasInteracted && !isDropdownValid()
      ? "form-control is-invalid"
      : "form-control-affordability";
 
 
 
 
  return (
    <div class="calculator-modal__health-contribution-cost">
      <h2>{localeValue &&
                config.copy[localeValue].healthContributionCost &&
                config.copy[localeValue].healthContributionCost.heading}</h2>
      <div id="yesOrNoSelection" className="form-group">
        <label
          id="contribution-per-paycheck-individual-in-app"
          htmlFor="contribution-per-paycheck-individual-in-app"
          style={{ width: "100%" }}
        >
            {props.localeValue === "en" ?
          `For this offer of coverage, is ${AppConsumerName} the employee?` :
          `¿${AppConsumerName} es la empleada para esta oferta de cobertura?`
          }

          <select
            onChange={handleYesNoChange}
            id="coverageSelection"
            className="form-control-affordability"
            value={props.yesNoSelection}
          >
            <option value="" disabled hidden>
            {localeValue &&
                config.copy[localeValue].utilities &&
                config.copy[localeValue].utilities.selectPlaceholder}
            </option>
            <option value="yes">{localeValue &&
                config.copy[localeValue].affordabilityDetermination &&
                config.copy[localeValue].affordabilityDetermination.yes}</option>
            <option value="no">{localeValue &&
                config.copy[localeValue].affordabilityDetermination &&
                config.copy[localeValue].affordabilityDetermination.no}</option>
          </select>
        </label>
      </div>

     

      {isEmployeeVisible && (
        <div id="employeeCoverage">
          <label>{localeValue &&
                config.copy[localeValue].healthContributionCost &&
                config.copy[localeValue].healthContributionCost.label1App}
          <Tooltip
            content={tooltipText}
            useDefaultIcon={true}
            placement={"right"}
            mobilePlacement={"right"}
            marginLeft="50"
            maxWidth="600"
            tooltipType="healthcontribution"
          ></Tooltip></label>
          <input
            type={"text"}
            placeholder={localeValue &&
              config.copy[localeValue].healthContributionCost &&
              config.copy[localeValue].healthContributionCost.placeholder1}
            onChange={handleChangeIndividual}
            value={displayEmployeeContributionForIndividual}
            className={employeeInputClasses}
            onBlur={(e) => handleBlur(e)}
            onFocus={(e) => handleFocus(e)}
            id="contribution-per-paycheck-individual-in-app"
          />
          <div className="invalid-feedback">
          {localeValue &&
                config.copy[localeValue].healthContributionCost &&
                config.copy[localeValue].healthContributionCost.invalidFeedback1}
          </div>
        </div>
      )}

      {isFamilyVisible && (
        <div id="familyCoverage">
          {props.isFamilyCoverageApplicable ? (
            <label
              id="contribution-per-paycheck-family"
              htmlFor="contribution-per-paycheck-family"
              style={{ width: "100%" }}
            >
            {localeValue &&
                config.copy[localeValue].healthContributionCost &&
                config.copy[localeValue].healthContributionCost.label2App}
              <Tooltip
                content={tooltipText1}
                useDefaultIcon={true}
                placement={"right"}
                mobilePlacement={"right"}
                marginLeft="50"
                maxWidth="600"
                tooltipType="healthcontributionFamily"
              ></Tooltip>
            </label>
          ) : null}
          <div
            className={`d-flex align-items-center ${
              isAlignMarginsChecked ? "align-margins-with-margin" : ""
            }`}
          ></div>
          {props.isFamilyCoverageApplicable ? (
            <>
              <input
                type={"text"}
                placeholder= {localeValue &&
                  config.copy[localeValue].healthContributionCost &&
                  config.copy[localeValue].healthContributionCost.placeholder2}
                onChange={handleChangeFamily}
                value={displayEmployeeContributionForFamily}
                className={familyInputClasses}
                id="contribution-per-paycheck-family"
                onBlur={(e) => handleBlur(e)}
                onFocus={(e) => handleFocus(e)}
              ></input>

              <div className="invalid-feedback">
              {localeValue &&
                config.copy[localeValue].healthContributionCost &&
                config.copy[localeValue].healthContributionCost.invalidFeedback2}
              </div>
            </>
          ) : null}
        </div>
      )}

{isFrequencyVisible && (
        <div id="frequencyDetermination" className="form-group">
          <label htmlFor="contribution-frequency" id="contributionFrequency">
          {localeValue &&
                config.copy[localeValue].healthContributionFrequency &&
                config.copy[localeValue].healthContributionFrequency.label1App}
          </label>
          <select
            onChange={handleFrequencyChange}
            id="contribution-frequency"
            className={dropdownClasses}
             value={frequencyOption}
          >
            <option value="" disabled hidden>
            {localeValue &&
                config.copy[localeValue].healthContributionFrequency &&
                config.copy[localeValue].healthContributionFrequency.placeholder1}
            </option>
            <option value="Weekly">
            {localeValue &&
                config.copy[localeValue].healthContributionFrequency &&
                config.copy[localeValue].healthContributionFrequency.option1}
            </option>
            <option value="Every 2 weeks">
            {localeValue &&
                config.copy[localeValue].healthContributionFrequency &&
                config.copy[localeValue].healthContributionFrequency.option2}
            </option>
            <option value="Twice a month">
            {localeValue &&
                config.copy[localeValue].healthContributionFrequency &&
                config.copy[localeValue].healthContributionFrequency.option3}
            </option>
            <option value="Monthly">
            {localeValue &&
                config.copy[localeValue].healthContributionFrequency &&
                config.copy[localeValue].healthContributionFrequency.option4}
            </option>
            <option value="Quarterly">
            {localeValue &&
                config.copy[localeValue].healthContributionFrequency &&
                config.copy[localeValue].healthContributionFrequency.option5}
            </option>
            <option value="Yearly">
            {localeValue &&
                config.copy[localeValue].healthContributionFrequency &&
                config.copy[localeValue].healthContributionFrequency.option6}
            </option>
          </select>
          {!_isValid(props.employerCollectionFrequency) && hasInteracted && (
            <div className="invalid-feedback">
                  {localeValue &&
                config.copy[localeValue].healthContributionFrequency &&
                config.copy[localeValue].healthContributionFrequency.invalidFeedback1}
            </div>
          )}
        </div>
      )}

      <div className="calculator-modal__buttons-container" >
          <button
            onClick={props.stepUp}
            type="button"
            className="calculator-modal__button calculator-modal__button--primary"
            disabled={!_isValid()}
          >
                {localeValue &&
                config.copy[localeValue].utilities &&
                config.copy[localeValue].utilities.next}
          </button>
          <button
            onClick={props.stepDown}
            type="button"
            className="calculator-modal__button calculator-modal__button--secondary"
          >
               {localeValue &&
                config.copy[localeValue].utilities &&
                config.copy[localeValue].utilities.back}
          </button>
      </div>
    </div>
  );
}

export default HealthContributionCostApp;
