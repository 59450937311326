import React, { useEffect, useState, useRef } from 'react';
import Wizard from '../components/Wizard/Wizard';
import Sb260WizardAuthenticationPage from './pages/Sb260WizardAuthenticationPage';
import Sb260WizardConsentPage from './pages/Sb260WizardConsentPage';
import Sb260WizardKeepPlanDetailsPage from './pages/Sb260WizardKeepPlanDetailsPage';
import Sb260WizardCancelPlanDetailsPage from './pages/Sb260WIzardCancelPlanDetailsPage';
import Sb260WizardCancelResultPage from './pages/Sb260WizardCancelResultPage';
import HelpOption from './options/Help';
import Main from './options/Main';
import Sb260WizardOptInResultZeroPremiumPage from './pages/Sb260WizardOptInResultZeroPremiumPage';
import Sb260WizardOptInResultNonZeroPremiumPage from './pages/Sb260WizardOptInResultNonZeroPremiumPage';
import Sb260WizardFeedbackPage from './pages/Sb260WizardFeedback'
import Sb260WizardThankYouPage from './pages/sb260WizardThankYouPage'
import Sb260WizardSourceOfHealthCoveragePage from './pages/Sb260WizardSourceOfHealthCoveragePage';
import Sb260ContactSupportMixedHouseHoldPage from './pages/Sb260ContactSupportMixedHouseHoldPage';
import Sb260ContactSupportIsNotAPSIndividualPage from './pages/Sb260ContactSupportIsNotAPSIndividualPage';
import Sb260WizardKeepyourplanAndPayBillPage from './pages/Sb260WizardKeepyourplanAndPayBillPage';
import ATHelpModal from "../calculator/ATHelpModal.js";
import ATHelpModalAfterServiceHour from "../calculator/ATHelpModal.js";
import isServiceOpen from "../calculator/utitilies/ServiceHours.js";

function Sb260Wizard(props) {
    /**
     * for hyphenated words that must appear in one line, use html entities.
     * when passing strings with html entities as strings, make sure to decode them first.
     */
    const eventNameMessageMap = {
        'SHOW_SERVICE_ERROR': "Oops, something went wrong. Please come back later or use the 'Help' option for support.",
        'SHOW_USER_NOT_FOUND': "I can't find you in our system. Please retry or use the 'Help' option for support."
    }

    const eventNameMessageMapEs = {
        'SHOW_SERVICE_ERROR': "Ups, algo salió mal. Por favor, inténtalo de nuevo o usa la opción de 'Ayuda' para obtener soporte.",
        'SHOW_USER_NOT_FOUND': "No puedo encontrarte en nuestro sistema. Por favor, inténtalo de nuevo o utiliza la opción 'Ayuda' para obtener soporte.",
    }

    const getEventNameMessageMap = (event) => {
        const eventMap = lang === 'es' ? eventNameMessageMapEs : eventNameMessageMap;
        if ((event.name === "SHOW_SERVICE_ERROR" || event.name === "SHOW_SERVICE_TIMEOUT_ERROR" ||  event.name === "SHOW_SERVICE_UNAVAILABLE_ERROR")) {
            return eventMap['SHOW_SERVICE_ERROR'];
        }
        return eventMap[event.name];
    }

    const { close, sendActivity, event, onHelpClick, lang, closeContactSupport, chatWithRepresentative, serviceHourData } = props;
    const [planDetails, setPlanDetails] = useState();
    const [currentPage, setCurrentPage] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);
    const [isWithinHours, setIsWithinHours] = useState(isServiceOpen(serviceHourData, lang));
        const handlePlanDetailsUpdate = (newPlanDetails) => {
        setPlanDetails(newPlanDetails);
    }
    
    const toggleHelpModal = () => {
        setIsHelpModalOpen(!isHelpModalOpen);
      };

    const authenticate = async ({ dob, ssn, zip }) => {
        setIsLoading(true);
        await sendActivity({
            type: "message",
            value: {
                wizard: "sb260",
                dob,
                ssn,
                zip
            }
        });
    }
    const optIn = async () => {
        setIsLoading(true);
        await sendActivity({ type: "message", value: { wizard: "sb260", consentYears: 5, optIn: true } });
    }

    const optOut = async (optOutReasonCode) => {
        setIsLoading(true);
        await sendActivity({ type: "message", value: { wizard: "sb260", optIn: false, optOutReasonCode: optOutReasonCode } });
    }

    const onContactSupport = async () => {
        close();
        await sendActivity({
            type: "message",
            text: "Contact support"
        })
    }

    const htmlDecode = (input) => {
        var e = document.createElement('div');
        e.innerHTML = input;
        return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
    }

    // const utilityOptions = [
    //     <HelpOption onHelpClick={onHelpClick} lang={lang} sendActivity={sendActivity} closeContactSupport={closeContactSupport} />,
    //     <Main onClose={close} sendLoggingEventToBackend={props.sendLoggingEventToBackend} lang={lang} />
    // ];

    const intentName= isWithinHours ? "HelpModalM2C" : "helpModalM2CAfterHours"
const text = isWithinHours ? "HelpModalM2C" : "helpModalM2CAfterHours"

const helpModal = isWithinHours ? (
  <ATHelpModal
    localeValue={lang}
    isOpenHelpModal={isHelpModalOpen}
    openATHelpModal={setIsHelpModalOpen}
    sendLoggingEventToBackend={props.sendLoggingEventToBackend}
    serviceHourData={serviceHourData}
    intentName1={intentName}
    text1={text}
  />
) : (
  <ATHelpModalAfterServiceHour
    localeValue={lang}
    isOpenHelpModal={isHelpModalOpen}
    openATHelpModal={setIsHelpModalOpen}
    sendLoggingEventToBackend={props.sendLoggingEventToBackend}
    serviceHourData={serviceHourData}
    intentName1={intentName}
    text1={text}
  />
);
    const utilityOptions = [
        <button 
         class="wizard__utility-option btn btn-link "
         onClick={toggleHelpModal}>
         {lang === "es" ? "Ayuda" : "Help"}
       </button>,
       <Main
         onClose={close}
         sendLoggingEventToBackend={props.sendLoggingEventToBackend}
         lang={lang}
       />,
     ];

    useEffect(() => {
        setIsLoading(false);
        if (event.name === "SHOW_START") {
            setCurrentPage(Pages.AUTHENTICATION);
            return;
        }

        if (event.name === "SHOW_AUTHENTICATE_SUCCESS") {
            setPlanDetails({
                plan: event.data.plan,
                monthlyPremium: event.data.monthlyPremium,
                isZeroPremium: event.data.isZeroPremium,
                ...event.data
            });
        
            if (event.data.type === "keep") {
                props.sendLoggingEventToBackend({
                    intentName: "KeepAuthenticateSuccess",
                    text: "KeepAuthenticateSuccess"
                });
        
                if (event.data.monthlyPremium > 0) {
                    setCurrentPage(Pages.KEEPYOURPLAN_BILL_PAGE);
                    props.sendLoggingEventToBackend({
                        intentName: "premiumGreaterThanZeroKeepSuccess",
                        text: "premiumGreaterThanZeroKeepSuccess"
                    });
                    props.sendEventToDataLayer({
                        event: "chatbot",
                        eventDetails: {
                          name: "cici_sb260_premiumGreaterThanZero"
                        }
                      });
                } else {
                    setCurrentPage(Pages.KEEP_PLAN_DETAILS);
                }
                return;
            }
        
            if (event.data.type === "cancel") {
                props.sendLoggingEventToBackend({
                    intentName: "CancelAuthenticateSuccess",
                    text: "CancelAuthenticateSuccess"
                });
                setCurrentPage(Pages.CANCEL_PLAN_DETAILS);
                return;
            }
        }

        if (event.name === 'SHOW_AUTHENTICATE_SUCCESS' && event.data.type === "cancel") {
            setPlanDetails({
                plan: event.data.plan,
                monthlyPremium: event.data.monthlyPremium,
                isZeroPremium: event.data.isZeroPremium,
                ...event.data
            });
            props.sendLoggingEventToBackend({
                intentName: "CancelAuthenticateSuccess",
                text: "CancelAuthenticateSuccess"
            })
            setCurrentPage(Pages.CANCEL_PLAN_DETAILS);
            return;
        }

        if (event.name === 'SHOW_OPT_IN_SUCCESS' && !event.data.isZeroPremium) {
            setCurrentPage(Pages.OPT_IN_RESULT_NON_ZERO_PREMIUM);
            return;
        }

        if (event.name === 'SHOW_OPT_IN_SUCCESS' && event.data.isZeroPremium) {
            setCurrentPage(Pages.OPT_IN_RESULT_ZERO_PREMIUM);
            return;
        }

    const eventActions = {
        SHOW_OPT_OUT_SUCCESS: {
          setPage: Pages.CANCEL_RESULT,
        },
        SHOW_UNSUPPORTED_SCENARIO: {
          setPage: Pages.CONTACT_SUPPORT,
          logEvent: "M2CFlowUnsupportedScenario",
        },
        SHOW_NO_APS_INDIVIDUAL: {
          setPage: Pages.IS_NOT_APS_INDIVIDUAL,
          logEvent: "M2CFlowNoApsIndividual",
        },
        SHOW_USER_NOT_FOUND: {
          setPage: Pages.AUTHENTICATION,
          logEvent: "M2CFlowUserNotFound",
        },
        SHOW_SERVICE_TIMEOUT_ERROR: {
          setPage: Pages.AUTHENTICATION,
          logEvent: "KeepFlowTimeOutError",
          dataLayerEvent: "cici_sb260_timeout_error",
        },
        SHOW_SERVICE_ERROR: {
          setPage: Pages.AUTHENTICATION,
          logEvent: "APIServiceError",
          dataLayerEvent: "cici_sb260_service_error",
        },
        SHOW_SERVICE_UNAVAILABLE_ERROR: {
          setPage: Pages.AUTHENTICATION,
          logEvent: "APIServiceUnavailableError",
          dataLayerEvent: "cici_sb260_service_unavailable_error",
        },
      };
      
      const action = eventActions[event.name];
      
      if (action) {
        setCurrentPage(action.setPage);
      
        if (action.logEvent) {
          props.sendLoggingEventToBackend({
            intentName: action.logEvent,
            text: action.logEvent,
          });
        }
      
        if (action.dataLayerEvent) {
          props.sendEventToDataLayer({
            event: "chatbot",
            eventDetails: {
              name: action.dataLayerEvent,
            },
          });
        }
      
        return;
      }
      
  
}, [event.data, event.name]);

    const Pages = {
        AUTHENTICATION: 0,
        KEEP_PLAN_DETAILS: 1,
        CANCEL_PLAN_DETAILS: 2,
        SOURCE_OF_HEALTH_COVERAGE: 3,
        CONSENT: 4,
        OPT_IN_RESULT_ZERO_PREMIUM: 5,
        OPT_IN_RESULT_NON_ZERO_PREMIUM: 6,
        CANCEL_RESULT: 7,
        CONTACT_SUPPORT: 8,
        IS_NOT_APS_INDIVIDUAL: 9,
        FEEDBACK_PAGE: 10,
        THANKYOU_PAGE: 11,
        KEEPYOURPLAN_BILL_PAGE: 12
    };

    const pages = [
        <Sb260WizardAuthenticationPage onSubmit={authenticate} onBack={close} data={planDetails} lang={props.lang} error={getEventNameMessageMap(event) ? htmlDecode(getEventNameMessageMap(event)) : null} />,
        <Sb260WizardKeepPlanDetailsPage onBack={() => { setCurrentPage(0) }} data={planDetails} lang={props.lang} onNext={() => setCurrentPage(4)} onPlanDetailsUpdate={handlePlanDetailsUpdate} />,
        <Sb260WizardCancelPlanDetailsPage onBack={() => { setCurrentPage(0) }} data={planDetails} lang={props.lang} setCurrentPage={setCurrentPage} sendLoggingEventToBackend={props.sendLoggingEventToBackend}/>,
        <Sb260WizardSourceOfHealthCoveragePage onNext={() => { setCurrentPage(7); }} lang={props.lang} onOptOut={optOut} onBack={() => { setCurrentPage(2); }} data={planDetails} setCurrentPage={setCurrentPage}  eventName={event.name} sendLoggingEventToBackend={props.sendLoggingEventToBackend}/>,
        <Sb260WizardConsentPage onBack={() => { setCurrentPage(1) }} lang={props.lang} onSubmit={optIn} sendLoggingEventToBackend={props.sendLoggingEventToBackend} eventName={event.name}/>,
        <Sb260WizardOptInResultZeroPremiumPage onNext={() => { setCurrentPage(10) }} lang={props.lang} sendLoggingEventToBackend={props.sendLoggingEventToBackend}/>,
        <Sb260WizardOptInResultNonZeroPremiumPage onNext={() => { setCurrentPage(10); }} lang={props.lang} />,
        <Sb260WizardCancelResultPage onNext={() => { setCurrentPage(10); }} lang={props.lang} sendLoggingEventToBackend={props.sendLoggingEventToBackend} />,
        <Sb260ContactSupportMixedHouseHoldPage onClose={close} sendLoggingEventToBackend={props.sendLoggingEventToBackend} lang={props.lang} chatWithRepresentative={props.chatWithRepresentative} />,
        <Sb260ContactSupportIsNotAPSIndividualPage onClose={close} onNext={() => { setCurrentPage(7) }} sendLoggingEventToBackend={props.sendLoggingEventToBackend} lang={props.lang} chatWithRepresentative={props.chatWithRepresentative} />,
        <Sb260WizardFeedbackPage onClose={close} onNext={() => { setCurrentPage(11); }} sendLoggingEventToBackend={props.sendLoggingEventToBackend} lang={props.lang} />,
        <Sb260WizardThankYouPage onClose={close} sendLoggingEventToBackend={props.sendLoggingEventToBackend} lang={props.lang} />,
        <Sb260WizardKeepyourplanAndPayBillPage sendLoggingEventToBackend={props.sendLoggingEventToBackend} lang={props.lang} setCurrentPage={setCurrentPage} onNext={() => { setCurrentPage(10) }} data={planDetails}  onClose={close}/>,
    ]

    const getPage = () => {
        return [pages[currentPage]];
    }

    return (
        <Wizard utilityOptions={utilityOptions} pages={getPage()}>
          {isLoading && (
            <div
              style={{
                position: "absolute",
                left: "0",
                top: "0",
                width: "100%",
                height: "100%",
                background: "black",
                opacity: ".5",
                zIndex: "3",
                display: "flex",
              }}
            >
             <div className="spinner-border text-light m-auto" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          )}
          {helpModal}
        </Wizard>
      );
    }
    
    
    export default Sb260Wizard;
