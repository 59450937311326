import React from "react";
import "./Activity.css";

// Creates the .remove() function if it does not exist... for IE
if (!("remove" in Element.prototype)) {
  Element.prototype.remove = function () {
    if (this.parentNode) {
      this.parentNode.removeChild(this);
    }
  };
}

class SuggestedActions extends React.Component {
  sendSuggestedActions = (action, divId) => {
    //console.log(action)
    //console.log(this.props)
    document.getElementById(divId).remove();
    //this.props.activity.suggestedActions.actions = []
    localStorage.setItem(divId, true);
    if (action === "Mas opciones" || action === "Más opciones" || action === "I want to see more options") {
      this.props.props.postActivity({
        type: "event",
        name: "showMoreOptions",
        value: {
          followUpSuggestedActions: this.props.activity.entities[0].type,
        },
      });
    } else {
      this.props.props.store.dispatch({
        type: "WEB_CHAT/SEND_MESSAGE",
        payload: {
          text: action + "|||||suggestedAction",
        },
      });
    }
    this.props.props.context.scrollToEnd();
  };

  render() {
    var suggestedActionsArray = [];
    if (!localStorage.hasOwnProperty(this.props.activity.id)) {
      // console.log(this.props)
      for (var i = 0; i < this.props.activity.suggestedActions.actions.length; i++) {
        var suggestedAction = (
          <div className="suggestedActionsBox" key={this.props.activity.suggestedActions.actions[i].value}>
            <button
              aria-label={"suggested action: " + this.props.activity.suggestedActions.actions[i].value}
              className="suggestedActions"
              onClick={this.sendSuggestedActions.bind(
                this,
                this.props.activity.suggestedActions.actions[i].value,
                this.props.activity.id
              )}
              key={"suggested-action" + i}
            >
              {this.props.activity.suggestedActions.actions[i].value}
            </button>
          </div>
        );
        suggestedActionsArray.push(suggestedAction);
      }
    }
    var finalAns = (
      <div id={this.props.activity.id}>
        <strong>{suggestedActionsArray}</strong>
      </div>
    );
    // setTimeout(() => {

    //   for(var i=0;i<document.getElementsByClassName("suggestedActionsBox").length;i++)
    //   {
    //     document.getElementsByClassName("suggestedActionsBox")[i].style.alignItems = "left"
    //     document.getElementsByClassName("suggestedActionsBox")[i].style.alignItems = "center"
    //   }
    //   console.log("Refreshed Now!")
    // }, 5000);

    return finalAns;
  }
}

export default SuggestedActions;
