import clearInactivityTimeout from './clearInactivityTimeout';
import deactivateLiveAgentTimeout from './deactivateLiveAgentTimeout';
import hideEndChatButton  from './../endChatButton/hideEndChatButton'


function endLiveAgentButtonClick (t) {
  t.state.store.dispatch({
    type: 'WEB_CHAT/SEND_EVENT',
    payload: {
      name: 'closeLiveChat',
      value: {
      reason:"User clicked end chat button."
    }
    }
  });
  clearInactivityTimeout(t);
  deactivateLiveAgentTimeout(t);
  hideEndChatButton(t)
}

export default endLiveAgentButtonClick;