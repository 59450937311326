import React from 'react';
import useDollarAmountFormatter from '../hooks/useDollarAmountFormatter';
import config from './config';
import { configURLs } from "../Constants";
import useConsumerName from "./hooks/useConsumerName";
import useAppId from "./hooks/useAppId";
import useAffordabilityDetermination from "./hooks/useAffordabilityDetermination";

function Review(props) {
    const {getFormattedDollarAmount} = useDollarAmountFormatter();
    const lang = document.documentElement.lang;
    const year = props.selectedYear || "2024";
    const { AppConsumerName, setAppConsumerName } = useConsumerName();
    const { AppId, SetAppId} = useAppId();

    const today = new Date();
    const cutoffDate = new Date(today.getFullYear(), 10, 30);
    const showEditIcon = today <= cutoffDate;

    const { calculations, setCalculations } = props;


    let contributionsCollectedDisplayText;

    switch (props.contributionsCollected) {
        case 26:
            contributionsCollectedDisplayText = config.copy[lang].review.every2Weeks
            break;
        case 52:
            contributionsCollectedDisplayText = config.copy[lang].review.weekly;
            break;
        case 24:            
            contributionsCollectedDisplayText = config.copy[lang].review.twiceAMonth
            break;
        case 12:
            contributionsCollectedDisplayText = config.copy[lang].review.monthly
            break;
        case 4:
            contributionsCollectedDisplayText = config.copy[lang].review.quarterly
            break;
        case 1: 
            contributionsCollectedDisplayText = config.copy[lang].review.yearly;
            break;
        default:
            contributionsCollectedDisplayText = 'Error';            
    }

    const formatCurrencyWithoutTrailingZeros = (amount) => {
        let numberValue = parseFloat(amount.replace(/[$,]/g, ''));
        return `$${numberValue.toLocaleString(undefined, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2
        })}`;
    }

    const affordabilityIndividualResult = useAffordabilityDetermination(
        props.affordabilityIndividual,
        "individual",
        document.documentElement.lang
      );
      
      const affordabilityFamilyResult = useAffordabilityDetermination(
        props.affordabilityFamily,
        "family",
        document.documentElement.lang
      );


    const addToCalculationObj = () =>{
        const newCalculation ={
            Name: AppConsumerName,
            ApplicationId: AppId,
            HouseholdIncome: props.annualIncome,
            EmployeeOnlyAmount: props.selfContribution,
            FamilyAmount: props.familyContribution,
            PlanYear: year,
            Frequency: contributionsCollectedDisplayText,
            AffordabilityDeterminationIndividual: affordabilityIndividualResult.component,
            affordabilityDeterminationFamily: affordabilityFamilyResult.component
        };
        setCalculations(prevCalculationObj => [...prevCalculationObj, newCalculation])
    };
   

    const handleClick = () =>{

        addToCalculationObj();
        props.stepUp()
    }

    return (
        <>
            <h2>{config.copy[lang].review.heading}</h2>
            <div className="row">
                <div className='col-12'>
                    <p>{config.copy[lang].review.paragraph1}</p>
                </div>
                 <div className="col">
                    <div className="yearOfCoverage">{config.copy[lang].review.item5}</div>
                    <div className="yearOfCoverageValue">{(year)}</div>
                </div>  
                { showEditIcon && 
                <div className="col-2">
                    <img
                        src={configURLs.url.BASE_URL + "/images/botImages/edit-icon.svg"}
                        alt="Edit Year of Coverage"
                        onClick={() => props.goToStep(2)}
                        className="edit-icon"
                    />
                </div> 
                }
            </div>
            <div className="row">
                <div className="col">
                <div className="houseHoldIncome" style={{ marginBottom: window.innerWidth >= 768 ? "0px" : "8px", lineHeight: window.innerWidth >= 768 ? "1.2" : "normal" }}>
                    {config.copy[lang].review.item1} {year}
                </div>


                    <div className="houseHoldIncomeValue" style={{marginBottom: "10px", marginTop: window.innerWidth >= 768 ? "-4px" : "-10px",}}>{formatCurrencyWithoutTrailingZeros(props.annualIncome)}</div>
                </div>
                <div className="col-2">
                    <img
                         src={configURLs.url.BASE_URL + "/images/botImages/edit-icon.svg"}
                        alt="Edit Household Income"
                        onClick={() => props.goToStep(3)}
                        className="edit-icon"
                    />
                </div>
            </div>            
        
            <div className="row">
                <div className="col">
                    <div className='healthCoverageCost' style={{marginBottom: "-8px"}}>{config.copy[lang].review.item3}</div>
                    <div className='healthCoverageCostValue'>{formatCurrencyWithoutTrailingZeros(props.selfContribution)} 
                        {lang === "en" ? " for Employee-only" : " solo para el empleado"}
                    </div>
                </div>
                <div className="col-2">
                    <img
                        src={configURLs.url.BASE_URL + "/images/botImages/edit-icon.svg"}
                        alt="Edit Your Health Coverage Costs"
                        onClick={() => props.goToStep(4)}
                        className="edit-icon"
                    />
                </div>  
                
            </div>

            {props.isFamilyCoverageApplicable ? 
                <div className="row">
                    <div className="col">
                        <div  className='healthCoverageCostValue' style={{marginTop: "-16px", marginBottom: "10px"}}>{formatCurrencyWithoutTrailingZeros(props.familyContribution)}
                        {lang === "en" ? " for Family" : " para la familia"}
                         </div>
                    </div>
                </div>
            : null}
            <div className="row">
            <div className="col">  
            <div  className='healthCoverageCostValue' style={{marginTop: "-18px", marginBottom: "10px"}}>{contributionsCollectedDisplayText}
                </div>
              
            </div>
            </div>
            <div className='form-group mt-auto row'>
            <div className='col-12 mb-2'>
                    <button onClick={handleClick} type="button" className='btn btn-block ac-pushButton style-default'>{config.copy[lang].utilities.calculate}</button>
                </div>
                <div className='col-12 mb-2'>
                    <button onClick={props.stepDown} type="button" className='btn btn-block btn-outline ac-pushButton style-default'>{config.copy[lang].utilities.back}</button>
                </div>
            
            </div>
        </>
    )
}

export default Review;