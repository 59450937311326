import React from 'react';

function WizardUtilityOption(props){
    const {onClick, onMouseOver, children} = props;
    return(
        <button 
            onClick={onClick} 
            onMouseOver={onMouseOver}
            className='wizard__utility-option btn btn-link '
        >
            {children}
        </button>
    )
}

export default WizardUtilityOption;