import React, { useEffect, useState } from 'react';
import translations from '../translation';

function Sb260WizardConsentPage({ onSubmit, onBack, sendLoggingEventToBackend, lang, eventName }) {
  const { acceptTermsheading, acceptTermsintro, acceptTermssubmitText, backText } = translations[lang];
  const [accepted, setAccepted] = useState(false);
  const [showError, setShowError] = useState(false);
  const [disableContinue, setDisableContinue] = useState(false);

  useEffect(() => {
    sendLoggingEventToBackend({
      intentName: "KeepConfirmedPlanDetails",
      text: "KeepConfirmedPlanDetails"
    });
    setDisableContinue(false);
  }, [sendLoggingEventToBackend]);

  useEffect(() => {
    if (eventName === "SHOW_SERVICE_TIMEOUT_ERROR") {
      setShowError(true);
      setAccepted(false);
      setDisableContinue(true);
    } else {
      setShowError(false);
      setDisableContinue(false);
    }
  }, [eventName]);

  const handleSubmit = () => {
    if (!accepted) {
      setShowError(true);
    } else {
      setShowError(false);
      onSubmit();
    }
  }


  return (
    <div className='position-relative h-100 d-flex flex-column'>
      <h2>{acceptTermsheading}</h2>
      <p>{acceptTermsintro}</p>
      {showError && (
        <div className="wizard__error-message">
          <div className="wizard__error-message-left-container">
            <img className="wizard__error-message-icon" src="/cici-assets/images/botImages/Red-Exclamation.svg" alt="Error" />
          </div>
          <div className="wizard__error-message-right-container">
            {lang === "en"
              ? "Oops, something went wrong. Please come back later or use the 'Help' option for support.."
              : "Ups, algo salió mal. Por favor, inténtalo de nuevo o usa la opción de 'Ayuda' para obtener soporte."
            }
          </div>

        </div>
      )}
      <div className="wizard__list-items-container">
        <ul className='wizard__list-items'>
          {translations[lang].acceptTermstermConditionItems.map(item => (<li key={item}>{item}</li>))}
        </ul>
        <div>{translations[lang].termConditionsFooterText}</div>
      </div>
      <div>
        <label className='d-flex align-items-center mt-4'>
          <input
            type="checkbox"
            checked={accepted}
            onChange={() => setAccepted(!accepted)}
            disabled={disableContinue}
          />
          <span className='ml-1'>{translations[lang].acceptText}</span>
        </label>
      </div>
      <div className='form-group mt-auto'>
        <button className={!accepted || disableContinue ? 'btn btn-block btn-disabled ac-pushButton mt-auto' : 'btn btn-block ac-pushButton mt-auto'} disabled={!accepted || disableContinue} onClick={handleSubmit}>{acceptTermssubmitText}</button>
        <button className='btn btn-block btn-outline ac-pushButton' onClick={onBack}>{backText}</button>
      </div>
    </div>
  )
}

export default Sb260WizardConsentPage;