import React, { useEffect, useState } from "react";
import Intro from "./Intro";
import HouseholdIncome from "./HouseholdIncome";
import HealthContributionCost from "./HealthContributionCost";
import HealthContributionFrequency from "./HealthContributionFrequency";
import Review from "./Review";
import AffordabilityDetermination from "./AffordabilityDetermination";
import Warning from "./Warning";
import sendEventToDataLayer from "../datalayer-event-logger-for-transactions/sendEventToDataLayer";
import YearOfCoverage from "./yearOfCoverage";
import AppIntro from "./AppIntro";
import EsiOffers from "./domain/EsiOffers";
import HealthContributionCostApp from "./HealthContributionCostApp";
import ReviewApp from "./ReviewApp";
import YearOfCoverageApp from "./yearOfCoverageApp";


function Calculator(props) {
  const [employerCollectionFrequency, setEmployerCollectionFrequency] =
    useState("");
  const [
    employeeContributionForIndividual,
    setEmployeeContributionForIndividual,
  ] = useState("");
  const [employeeContributionForFamily, setEmployeeContributionForFamily] =
    useState("");
  const [householdIncome, setHouseholdIncome] = useState("");
  const [isFamilyCoverageApplicable, setIsFamilyCoverageApplicable] =
    useState(true);
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedFrequencyOption, setSelectedFrequencyOption] = useState("");
  const AFFORDABILITY_THRESHOLD = selectedYear === 2023 ? 9.12 : 8.39;
  const [selectedOption, setSelectedOption] = useState("");
  const isAppInApplication = process.env.REACT_APP_IN_APPLICATION === "true";
  // const initialStep = isAppInApplication ? 7 : 0;
  const initialStep = isAppInApplication ? 7 : 0;
  const [calculations, setCalculations] = useState([]);
  const [yesNoSelection, setYesNoSelection] = useState("");
  const [frequencySelection, setFrequencySelection] = useState("");
  const [employeeContribution, setEmployeeContribution] = useState("");
  const [familyContribution, setFamilyContribution] = useState("");
  const [localeValue, setLocaleValue] = useState("");

  const [step, setStep] = useState(initialStep);

  const [lang, setLang] = useState("");
  const cookieValue = document.cookie;
  const locale = cookieValue
    .split(";")
    .map((item) => item.trim())
    .find((item) => item.startsWith("ahbx_locale="));


  useEffect(() => {
    const cookieValue = document.cookie;
    const locale = cookieValue
      .split(";")
      .map((item) => item.trim())
      .find((item) => item.startsWith("ahbx_locale="));

    if (locale) {
      const localeValue = locale.split("=")[1];
      setLocaleValue(localeValue);
      if (localeValue === "en") {
        setLang("en");
      } else if (localeValue === "es") {
        setLang("es");
      }
    } else {
      const defaultLang = document.documentElement.lang || "en";
      setLocaleValue(defaultLang);
      setLang(defaultLang);
    }
  }, [document.cookie]);

  useEffect(() => {
  }, [localeValue]);


  /**
   * Currently hard coded to check "YES" in the tool
   */
  const onSubmit = () => {
    /**
     * 
     */
    props.closeCici();
    const esiOffers = new EsiOffers(calculations);
    const affordabilityCalculationCustomEvent = new CustomEvent("affordabilityCalculationCustomEvent", {
      detail: {
        result: esiOffers.isAffordable() ? "AFFORDABLE" : "NOT_AFFORDABLE"
      }
    });
    document
      .getElementById("chatbot-root")
      .dispatchEvent(affordabilityCalculationCustomEvent);
  }

  const getRequiredAnnualContribution = (
    employerCollectionFrequency,
    employeeContribution
  ) => {
    return employerCollectionFrequency * employeeContribution;
  };

  const isAffordable = (percentage) => {
    if (percentage > AFFORDABILITY_THRESHOLD) {
      return "NOT AFFORDABLE";
    }
    return "AFFORDABLE";
  };

  const getPercentageOfHouseholdIncome = (
    requiredAnnualContribution,
    householdIncome
  ) => {
    if (requiredAnnualContribution === 0 || householdIncome === 0) {
      return 0;
    }
    const percentage = (
      (parseFloat(requiredAnnualContribution) /
        parseFloat(householdIncome.replace(/,/g, ""))) *
      100
    ).toFixed(2);
    return isNaN(percentage) ? 0 : percentage;
  };

  const employerCollectionFrequencyChange = (e) => {
    switch (e.target.value) {
      case "Every 2 weeks":
        setEmployerCollectionFrequency(26);
        return;
      case "Weekly":
        setEmployerCollectionFrequency(52);
        return;
      case "Twice a month":
        setEmployerCollectionFrequency(24);
        return;
      case "Monthly":
        setEmployerCollectionFrequency(12);
        return;
      case "Quarterly":
        setEmployerCollectionFrequency(4);
        return;
      case "Yearly":
        setEmployerCollectionFrequency(1);
        return;
      default:
        setEmployerCollectionFrequency(0);
        return;
    }
  };

  const onClickCheckAnotherOfferInApp = () => {
    setStep(9);
    setEmployeeContributionForIndividual("");
    setEmployeeContributionForFamily("");
    setIsFamilyCoverageApplicable(true);
    setSelectedFrequencyOption("");
    setEmployerCollectionFrequency("");
    setSelectedOption("");
    sessionStorage.removeItem("selectedOption");
    sessionStorage.removeItem("hasInteracted");
    setYesNoSelection("");
    setFrequencySelection("");
  }

  const onStartOver = () => {
    const newStep = isAppInApplication ? 7 : 2;
    setStep(newStep);
    setEmployeeContributionForIndividual("");
    setEmployeeContributionForFamily("");
    setHouseholdIncome("");
    setIsFamilyCoverageApplicable(true);
    setSelectedFrequencyOption("");
    setSelectedYear("");
    setEmployerCollectionFrequency("");
    setSelectedOption("");
    sessionStorage.removeItem("selectedOption");
    sessionStorage.removeItem("hasInteracted");
    setYesNoSelection("");
    setFrequencySelection("");

    // #affordability-tool-logging
    sendEventToDataLayer({
      event: "chatbot",
      eventDetails: {
        category: "CiCi",
        action: "Affordability Tool Clicks",
        label: "Affordability Tool Start Over",
      },
    });
    if(!isAppInApplication){
      props.sendLoggingEventToBackend({
        intentName:
          lang === "en"
            ? "affordabilityToolStartOver"
            : "affordabilityToolStartOverSpanish",
        text:
          lang === "en"
            ? "Affordability Tool Start Over"
            : "Affordability Tool Start Over Spanish",
      });
    }
  };

  useEffect(() => {
    sessionStorage.removeItem("selectedOption");
    sessionStorage.removeItem("hasInteracted");
  }, []);


  useEffect(() => {
  }, [employerCollectionFrequency]);

  const percentageOfHouseholdIncomeForIndividual =
    getPercentageOfHouseholdIncome(
      getRequiredAnnualContribution(
        employerCollectionFrequency,
        employeeContributionForIndividual
      ),
      householdIncome
    );
  const percentageOfHouseholdIncomeForFamily = getPercentageOfHouseholdIncome(
    getRequiredAnnualContribution(
      employerCollectionFrequency,
      employeeContributionForFamily
    ),
    householdIncome
  );
  const affordabilityIndividual = isAffordable(
    percentageOfHouseholdIncomeForIndividual,
    householdIncome
  );
  const affordabilityFamily = isAffordable(
    percentageOfHouseholdIncomeForFamily,
    householdIncome
  );

  const getDisplayStepForSecondSection = (actualStep) => {
    const stepMap = { 8: 1, 3: 2, 9: 3 };
    return stepMap[actualStep]  // Defaults to 1 if not found
  };


  let view = <div></div>;
  switch (step) {
    case 1:
      view = (
        <Warning
          stepUp={() => {
            setStep(step + 1);
          }}
          stepDown={() => {
            setStep(step - 1);
          }}
          goBack={() =>
            setStep(process.env.REACT_APP_IN_APPLICATION === "true" ? 7 : 0)
          }
        />
      );
      break;
    case 2:
      view = (
        <YearOfCoverage
          yearOfCoverage={selectedYear}
          setSelectedYear={setSelectedYear}
          stepUp={() => {
            setStep(step + 1);
          }}
          stepDown={() =>
            setStep(process.env.REACT_APP_IN_APPLICATION === "true" ? 7 : 1)
          }
          isAppInApplication={isAppInApplication}
        />
      );
      break;
    case 3:
      view = (
        <HouseholdIncome
          // stepUp={() => {
          //   setStep(step + 1);
          // }}
          stepUp={() => {
            setStep(isAppInApplication ? 9 : step + 1);
          }}
          stepDown={() => {
            setStep(isAppInApplication ? 8 : step - 1);
          }}
          startOver={onStartOver}
          income={householdIncome}
          setIncome={setHouseholdIncome}
          contactUs={props.contactUs}
          selectedYear={selectedYear}
          localeValue={localeValue}
        />
      );
      break;
    case 4:
      view = (
        <HealthContributionCost
          stepUp={() => {
            setStep(step + 1);
          }}
          stepDown={() => {
            setStep(step - 1);
          }}
          startOver={onStartOver}
          isFamilyCoverageApplicable={isFamilyCoverageApplicable}
          employeeContributionForIndividual={employeeContributionForIndividual}
          employeeContributionForFamily={employeeContributionForFamily}
          setEmployeeContributionForIndividual={
            setEmployeeContributionForIndividual
          }
          setEmployeeContributionForFamily={setEmployeeContributionForFamily}
          setIsFamilyCoverageApplicable={setIsFamilyCoverageApplicable}
          contactUs={props.contactUs}
          employerCollectionFrequency={employerCollectionFrequency}
          selectedFrequencyOption={selectedFrequencyOption}
          setSelectedYear={setSelectedYear}
          setEmployerCollectionFrequency={setEmployerCollectionFrequency}
          sendLoggingEventToBackend={props.sendLoggingEventToBackend}
        />
      );
      break;
    case 5:
      view = (
        <Review
          stepUp={() => {
            setStep(step + 1);
          }}
          stepDown={() => {
            setStep(isAppInApplication ? 8 : step - 1);
          }}
          startOver={onStartOver}
          goToStep={setStep}
          annualIncome={householdIncome}
          employerCollectionFrequency={employerCollectionFrequency}
          contributionsCollected={employerCollectionFrequency}
          selfContribution={employeeContributionForIndividual}
          familyContribution={employeeContributionForFamily}
          isFamilyCoverageApplicable={isFamilyCoverageApplicable}
          setEmployerCollectionFrequency={setEmployerCollectionFrequency}
          contactUs={props.contactUs}
          selectedYear={selectedYear}
          calculations={calculations}
          setCalculations={setCalculations}
          percentageOfHouseholdIncomeForIndividual={
            percentageOfHouseholdIncomeForIndividual
          }
          percentageOfHouseholdIncomeForFamily={
            percentageOfHouseholdIncomeForFamily
          }
          affordabilityIndividual={affordabilityIndividual}
          affordabilityFamily={affordabilityFamily}
          yesNoSelection={yesNoSelection}
        />
      );
      break;
    case 6:
      view = (
        <AffordabilityDetermination
          stepUp={() => {
            setStep(step + 1);
          }}
          stepDown={() => {
            setStep(step - 1);
          }}
          startOver={onStartOver}
          close={props.close}
          submit={onSubmit}
          percentageOfHouseholdIncomeForIndividual={
            percentageOfHouseholdIncomeForIndividual
          }
          percentageOfHouseholdIncomeForFamily={
            percentageOfHouseholdIncomeForFamily
          }
          affordabilityIndividual={affordabilityIndividual}
          affordabilityFamily={affordabilityFamily}
          contactUs={props.contactUs}
          sendMessage={props.sendMessage}
          isFamilyCoverageApplicable={isFamilyCoverageApplicable}
          sendLoggingEventToBackend={props.sendLoggingEventToBackend}
          selectedYear={selectedYear}
          calculations={calculations}
          yesNoSelection={yesNoSelection}
          sendEventToBackend={props.sendEventToBackend}
          setStep={setStep}
          clickCheckAnotherOfferInApp={onClickCheckAnotherOfferInApp}
          localeValue={localeValue}
        />
      );
      break;
    case 7:
      view = (
        <AppIntro
          stepUp={() => {
            setStep(isAppInApplication ? 8 : step - 6);
          }}
          sendLoggingEventToBackend={props.sendLoggingEventToBackend}
          localeValue={localeValue}
        />
      );
      break;
    case 8:
      view = (
        <YearOfCoverageApp
          yearOfCoverage={selectedYear}
          setSelectedYear={setSelectedYear}
          stepUp={() => {
            setStep(step - 5);
          }}
          stepDown={() =>
            setStep(process.env.REACT_APP_IN_APPLICATION === "true" ? 7 : 1)
          }
          isAppInApplication={isAppInApplication}
          localeValue={localeValue}
        />
      );
      break;
    case 9:
      view = (
        <HealthContributionCostApp
          stepUp={() => {
            setStep(step + 1);
          }}
          stepDown={() => {
            setStep(step - 6);
          }}
          startOver={onStartOver}
          isFamilyCoverageApplicable={isFamilyCoverageApplicable}
          employeeContributionForIndividual={employeeContributionForIndividual}
          employeeContributionForFamily={employeeContributionForFamily}
          setEmployeeContributionForIndividual={
            setEmployeeContributionForIndividual
          }
          setEmployeeContributionForFamily={setEmployeeContributionForFamily}
          setIsFamilyCoverageApplicable={setIsFamilyCoverageApplicable}
          contactUs={props.contactUs}
          employerCollectionFrequency={employerCollectionFrequency}
          selectedFrequencyOption={selectedFrequencyOption}
          setSelectedYear={setSelectedYear}
          setEmployerCollectionFrequency={setEmployerCollectionFrequency}
          yesNoSelection={yesNoSelection}
          setYesNoSelection={setYesNoSelection}
          frequencySelection={frequencySelection}
          setFrequencySelection={setFrequencySelection}
          employeeContribution={employeeContribution}
          setEmployeeContribution={setEmployeeContribution}
          familyContribution={familyContribution}
          setFamilyContribution={setFamilyContribution}
          localeValue={localeValue}
          sendLoggingEventToBackend={props.sendLoggingEventToBackend}
        />
      );
      break;
    case 10:
      view = (
        <ReviewApp
          stepUp={() => {
            setStep(step - 4);
          }}
          stepDown={() => {
            setStep(step - 1);
          }}
          startOver={onStartOver}
          goToStep={setStep}
          annualIncome={householdIncome}
          employerCollectionFrequency={employerCollectionFrequency}
          contributionsCollected={employerCollectionFrequency}
          selfContribution={employeeContributionForIndividual}
          familyContribution={employeeContributionForFamily}
          isFamilyCoverageApplicable={isFamilyCoverageApplicable}
          setEmployerCollectionFrequency={setEmployerCollectionFrequency}
          contactUs={props.contactUs}
          selectedYear={selectedYear}
          calculations={calculations}
          setCalculations={setCalculations}
          percentageOfHouseholdIncomeForIndividual={
            percentageOfHouseholdIncomeForIndividual
          }
          percentageOfHouseholdIncomeForFamily={
            percentageOfHouseholdIncomeForFamily
          }
          affordabilityIndividual={affordabilityIndividual}
          affordabilityFamily={affordabilityFamily}
          yesNoSelection={yesNoSelection}
          localeValue={localeValue}
        />
      );
      break;

    case 0:
    default:
      view = (
        <Intro
          stepUp={() => {
            setStep(step + 1);
          }}
          sendLoggingEventToBackend={props.sendLoggingEventToBackend}
        />
      );
      break;
  }

  return (
    <div className="calculator-modal__content">
      {step > 1 && step < 5 ? (
        <div className="calculator-modal__page-number" style={{ minHeight: "26px" }}>
          {step - 1} {lang === "en" ? "of" : "de"} 3
        </div>
      ) : null}
      {/* {step > 7 && step < 10 ? (
            <div className="row mb-3" style={{ minHeight: "26px" }}>
              <div className="col d-flex font-weight-bold">
                {step - 7} {lang === "en" ? "of" : "de"} 3
              </div>
            </div>
          ) : null} */}
      {step > 7 && step < 10 ? (
        <div className="calculator-modal__page-number" style={{ minHeight: "26px" }}>
          {getDisplayStepForSecondSection(step)} {lang === "en" ? "of" : "de"} 3
        </div>
      ) : null}

      {view}
    </div>
  );
}

export default Calculator;
