import { useState, useEffect } from 'react';

const useConsumerName = () => {
  const [AppConsumerName, setAppConsumerName] = useState('');

  useEffect(() => {
    setAppConsumerName(window.appConsumerNameValue);
  }, []);

  return { AppConsumerName, setAppConsumerName };
};

export default useConsumerName;
