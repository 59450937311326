import liveAgentTimeout from '../live-agent/timeout-manager/liveAgentTimeout';
import newMessageAnimation from './newMessageAnimation';

function newMessageCount (t, forceCiCiToOpen = false) {
  //console.log(this.state)
  if(forceCiCiToOpen === false)
  {
    if (!t.state.open) {
      t.setState({newMessages: t.state.newMessages + 1});
      newMessageAnimation(t);
    }
  }
  else
  {
      t.handleOpen();
      liveAgentTimeout(t);
  }
}

export default newMessageCount;