import { useEffect } from 'react';
import React from 'react';
import WizardErrorMessage from '../../components/Wizard/WizardErrorMessage';
import translations from '../translation';


function Sb260WizardOptInResultNonZeroPremiumPage({ onNext, sendLoggingEventToBackend, lang }) {
    const { nonZeroPremiumheading, nonZeroPremiumIntro, nonZeroPremiumWarningHeading, nonZeroPremiumCloseText, backText, nonZeroPremiumbody, nonZeroPremiumWarningBody } = translations[lang];
    return (
        <div className='h-100 d-flex flex-column'>
            <h2>{nonZeroPremiumheading}</h2>
            {/* <h3 style={{fontSize: "1rem",fontWeight: "bold"}}>{nonZeroPremiumIntro}</h3> */}
            <p className='pb-5'>
                {nonZeroPremiumIntro}
                {nonZeroPremiumbody}
            </p>
            <WizardErrorMessage isWarning={true}>
                <div><strong>{nonZeroPremiumWarningHeading}</strong></div>
                <div>{nonZeroPremiumWarningBody}</div>
            </WizardErrorMessage>
            <div className='form-group mt-auto'>
                <button className='btn btn-block ac-pushButton' onClick={onNext}>{nonZeroPremiumCloseText}</button>
            </div>
        </div>
    )
}

export default Sb260WizardOptInResultNonZeroPremiumPage;