import React from 'react';

function WizardPage(props){
    const {children} = props; 
    return(
        <div className='wizard__page'>
            {/* Child will need to be a React Component */}
           {React.Children.map(children, child => React.cloneElement(child, {...props}))}
        </div>
    )
}

export default WizardPage;