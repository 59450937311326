import React, { Component } from 'react';
import { configURLs } from './Constants'
import './Cici.css';

class CiciHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rotate: false
    }
  }

  handleFeedbackClick = (lang) => {
    if (lang === 'es') {
      this.props.store.dispatch({
        type: 'WEB_CHAT/SEND_MESSAGE',
        payload: {
          text: 'Quiero dar un comentario'
        }
      });
    }
    else {
      this.props.store.dispatch({
        type: 'WEB_CHAT/SEND_MESSAGE',
        payload: {
          text: 'feedback'
        }
      });
    }
  }

  handleRotate = () => {
    this.setState({ rotate: !this.state.rotate })
  }

  handleGetTranscript = () => {
    var utteranceElementArray = document.querySelectorAll('.css-ljhy6a.css-7c9av6 .css-1qyo5rb');
    var transcriptJsonArray = [];

    for (var i = 0; i < utteranceElementArray.length; i++) {
      var utteranceObj = {};
      utteranceObj.from = utteranceElementArray[i].getElementsByClassName('avatar').length > 0 ? 'bot' : 'user';
      if (utteranceElementArray[i].getElementsByTagName('p').length > 0) {
        utteranceObj.text = []
        var pTags = utteranceElementArray[i].getElementsByTagName('p');
        for (var j = 0; j < pTags.length; j++) {
          utteranceObj.text.push(pTags[j].innerText);
        }
        transcriptJsonArray.push(utteranceObj);
      }
    }

    console.log(transcriptJsonArray);

    // create a hidden div container
    let transcriptContainer = document.createElement("div");
    transcriptContainer.setAttribute('id', 'transcript-container');

    for (var k = 0; k < transcriptJsonArray.length; k++) {
      // create p tag 
      let utteranceContainer = document.createElement("div");
      let boldTextNode = document.createElement('strong');

      let utteranceOrigin = document.createTextNode(transcriptJsonArray[k].from);
      let utteranceText = document.createTextNode(': ' + transcriptJsonArray[k].text);

      boldTextNode.appendChild(utteranceOrigin);

      // insert utterance into utterance container
      utteranceContainer.appendChild(boldTextNode);
      utteranceContainer.appendChild(utteranceText);

      // insert utterance container into transcriptContainer
      transcriptContainer.appendChild(utteranceContainer);
    }


    var printButton = document.createElement('button');
    var printButtonText = document.createTextNode('Print Transcript');
    printButton.appendChild(printButtonText);

    console.log(transcriptContainer.innerHTML);

    var printContents = transcriptContainer.innerHTML;

    var divContents = printContents;
    var printWindow = window.open('', '', 'height=600,width=400');
    printWindow.document.write('<html><head><title>Transcript</title>');
    printWindow.document.write('</head><body>');
    printWindow.document.write(printButton.outerHTML);
    printWindow.document.write(divContents);
    printWindow.document.write('<script>');
    printWindow.document.write('document.getElementsByTagName("button")[0].addEventListener("click",function(){window.print()})');
    printWindow.document.write('</script>');
    printWindow.document.write('</body>');
    printWindow.document.write('</html>');
    printWindow.document.close();
  }

  render() {
    const getTranscriptText = 'Transcript';
    const getTranscriptSpanishText = 'TranscriptSpanish';
    let supportText = "";
    //let headerImg = "";
    let ciciAvaterImage = configURLs.url.BASE_URL + '/images/botImages/chatbot_bot_avatar.svg';
    if (this.props.liveAgent === true) {
      supportText = 'Live Agent';
      //headerImg = '/images/botImages/chatbot_avatar.svg';
    } else {
      supportText = 'CiCi';
      //headerImg = 'https://www.coveredca.com/images/botImages/ribbon-help-bubble.png';
    }
    const privacyStatementText = 'Privacy';
    const howToTalkToCiciText = 'How to Ask Questions';
    const feedbackText = 'Feedback';
    const supportSpanishText = 'CiCi'
    const privacyStatementSpanishText = 'privacidad';
    const howToTalkToCiciSpanishText = 'Cómo hacer preguntas';
    const feedbackSpanishText = 'Comentarios';

    let headerLanguage = {};

    if (this.props.lang === 'es') {
      headerLanguage = {
        support: supportSpanishText,
        privacy: privacyStatementSpanishText,
        howToTalkToCici: howToTalkToCiciSpanishText,
        feedback: feedbackSpanishText,
        transcript: getTranscriptSpanishText
      }
    } else {
      headerLanguage = {
        support: supportText,
        privacy: privacyStatementText,
        howToTalkToCici: howToTalkToCiciText,
        feedback: feedbackText,
        transcript: getTranscriptText
      }
    }

    const displayBlock = {
      top: 55,
      zIndex: 1,
      opacity: 1
    };
    const displayNone = {
      top: 0,
      zIndex: -1,
      opacity: 0
    };

    return (
      <div className="cici__header" style={{ height: '55px' }}>
          <div className="cici__header-content">
              <ul className="cici__buttons hidden-xxs" style={{ display: "flex" }}>
                  <li><img width="56px" height="56px" src={ciciAvaterImage} alt="" /></li>
                  <li className="cici__name">
                      <b>{headerLanguage.support}</b>
                  </li>
                  <li><button className="cici__button" id="how-to-talk-to-cici" onClick={this.props.handleTalkToCiciClick}>{headerLanguage.howToTalkToCici}</button></li>
                  <div style={{ alignItems: "center", display: "flex" }}>|</div><li><button className="cici__button" id="privacy-statement" onClick={this.props.handlePrivacyClick}>{headerLanguage.privacy}</button></li>
                  <li><button className="cici__button" id="feedback" onClick={() => { this.handleFeedbackClick(this.props.lang); }}>{headerLanguage.feedback}</button></li>
              </ul>
              <ul className="cici__buttons visible-xxs">
                  <li className="cici__name visible-xxs">
                      <img width="56px" height="56px" src={ciciAvaterImage} alt="" />
                      <b>{headerLanguage.support}</b>
                  </li>
                  <li><button className="cici__button" id="privacy-statement" style={{ lineHeight: "0.75rem" }} onClick={this.props.handlePrivacyClick}>{headerLanguage.privacy}</button></li>
              </ul>
          </div>
          <div className="button-container">
              <div className="visible-xxs more-info-button" tabIndex="0" aria-owns="more-options" aria-label="expandable" aria-expanded={this.state.rotate ? "true" : "false"} onClick={() => { this.handleRotate(); this.props.handleHeaderDropdownClick(); }} onKeyPress={(e) => { this.props.handleKeyPress(e, this.handleRotate); this.props.handleKeyPress(e, this.props.handleHeaderDropdownClick); }}>
                  <span className={this.state.rotate === true ? 'material-icons rotated' : 'material-icons'}>more_horiz</span>
              </div>
              <div onKeyPress={(e) => { this.props.handleKeyPress(e, this.props.handleMinimize) }} className="close-button" onClick={this.props.handleMinimize} tabIndex="0"><span className="material-icons">expand_more</span></div>
          </div>
          <ul id="more-options" className="cici__buttons--dropdown" style={this.props.headerDropdown ? displayBlock : displayNone}>
              <li><button className="cici__button--dropdown" id="how-to-talk-to-cici" tabIndex={this.state.rotate ? "0" : "-1"} onClick={() => { this.props.handleTalkToCiciClick(); this.props.handleHeaderDropdownClick(); this.handleRotate(); }}>{headerLanguage.howToTalkToCici}</button></li>
              <li><button className="cici__button--dropdown" id="feedback" tabIndex={this.state.rotate ? "0" : "-1"} onClick={() => { this.handleFeedbackClick(this.props.lang); this.props.handleHeaderDropdownClick(); this.handleRotate(); }}>{headerLanguage.feedback}</button></li>
          </ul>
      </div>
  );
}
}

export default CiciHeader;