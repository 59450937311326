import { useEffect } from "react";
import { useState } from "react";
import React from "react";
import Wizard from "../../components/Wizard/Wizard";
import WelcomeScreen from "./Pages/welcomePage";
import EnrollmentStatusResults from "./Pages/EnrollmentStatusResults";
import ResultWithNoStatus from "./Pages/EnrollmentStatusResultNoStatus";
import HelpOption from "../../sb260/options/Help";
import EnrollmentStatusAuthScreen from "./Pages/EnrollStatusAuthenticationPage";
import Main from "../../sb260/options/Main";
import EnrollmentStatusFeedbackPage from "./Pages/EnrollmentStatusWizardFeedback";
import EnrollmentStatusThankYouPage from "./Pages/EnrollmentStatusWizardThankYouPage";
import EnrollmentStatusContactSupport from "./Pages/EnrollmentStatusContactSupport";
import EnrollmentYear from "./Pages/EnrollmentYear";
import EnrollmentStatusContactSupportMultipleGroups from "./Pages/EnrollmentStatusContactSupportMultipleGroups";
import ATHelpModal from "../../calculator/ATHelpModal";
import ATHelpModalAfterServiceHour from "../../calculator/ATHelpModalAfterServiceHours";
import isServiceOpen from "../../calculator/utitilies/ServiceHours";
function EnrollmentStatusWizard(props) {
  const {
    close,
    sendActivity,
    event,
    onHelpClick,
    lang,
    serviceHourData,
    isOpen,
    closeContactSupport,
    chatWithRepresentative,
  } = props;
  const [planDetails, setPlanDetails] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const [selectedYear, setSelectedYear] = useState("");
  const [navigationSource, setNavigationSource] = useState("");
  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);
  const [isWithinHours, setIsWithinHours] = useState(isServiceOpen(serviceHourData, lang));

  const toggleHelpModal = () => {
    setIsHelpModalOpen(!isHelpModalOpen);
  };

  const eventNameMessageMap = {
    SHOW_SERVICE_ERROR:
      "Oops, something went wrong. Please come back later or use the 'Help' option for support.",
  };

  const eventNameMessageMapEs = {
    SHOW_SERVICE_ERROR:
      "Ups, algo salió mal. Por favor, inténtalo de nuevo o usa la opción de 'Ayuda' para obtener soporte.",
  };

  const getEventNameMessageMap = (event) => {
    const eventMap =
      lang === "es" ? eventNameMessageMapEs : eventNameMessageMap;
    if (
      event.name === "SHOW_SERVICE_ERROR" ||
      event.name === "SHOW_SERVICE_TIMEOUT_ERROR" ||
      event.name === "SHOW_SERVICE_UNAVAILABLE_ERROR"
    ) {
      return eventMap["SHOW_SERVICE_ERROR"];
    }
    return eventMap[event.name];
  };

  const authenticate = async ({ dob, ssn, zip }) => {
    setIsLoading(true);
    await sendActivity({
      type: "message",
      value: {
        wizard: "enrollmentstatus",
        dob,
        ssn,
        zip,
      },
    });
  };

  const sendType = async () => {
    setIsLoading(true);
    await sendActivity({
      type: "message",
      value: {
        wizard: "enrollmentstatus",
      },
    });
  };

  const utilityOptions = [
    <button 
      class="wizard__utility-option btn btn-link "
      onClick={toggleHelpModal}>
      {lang === "es" ? "Ayuda" : "Help"}
    </button>,
    <Main
      onClose={close}
      sendLoggingEventToBackend={props.sendLoggingEventToBackend}
      lang={lang}
    />,
  ];

  useEffect(() => {
    console.log("event.name", event);
    console.log("------------------------------->", event.data);
    setIsLoading(false);
    if (event.name === "SHOW_START") {
      setCurrentPage(Pages.WELCOMESCREEN);
      return;
    }
  }, [event.data, event.name]);

  console.log("event.name", event.name);

  useEffect(() => {
    if (event.name === "SHOW_AUTHENTICATE_SUCCESS") {
      console.log("Authentication Success Event Data:", event.data);
      setPlanDetails({
        plan: event.data.plan,
        monthlyPremium: event.data.monthlyPremium,
        isZeroPremium: event.data.isZeroPremium,
        ...event.data,
      });

      if (
        event.data.getDetails &&
        event.data.getDetails["2023"] &&
        event.data.getDetails["2024"]
      ) {
        setNavigationSource("enrollmentYear");
        setCurrentPage(Pages.ENROLLMENTYEAR);
      } else {
        setNavigationSource("authentication");
        setCurrentPage(Pages.ENROLLMENTRESULTS);
      }
      let intentName = (lang === "es" ? "enrollmentStatusAuthenticateSuccessSpanish" : "enrollmentStatusAuthenticateSuccess")
      let text = (lang === "es" ? "Enrollment Status Authenticate Success Spanish" : "Enrollment Status Authenticate Success")
      props.sendLoggingEventToBackend({
        intentName: intentName,
        text: text,
      });
    }
    if (event.name === "SHOW_USER_NOT_FOUND") {
      console.log("user not found", event.data);
      setPlanDetails({
        plan: event.data.plan,
        monthlyPremium: event.data.monthlyPremium,
        isZeroPremium: event.data.isZeroPremium,
        ...event.data,
      });
      let intentName = (lang === "es" ? "enrollmentStatusUserNotFoundSpanish" : "enrollmentStatusUserNotFound")
      let text = (lang === "es" ? "Enrollment Status User Not Found Spanish" : "Enrollment Status User Not Found")
      props.sendLoggingEventToBackend({
        intentName: intentName,
        text: text,
      });
      setCurrentPage(4);
    }
    if (event.name === "SHOW_NO_ENROLLMENT_STATUS") {
      console.log("No enrollment", event.data);
      setPlanDetails({
        plan: event.data.plan,
        monthlyPremium: event.data.monthlyPremium,
        isZeroPremium: event.data.isZeroPremium,
        ...event.data,
      });
      let intentName = (lang === "es" ? "enrollmentStatusNoResultSpanish" : "enrollmentStatusNoResult")
      let text = (lang === "es" ? "Enrollment Status No Result Spanish" : "Enrollment Status No Result")
      props.sendLoggingEventToBackend({
        intentName: intentName,
        text: text,
      });
      setCurrentPage(4)
    }
    if (event.name === "SHOW_UNSUPPORTED_SCENARIO") {
      console.log("user not found", event.data);
      setPlanDetails({
        plan: event.data.plan,
        monthlyPremium: event.data.monthlyPremium,
        isZeroPremium: event.data.isZeroPremium,
        ...event.data,
      });
      let intentName = (lang === "es" ? "enrollmentStatusUnsupportedMultiCaseSpanish" : "enrollmentStatusUnsupportedMultiCase")
      let text = (lang === "es" ? "Enrollment Status Unsupported Multi-Case Spanish" : "Enrollment Status Unsupported Multi-Case")
      props.sendLoggingEventToBackend({
        intentName: intentName,
        text: text,
      });
      setCurrentPage(7);
    }
  }, [event, props]);

  // const checkPlansAndNavigate = (selectedYear) => {
  //     if (planDetails && planDetails.getDetails[selectedYear]) {
  //       const details = planDetails.getDetails[selectedYear];
  //       if (details && (details.Health.length > 1 || details.Dental.length > 1)) {
  //         setCurrentPage(Pages.CONTACTSUPPORTMULTI);
  //       } else {
  //         setCurrentPage(Pages.ENROLLMENTRESULTS);
  //       }
  //     }
  //   };

  const htmlDecode = (input) => {
    var e = document.createElement('div');
    e.innerHTML = input;
    return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
}

  const handleStepUp = () => {
    console.log("currentPage", currentPage);
    setCurrentPage(currentPage + 1);
  };

  const handleStepDown = () => {
    setCurrentPage(currentPage - 1);
  };
  const Pages = {
    WELCOMESCREEN: 0,
    AUTHENTICATION: 1,
    ENROLLMENTYEAR: 2,
    ENROLLMENTRESULTS: 3,
    ENROLLMENTNORESULT: 4,
    FEEDBACK: 5,
    THANKYOU: 6,
    CONTACTSUPPORT: 7,
    CONTACTSUPPORTMULTI: 8,
  };

  const pages = [
    <WelcomeScreen
      onNext={() =>{setCurrentPage(1)}}
      onClose={close}
      lang={props.lang}
      data={planDetails}
      sendLoggingEventToBackend={props.sendLoggingEventToBackend}
      onSubmit={sendType}
    />,
    <EnrollmentStatusAuthScreen
      setCurrentPage={setCurrentPage}
      onBack={close}
      lang={props.lang}
      sendLoggingEventToBackend={props.sendLoggingEventToBackend}
      onSubmit={authenticate}
      data={planDetails}
      error={getEventNameMessageMap(event) ? htmlDecode(getEventNameMessageMap(event)) : null}
    />,
    <EnrollmentYear
      onNext={handleStepUp}
      onBack={close}
      onClose={close}
      data={planDetails}
      lang={props.lang}
      setSelectedYear={setSelectedYear}
      yearOfCoverage={selectedYear}
      sendLoggingEventToBackend={props.sendLoggingEventToBackend}
      // checkPlansAndNavigate={checkPlansAndNavigate}
    />,
    <EnrollmentStatusResults
      onNext={() => {
        setCurrentPage(5);
      }}
      onBack={handleStepDown}
      onClose={close}
      data={planDetails}
      lang={props.lang}
      sendLoggingEventToBackend={props.sendLoggingEventToBackend}
      selectedYear={selectedYear}
      navigationSource={navigationSource}
    />,
    <ResultWithNoStatus
      onNext={() => setCurrentPage(5)}
      onClose={close}
      lang={props.lang}
      sendLoggingEventToBackend={props.sendLoggingEventToBackend}
    />,
    <EnrollmentStatusFeedbackPage
      onClose={close}
      onNext={() => setCurrentPage(6)}
      sendLoggingEventToBackend={props.sendLoggingEventToBackend}
      lang={props.lang}
    />,
    <EnrollmentStatusThankYouPage
      onClose={close}
      sendLoggingEventToBackend={props.sendLoggingEventToBackend}
      lang={props.lang}
    />,
    <EnrollmentStatusContactSupport
      onClose={close}
      onNext={() => {
        setCurrentPage(5);
      }}
      sendLoggingEventToBackend={props.sendLoggingEventToBackend}
      lang={props.lang}
      chatWithRepresentative={props.chatWithRepresentative}
    />,
    <EnrollmentStatusContactSupportMultipleGroups
      onClose={close}
      onNext={() => {
        setCurrentPage(5);
      }}
      sendLoggingEventToBackend={props.sendLoggingEventToBackend}
      lang={props.lang}
      chatWithRepresentative={props.chatWithRepresentative}
    />,
  ];

  const getPage = () => {
    return [pages[currentPage]];
  };

  const helpModal = isWithinHours ? (
    <ATHelpModal
      localeValue={lang}
      isOpenHelpModal={isHelpModalOpen}
      openATHelpModal={setIsHelpModalOpen}
      sendLoggingEventToBackend={props.sendLoggingEventToBackend}
      serviceHourData={serviceHourData}
    />
  ) : (
    <ATHelpModalAfterServiceHour
      localeValue={lang}
      isOpenHelpModal={isHelpModalOpen}
      openATHelpModal={setIsHelpModalOpen}
      sendLoggingEventToBackend={props.sendLoggingEventToBackend}
      serviceHourData={serviceHourData}
    />
  );
  return (
    <Wizard utilityOptions={utilityOptions} pages={getPage()}>
      {/* Loading overlay */}
      {isLoading && (
        <div
          style={{
            position: "absolute",
            left: "0",
            top: "0",
            width: "100%",
            height: "100%",
            background: "black",
            opacity: ".5",
            zIndex: "3",
            display: "flex",
          }}
        >
          <div className="spinner-border text-light m-auto" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
        {helpModal}
    </Wizard>
  );
  
        }
export default EnrollmentStatusWizard;
