// Limits the range of the randomGenerator
const getRandomIndex = (lim) => Math.floor(Math.random() * lim);

// * Can be used in any File
// messages must be an data structure with indices, like Array
const messageGenerator = (messages) => {
  const randI = getRandomIndex(messages.length);
  return messages[randI];
};

export default messageGenerator;