import React from 'react';

function RadioButtons({ options, selectedOption, setSelectedOption }) {
    return (
        <div className="radio-buttons">
            {options.map((option, index) => (
                <div key={index} className="form-check">
                    <input
                        className="form-check-input"
                        type="radio"
                        name="radioOptions"
                        id={`radio${index}`}
                        value={option.value}
                        checked={selectedOption === option.value}
                        onChange={() => setSelectedOption(option.value)}
                    />
                    <label className="form-check-label" htmlFor={`radio${index}`}>
                        {option.label}
                    </label>
                </div>
            ))}
        </div>
    );
}

export default RadioButtons;