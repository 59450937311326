import React from 'react';
import './ATPopupModal.css';
import './calculator.css';
import '../../src/AffordabilityToolModal.css';
import './affordability.css';
function ATHelpModal(props) {
    // Destructuring localeValue from props
    const { localeValue, serviceHourData, isOpenHelpModal, openATHelpModal, sendLoggingEventToBackend } = props;

    const modalTitle = localeValue === "en" ? "We’re here to help." : "Estamos aquí para ayudar.";
    const liveChatBtnText = localeValue === "en" ? "Live chat with us" : "Chatea en vivo con nosotros";
    const liveChatBtnLink = "https://coveredca-support.secure.force.com/chat/?lang=en_US";
    const callBtnText = localeValue === "en" ? "Call (855) 708-4590" : "Llama al (855) 708-4590";
    const callNumber = "tel:(855) 708-4590";
    const closeModal = () => {
        openATHelpModal(false);
    };

    let weekDayHours = [];
    let extendedHours = [];    
   
    if (serviceHourData && serviceHourData.en && serviceHourData.es) {
        weekDayHours = localeValue === "en" ? serviceHourData.en.weekDayHours : serviceHourData.es.weekDayHours;
        extendedHours = localeValue === "en" ? serviceHourData.en.extendedHours : serviceHourData.es.extendedHours;
    } else {
        console.error("serviceHourData is not defined or missing properties");
    }

    const validExtendedHours = extendedHours.filter(hour => hour.trim() !== "");
    
    if (isOpenHelpModal) {
        const intentName = localeValue === "en" ? "HelpModalOpenInApp" : "HelpModalOpenInAppSpanish";
        const text = localeValue === "en" ? "Help Modal Open In App" : "Help Modal Open In App Spanish";
        sendLoggingEventToBackend({
            intentName: intentName,
            text: text
        });
    }

    return (
        <>
            {isOpenHelpModal && (
                <div className="atModal ciciModal--active">
                    <div className="atModal__modal">
                        <div className="atModal__header">
                            <button className="material-icons atModal__close" onClick={closeModal} onKeyPress={closeModal}>
                                close
                            </button>
                        </div>
                        <div className="atModal__body">
                            <div>
                                <div className="text-left">
                                    <span className='atModal__modal-title' style={{ fontFamily:  'Roboto, sans-serif' }}>{modalTitle}</span>
                                    <div className='atModal__modal-content'  style={{ fontFamily:  'Roboto, sans-serif' }}>
                                        {/* <p>{weekDayHours.join('\n')}</p> */}
                                        <p style={{ fontFamily:  'Roboto, sans-serif' }}>
                                            {weekDayHours.map((hour, index) => (
                                                <span key={index}>
                                                {hour}
                                                {index < weekDayHours.length - 1 && <br />}
                                                </span>
                                            ))}
                                            </p>

                                        {validExtendedHours.length > 0 && (
                                            <>
                                                <br /><br />
                                                <p  style={{ fontFamily:  'Roboto, sans-serif' }}>{localeValue === "en" ? "Extended Hours:" : "Horas extendidas:"} <br />
                                                {validExtendedHours.map((hour, index) => <span key={index}>{hour}<br /></span>)}
                                                </p>
                                            </>
                                        )}
                                    </div>
                                </div>
                                <div className="atModal__btn-container">
                                <a href={liveChatBtnLink} className="calculator-modal__button calculator-modal__button--primary btn btn-block ac-pushButton" style={{ fontFamily:  'Roboto, sans-serif' }}>

                                        {liveChatBtnText}
                                    </a>
                                    <a href={callNumber} className="calculator-modal__button calculator-modal__button--secondary btn btn-block btn-outline ac-pushButton">
                                        {callBtnText}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default ATHelpModal;