import { useEffect } from "react";
import React from 'react';
import translations from "../translation";

function Sb260WizardCancelResultPage({ onNext, sendLoggingEventToBackend, lang}) {
    const { cancelResultHeading, cancelResultIntro, cancelResultBody, cancelResultCloseText } = translations[lang];


    useEffect(() => {
        sendLoggingEventToBackend({
        intentName: "CancelSuccess",
        text: "CancelSuccess"
        });
    }, [sendLoggingEventToBackend]);

    return (
        <div className='h-100 d-flex flex-column'>
            <h2>{cancelResultHeading}</h2>
            <p>{cancelResultIntro}</p>
            <p className='pb-5'>{cancelResultBody}</p>
            <div className='form-group mt-auto'>
                <button className={'btn btn-block ac-pushButton'} onClick={onNext}>{cancelResultCloseText}</button>
            </div>
        </div>
    )
}

export default Sb260WizardCancelResultPage;