import ConnectedActivityWithFeedback from './ConnectedActivityWithFeedback';
import ConnectedActivityWithDataLayerLogging from './datalayer-event-logger-for-transactions/ConnectedActivityWithDataLayerLogging';
import shouldActivitySendDataLayerEvent from './datalayer-event-logger-for-transactions/shouldActivitySendDataLayerEvent';
import React from 'react';
import sendEventToDataLayer from './datalayer-event-logger-for-transactions/sendEventToDataLayer';
import activityToDataLayerEventMapping from './datalayer-event-logger-for-transactions/activityToDataLayerEventMapping.json';

// this is based on 09.customization-reaction-buttons sample. Mainly for using the activity middleware

const activityMiddleware = () => next => card => {

  if (shouldActivitySendDataLayerEvent(card.activity)) {
    return (
      children =>
        <ConnectedActivityWithDataLayerLogging from={card.activity.from.role} key={card.activity.id} activityID={card.activity.id}>
          {next(card)(children)}
        </ConnectedActivityWithDataLayerLogging>
    );
  }

  if (card.activity.attachments && (card.activity.attachments[0].contentType === "application/usernameDobCard" || card.activity.attachments[0].contentType === "application/otpTypeCard" || card.activity.attachments[0].contentType === "application/passcodeCard" || card.activity.attachments[0].contentType === "application/passwordCard" || card.activity.attachments[0].contentType === "application/usernameDobCardForgotUsername")) {
    return next(card);
  }

  if (card.activity.type === "event" && (card.activity.name === "messageHelpful" || card.activity.name === "messageNotHelpful")) {
    return next(card)
  }

  return (
    children =>
      <ConnectedActivityWithFeedback from={card.activity.from.role} key={card.activity.id} activityID={card.activity.id}>
        {next(card)(children)}
      </ConnectedActivityWithFeedback>

  );

};


export default activityMiddleware;
