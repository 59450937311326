export const getLocaleValue = () => {
    const cookieValue = document.cookie;
    const localeCookie = cookieValue
      .split(";")
      .map(item => item.trim())
      .find(item => item.startsWith("ahbx_locale="));
  
    if (localeCookie) {
      return localeCookie.split("=")[1];
    } else {
      return document.documentElement.lang || "en";
    }
  };
  