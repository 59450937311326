import React from 'react';
import './FeedbackAttachment.css';
import StarRating from './StarRating';

class FeedbackAttachment extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      starRating: null,
      commentText: '',
      submitted: null,
      canceled: false,
      transitionComplete: null,
      formValidation: false,
      error: null 
    }
  }

  handleSubmit = () => {
    if(this.state.starRating || this.state.commentText) {
      this.props.postActivity({
        type: 'message',
          value: {
            intentName: 'provideFeedbackResponse',
            parentIntent: 'provideFeedback',
            title: 'provideFeedbackResponse',
            NumVal: this.state.starRating,
            MultiLineVal: this.state.commentText,
            conversationID: this.props.activity.conversation.id,
            timestamp: this.props.activity.timestamp
          }
      }); 
      this.setState({ submitted: true});
      setTimeout(this.handleTransition,1000);
    } else {
      if (this.props.context.locale === "es") {
        this.setState({error: '¡Ups! Olvidaste calificar tu experiencia.'});
      } else {
        this.setState({error: 'Oops! You forgot to rate your experience.'});
      }
   
      setTimeout(() => {this.handleShake()},1);
      setTimeout(() => {this.handleShake()},250);
    }
  }

  handleShake = () => {
    this.setState({formValidation: !this.state.formValidation});
  }

  handleStarRating = (event) => {
    this.setState({ starRating: event });
  }

  handleTyping = (event) => {
    this.setState({ commentText: event.target.value });
  }

  handleTransition = () => {
    this.setState({transitionComplete: true});
  }
  
  render() {
    const cardHeading = "Feedback";
    const cardHeadingSpanish = "Comentarios";
    const cardTextStars = "Let us know how we did"; //"Please rate your experience using CiCi";
    const cardTextStarsSpanish = "Por favor califique su experiencia usando CiCi";
    const cardTextComment = "Please provide any comments on your experience";
    const cardTextCommentSpanish = "Por favor, proporcione cualquier comentario sobre su experiencia";
    const cardTextPlaceholder = "Please provide comments on your experience"; //"Comments";
    const cardTextPlaceholderSpanish = "Comentarios";
    const cardTextSuccess = "Thank you for your feedback!";
    const cardTextSuccessSpanish = "¡Gracias por tus comentarios!";
    const cardButton = "Submit";
    const cardButtonSpanish = "Enviar";

    let cardLanguage = {};

    if(this.props.context.locale === "es") {
      cardLanguage = {
        heading: cardHeadingSpanish,
        stars: cardTextStarsSpanish,
        comment: cardTextCommentSpanish,
        placeholder: cardTextPlaceholderSpanish,
        success: cardTextSuccessSpanish,
        button: cardButtonSpanish
      }
    } else {
      cardLanguage = {
        heading: cardHeading,
        stars: cardTextStars,
        comment: cardTextComment,
        placeholder: cardTextPlaceholder,
        success: cardTextSuccess,
        button: cardButton
      }
    }



    const displayBlock = {
      display: 'block'
    };

    const displayNone = {
      display: 'none'
    };

    if (this.state.transitionComplete) {
      return(
        <div className={ 'feedbackAttachment' }>
          <p className={ 'text-body' }><strong>{cardLanguage.success}</strong></p>
          <i className={ 'material-icons feedbackAttachment__submitted' }>check_circle_outline</i>
        </div>
      );
    }
    return(
      <div className={ 'feedbackAttachment width-100' }>
        <div style={!this.state.transitionComplete ? displayBlock : displayNone}>
          {/* <p className={ 'text-body' }><strong>{cardLanguage.heading}</strong></p> */}
          <p className={ 'margin-b-0 text-body' }>{cardLanguage.stars}</p>
      
          <StarRating onClick={ this.handleStarRating } />
          {this.state.error && <p className="error-message">{this.state.error}</p>}
          <div >
            <label className={ 'text-body width-100' } htmlFor="comment-area">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
            <textarea id="comment-area" placeholder={cardLanguage.placeholder} className={'width-100'} value={ this.state.commentText } onChange={ this.handleTyping }></textarea>
          </div>
        </div>
        <div style={this.state.transitionComplete ? displayBlock : displayNone}>
          <p className={ 'text-body' }><strong>{cardLanguage.success}</strong></p>
        </div>
  
        <div className={ 'feedbackAttachment__button-div ac-actionSet' }>
          <button type="button" aria-label="Submit" className={ 'feedbackAttachment__button ac-pushButton width-100'} onClick={ this.handleSubmit }>
            <div style={this.state.submitted ? displayBlock : displayNone}>
              <span className={'cici-loading'} role="status" aria-hidden="true"></span>
            </div>
            <div style={!this.state.submitted ? displayBlock : displayNone}>
              <div className={!this.state.formValidation ? '' : 'shakeButton'}>
                {cardLanguage.button}
              </div>
            </div>
          </button>
        </div>
      </div>
    );
  }
}

export default FeedbackAttachment;