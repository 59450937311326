import { useState, useRef } from 'react';

function useInteractiveHandlers(initialText, lang,  setShowCloseAffordability, setShowCheckAnother, startOver, close, sendLoggingEventToBackend, setStep, clickCheckAnotherOfferInApp, localeValue) {
  const checkAnotherButtonRef = useRef(null);
  const [text, setText] = useState(initialText);

  const handleYesClick = () => {
    setShowCloseAffordability(true);
    setShowCheckAnother(true);
    setText(
      lang === "en"
        ? "Check Another Coverage Offer"
        : "Revisar otra oferta de cobertura"
    );
    scrollToButton();
  };

  const handleNoClick = () => {
    setShowCloseAffordability(true);
    setShowCheckAnother(true);
    setText(lang === "en" ? "Go to Application" : "Ir a la Aplicación");
    scrollToButton();
  };

  const handleGoToApplication = () => {
    close();
  };

  const handleCheckAnotherClick = () => {
    if (text === "Check Another Coverage Offer" || text === "Revisar otra oferta de cobertura") {
      startOver();
    } else {
      const intentData = lang === "en"
        ? { intentName: "GotoApplicationFromAffordabilityTool", text: "GO TO Application From Affordability Tool" }
        : { intentName: "GotoApplicationFromAffordabilityToolSpanish", text: "GO TO Application From Affordability Tool Spanish" };

      sendLoggingEventToBackend(intentData);
      window.open("https://apply.coveredca.com/static/lw-web/login?", "_blank");
      close();
    }
  };

  const handleCheckAnotherClickForConsumer = () => {
    //startOver();
    //setStep(3);
    const intentName = localeValue === "en" ? "CheckAnotherOfferInApp" : "CheckAnotherOfferInAppSpanish"
    const text = localeValue === "en" ? "Check another offer in app" : "Check another offer in app Spanish"
        sendLoggingEventToBackend({
              intentName,
              text
          });
    clickCheckAnotherOfferInApp();
  }
  
  const scrollToButton = () => {
    if (checkAnotherButtonRef.current) {
      checkAnotherButtonRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return { handleYesClick, handleNoClick, handleGoToApplication, handleCheckAnotherClick,handleCheckAnotherClickForConsumer, checkAnotherButtonRef, text, setText };
}

export default useInteractiveHandlers;
