import React from 'react';
import { configURLs } from "../../Constants";

function WizardErrorMessage({children, isWarning}){
    return(
        <div className={isWarning ? "wizard__error-message wizard__error-message--warning" : "wizard__error-message" }>
            <div className='wizard__error-message-left-container'>
                <img className="wizard__error-message-icon" src={ isWarning ? configURLs.url.BASE_URL + "/images/botImages/Yellow-Exclamation.svg" : configURLs.url.BASE_URL + "/images/botImages/Red-Exclamation.svg"} />
            </div>
            <div className='wizard__error-message-right-container'>
                {children}
            </div>
        </div>
    )
}

export default WizardErrorMessage;