import React from 'react';
import Tooltip from './Tooltip';

const ES = "es";
const EN = "en";

const NEED_HELP_TOOLTIP_TEXT_EN = "Contact your Certified Enroller for assistance. If you are not working with one, use \"Help on Demand\"  to get free expert help.";
const NEED_HELP_TOOLTIP_TEXT_ES = "Comunícate con tu inscriptor certificado para recibir ayuda. Si no estás trabajando con uno actualmente, utiliza Ayuda Inmediata para obtener ayuda gratuita de un experto.";
const INDIVIDUAL_AFFORDABLE_TOOLTIP_TEXT_EN = "Based on the information you provided, your offer of employer-sponsored coverage is considered affordable. This means you do not qualify for financial help through Covered California, but may still enroll.";
const INDIVIDUAL_NOT_AFFORDABLE_TOOLTIP_TEXT_EN = "Based on the information you provided, your offer of  employer-sponsored coverage is  considered  not affordable. This means you may qualify for financial help through Covered California.";
const FAMILY_NOT_AFFORDABLE_TOOLTIP_TEXT_EN = "Based on the information you provided, your offer of employer-sponsored coverage is considered not affordable.This means that your family may qualify for financial help through Covered California.";
const FAMILY_AFFORDABLE_TOOLTIP_TEXT_EN = "Based on the information you provided, your offer of employer-sponsored coverage is considered affordable. This means that your family does not qualify for financial help through Covered California, but may still enroll.";

const INDIVIDUAL_AFFORDABLE_TOOLTIP_TEXT_ES = "Basado en la información que nos diste, la cobertura patrocinada por el empleador que te ofrecen es considerada asequible. No calificas para la ayuda financiera para bajar el costo de un plan de salud de Covered California.";
const INDIVIDUAL_NOT_AFFORDABLE_TOOLTIP_TEXT_ES = "Basado en la información que nos diste, la cobertura patrocinada por el empleador que te ofrecen no es considerada asequible. Podrías calificar para la ayuda financiera para bajar el costo de un plan de salud de Covered California.";
const FAMILY_NOT_AFFORDABLE_TOOLTIP_TEXT_ES = "Basado en la información que nos diste, la cobertura patrocinada por el empleador que te ofrecen no es considerada asequible. Tu familia podría calificar para la ayuda financiera para bajar el costo de un plan de salud de Covered California.";
const FAMILY_AFFORDABLE_TOOLTIP_TEXT_ES = "Basado en la información que nos diste, la cobertura patrocinada por el empleador que te ofrecen es considerada asequible. Tu familia no califica para la ayuda financiera para ayudar a bajar el costo de un plan de salud de Covered California.";

const AFFORDABLE_HEADER = "Does not qualify for financial help through Covered California";
const NOT_AFFORDABLE_HEADER = "May qualify for financial help through Covered California";
const GOT_A_CALL_BACK = "Get a Call Back";
const GOT_A_CALL_BACK_ES = "Recibe una llamada";

function Outro(props){
    const {lang} = props;
    if(lang === "es"){
        return (
            <span>Si no estás seguro, puedes pedirle a tu empleador que llene nuestra <a onClick={()=>{window.dataLayer.push({event: "chatbot",eventDetails:{category: "CiCi", action:"Affordability Tool", label: "Affordability Tool Employer Coverage Worksheet"}})}} href="https://www.coveredca.com/pdfs/SPANISH-VERSION-Pdf-Employer-Sponsored-Coverage-Worksheet-(PDF)_12-07-2022.pdf" target={"_blank"}>Hoja de trabajo para la cobertura del empleador</a>.</span>
        )
    }
    return (
        <span>If you aren't sure, you can ask your employer to fill out our <a onClick={()=>{window.dataLayer.push({event: "chatbot",eventDetails:{category: "CiCi", action:"Affordability Tool", label: "Affordability Tool Employer Coverage Worksheet"}})}} href="https://www.coveredca.com/pdfs/Employer-Sponsored-Coverage-Worksheet.pdf" target={"_blank"}>Employer Coverage Worksheet</a>.</span>
    )
}

function New(props){
    const {lang} = props;
    if(lang === "es"){
        return (
            <span>¿Necesitas ayuda para obtener esta información?
            Tu empleador puede completar la <a  id="appIntroLink" onClick={()=>{window.dataLayer.push({event: "chatbot",eventDetails:{category: "CiCi", action:"Affordability Tool", label: "Affordability Tool Employer Coverage Worksheet"}})}} href="https://www.coveredca.com/pdfs/SPANISH-VERSION-Pdf-Employer-Sponsored-Coverage-Worksheet-(PDF)_12-07-2022.pdf" target={"_blank"}>Hoja de trabajo para la cobertura del empleador</a>.</span>
        )
    }
    return (
        <span>Need help getting this information? Your employer can complete <a  id="appIntroLink" onClick={()=>{
            window.dataLayer.push({
                event: "chatbot",
                eventDetails: {
                    category: "CiCi",  
                    action:"Affordability Tool", 
                    label: "Affordability Tool Employer Coverage Worksheet"}
                });
            }
        }
                    
                href="https://www.coveredca.com/pdfs/Employer-Sponsored-Coverage-Worksheet.pdf" 
                target={"_blank"}>Employer Coverage Worksheet</a>.</span>
    )
}


function EmployeeOnlyCoverageCost(props){
    const {lang} = props;
    if(lang === ES){
        return (
            <>
                <span>¿Cuánto pagarías por la cobertura <strong>solo para el empleado</strong>?</span>
            </>
        )
    }
    return (
        <>
            <span>What would you pay for <strong>employee-only</strong> coverage? </span>
        </>
    )
}

function EmployeeOnlyCoverageCostInApp(props){
    const {lang} = props;
    if(lang === ES){
        return (
            <>
                <span>Utilizando el plan de menor costo ofrecido, ¿cuánto cuesta la cobertura <strong>solo para el empleado</strong>?</span>
            </>
        )
    }
    return (
        <>
            <span>Using the lowest-cost plan offered, what does <strong>employee-only</strong> coverage cost? </span>
        </>
    )
}

function FamilyCoverageCost(props){
    const {lang} = props;
    if(lang === ES){
        return (
            <>
               ¿Cuánto pagarías por la cobertura <strong>familiar</strong> ?
            </>
        )
    }
    return (
        <>
           What would you pay for <strong>family</strong> coverage? 
        </>
    )
}

function FamilyCoverageCostInApp(props){
    const {lang} = props;
    if(lang === ES){
        return (
            <>
              Utilizando el plan de menor costo ofrecido, ¿cuánto cuesta la cobertura <strong>familiar</strong> ?
            </>
        )
    }
    return (
        <>
           Using the lowest-cost plan offered, what does <strong>family</strong> coverage cost? 
        </>
    )
}

const config = {
    "copy": {
        "en": {
            "utilities": {
                "help": "Help",
                "menu": "Menu",
                "getStarted": "Get started",
                "next": "Next",
                "back": "Back",
                "calculate": "Get my results",
                "returnToMenu": "Close Affordability Tool",
                "startOver": "Check another coverage offer",
                "goTo": "Go to Application",
                helpTooltipContent: NEED_HELP_TOOLTIP_TEXT_EN,
                gotACallBack: GOT_A_CALL_BACK,
                applyResults: "Apply results",
                selectPlaceholder: "Select an answer"

            },
            "appIntro":{
                "heading": "Let's check the affordability of coverage offers for Elena.",
                "subHeading" : "To get started, you’ll need to know the following:",
                "bullet1": "Your cost for the least expensive plan the employer offers for the employee and family coverage.",
                "bullet2": "Your estimated total household income.",
                "bullet2TooltipContent":"Include the income for you, your spouse, and anyone you claim as a dependent when you file taxes. The amount needs to include all income you get like salary, self-employment, interest, dividends, alimony, and Social Security.",
                "getStarted": "Get started",
                "new": <New lang={"en"}/>
            },
            "intro": {
                "heading": "Is the health coverage offered by your employer considered affordable? ",
                "subHeading": "Let's find out. First, you will need the following information:",
                "bullet1": "Total estimated household income for coverage year.",
                "bullet1TooltipContent": "Include the income for you, your spouse, and anyone you claim as a dependent when you file taxes. Income includes wages and tips, salary, self-employment income, interest and dividends received, alimony received, Social Security payments received and other income.",
                "bullet2": "Whether the health coverage offered by your employer meets the minimum value standard.",
                "bullet2TooltipContent": "Plan pays at least 60% of the total cost of medical services and substantially covers hospital and doctor services.",
                "bullet3": "Your cost for the lowest-cost health plan offered by your employer for employee-only and family coverage.",
                "outro": <Outro lang={"en"}/>
            },
            "warning": {
                heading: "Before You Begin",
                paragraph1: "Use this tool to determine if the employer-sponsored coverage offered to each member of your household is affordable.",
                paragraph2: "This information will help when you apply for coverage through Covered California.",
                paragraph3: <strong>Please answer all questions carefully.</strong>,
                paragraph4:  "If you enter a wrong amount, you could incorrectly qualify for financial help and would have to pay back some or all the premium tax credits when you file your taxes."
            },
            "householdIncome": {
                heading: "Income",
                label: "What's your total estimated 2023 household income?",
                labelTooltipContent: "Include the income for you, your spouse, and anyone you claim as a dependent when you file taxes. Income includes wages and tips, salary, self-employment income, interest and dividends received, alimony received, Social Security payments received and other income.",
                incomePlaceholder: "Enter income",
                invalidFeedback: "Please provide a household income.",
                confirmation: "Amount entered:"
            },
            "yearOfCoverage": {
                heading: "Coverage Year ",
                label: "Please choose the year you want your coverage to begin.",
                labelTooltipContent: "Include the income for you, your spouse, and anyone you claim as a dependent when you file taxes. Income includes wages and tips, salary, self-employment income, interest and dividends received, alimony received, Social Security payments received and other income.",
                yearOfCoveragePlaceholder: "Select Year",
                invalidFeedback: "Please provide year of Coverage.",
                confirmation: "Year entered:"
            },
            "healthContributionCost": {
                heading: "Health Coverage Costs",
                label1: <EmployeeOnlyCoverageCost lang={EN} />,
                label1App: <EmployeeOnlyCoverageCostInApp lang={EN} />,
                label4: <>
                            Using the lowest-cost plan offered by the employer:
                        </>,
                placeholder1: "Enter amount",
                invalidFeedback1: "Please provide an amount.",
                label2: <FamilyCoverageCost lang={EN} />,
                label2App: <FamilyCoverageCostInApp lang={EN} />,
                placeholder2: "Enter amount",
                invalidFeedback2: "Please provide an amount.",
                label3: "Not offered or does not apply to me",
            },
            "healthContributionFrequency": {
                heading: "How Often You Pay",
                label1: "How often would you pay for coverage?",
                label1App:"How often would this amount be paid?",
                placeholder1: "Select how often",
                invalidFeedback1: "Please select an option",
                option1: "Weekly",
                option2: "Every 2 weeks",
                option3: "Twice a month",
                option4: "Monthly",
                option5: "Quarterly",
                option6: "Yearly"
            },
            "review": {
                heading: "Review and Confirm",
                paragraph1: "Does this information look correct? If not, you can make changes below.",
                item1: "Estimated Total Household Income for",
                item2: "How Often You Pay",
                item3: "Your Health Coverage Costs",
                item4: "Cost of Family Coverage",
                item5: "Year of Coverage",
                edit: "Edit",
                every2Weeks: 'Every 2 weeks',
                weekly: 'Weekly',
                twiceAMonth: 'Twice a month',
                monthly: 'Monthly',
                quarterly: 'Quarterly',
                yearly: 'Yearly',
            },
            "affordabilityDetermination": {
                heading: "Affordability Results",
                headingApp: "Affordability Results",
                subHeading1: "For Employee-Only Coverage",
                subHeadingApp: "This coverage offer is ",
                result1Label1: "Health Coverage Cost: ",
                result1LabelApp: "Coverage cost: ",
                result1Suffix: "of income",
                result1Label2: "Coverage is: ",
                subHeading2: "For Family Coverage",
                subHeadingApp2: "This coverage offer is ",
                result2Label1: "Health Coverage Cost: ",
                result2Suffix: "of income",
                result2Label2: "Coverage is: ",
                result2LabelApp2: "Coverage cost: ",
                footer: "Does your household have another offer of employer-sponsored coverage you would like to check?",
                affordable: "Affordable",
                notAffordable: "Not Affordable",
                affordableApp:  "affordable",
                notAffordableApp: "not affordable",
                individualAffordableTooltipText: INDIVIDUAL_AFFORDABLE_TOOLTIP_TEXT_EN,
                individualNotAffordableTooltipText: INDIVIDUAL_NOT_AFFORDABLE_TOOLTIP_TEXT_EN,
                familyAffordableTooltipText: FAMILY_AFFORDABLE_TOOLTIP_TEXT_EN,
                familyNotAffordableTooltipText: FAMILY_NOT_AFFORDABLE_TOOLTIP_TEXT_EN,
                affordableHeader: AFFORDABLE_HEADER,
                affordableHeaderApp: "Does not qualify for financial help",
                notAffordableHeader: NOT_AFFORDABLE_HEADER,
                notaffordableHeaderApp: "May qualify for financial help",
                yes: "Yes",
                no: "No"
            }
        },
        "es": {
            "utilities": {
                "help": "Ayuda",
                "menu": "Menú",
                "getStarted": "Comenzar",
                "next": "Siguiente",
                "back": "Volver",
                "calculate": "Obtener mis resultados",
                "returnToMenu": "Cerrar ventana",
                "startOver": "Comenzar de nuevo",
                helpTooltipContent: NEED_HELP_TOOLTIP_TEXT_ES,
                gotACallBack: GOT_A_CALL_BACK_ES,
                applyResults: "Aplicar resultados",
                selectPlaceholder: "Seleccionar respuesta"
            },
            "intro": {
                "heading": "¿La cobertura de salud ofrecida por tu empleador se considera asequible (a tu alcance)?",
                "subHeading": "Vamos a averiguarlo. Primero, necesitarás la siguiente información:",
                "bullet1": "Los ingresos totales estimados del hogar para el año de cobertura.",
                "bullet1TooltipContent": "Incluye tus ingresos, los de tu cónyuge y los de todas las personas que reclames como dependientes cuando declares impuestos. Los ingresos incluyen sueldos y propinas, salarios, ingresos de trabajo por cuenta propia, intereses y dividendos recibidos, pensión alimenticia recibida, pagos del Seguro Social recibidos y otros ingresos.",
                "bullet2": "Si la cobertura de salud ofrecida por tu empleador cumple con el estándar de valor mínimo.",
                "bullet2TooltipContent": "El plan debe pagar por lo menos el 60% del costo total de los servicios médicos y cubrir en gran medida los servicios del hospital y del médico.",
                "bullet3": "Tu costo para el plan de salud de menor costo ofrecido por tu empleador para la cobertura familiar y solo para el empleado.",
                "outro": <Outro lang={"es"}/>
            },
            "appIntro":{
                "subHeading" : "Para comenzar, necesitarás saber lo siguiente:",
                "bullet1": "Tu costo por el plan menos caro que ofrece el empleador para la cobertura del empleado y la familia.",
                "bullet2": "El total estimado de los ingresos de tu hogar.",
                "bullet2TooltipContent":"Incluye tus ingresos, los de tu cónyuge y los de todas las personas que reclames como dependientes cuando declares impuestos. La cantidad debe incluir todos los ingresos que recibes, como sueldos, ingresos de trabajo por cuenta propia, intereses, dividendos, pensión alimenticia, y Seguro Social.",
                "getStarted": "Comenzar",
                "new": <New lang={"es"}/>
            },
            "warning": {
                heading: "Antes de que empieces",
                paragraph1: "Utiliza esta herramienta para determinar si la cobertura patrocinada por el empleador ofrecida a cada miembro de tu hogar es asequible o está al alcance de tu bolsillo. ",
                paragraph2: "Esta información te ayudará cuando solicites la cobertura a través de Covered California.",
                paragraph3: <strong>Por favor, responde a todas las preguntas cuidadosamente.</strong>,
                paragraph4: "Si ingresas una cantidad incorrecta, podrías calificar incorrectamente para la ayuda financiera y tendrías que devolver algunos o todos los créditos fiscales para las primas cuando presentes tus impuestos."
            },
            "householdIncome": {
                heading: "Ingresos",
                label: "¿Cuál es tu estimado total de ingresos del hogar para el 2023?",
                labelTooltipContent: "Incluye tus ingresos, los de tu cónyuge y los de todas las personas que reclames como dependientes cuando declares impuestos. La cantidad debe incluir todos los ingresos que recibes, como sueldos, ingresos de trabajo por cuenta propia, intereses, dividendos, pensión alimenticia, y Seguro Social.",
                incomePlaceholder: "Ingresar cantidad",
                invalidFeedback: "Ingresa una cantidad.",
                confirmation: "Cantidad ingresada:"
            },
            "yearOfCoverage": {
                heading: "Año de cobertura",
                label: "Elige el año en el que quieres que comience tu cobertura.",
                labelTooltipContent: "Incluye tus ingresos, los de tu cónyuge y los de todas las personas que reclames como dependientes cuando declares impuestos. Los ingresos incluyen sueldos y propinas, salarios, ingresos de trabajo por cuenta propia, intereses y dividendos recibidos, pensión alimenticia recibida, pagos del Seguro Social recibidos y otros ingresos.",
                yearOfCoveragePlaceholder: "Seleccionar año",
                invalidFeedback: "Please provide year of Coverage.",
            },
            "healthContributionCost": {
                heading: "Los costos de la cobertura de salud",
                label1: <EmployeeOnlyCoverageCost lang={ES} />,
                label1App: <EmployeeOnlyCoverageCostInApp lang={ES} />,
                label4: <>
                            Utilizando el plan de menor costo ofrecido por el empleador:
                        </>,
                placeholder1: "Ingresar cantidad",
                invalidFeedback1: "Por favor proporciona una cantidad.",
                label2: <FamilyCoverageCost lang={ES} />,
                label2App: <FamilyCoverageCostInApp lang={ES} />,
                placeholder2: "Ingresar cantidad",
                invalidFeedback2: "Por favor proporciona una cantidad.",
                label3: "No se ofrece o no aplica a mí",
            },
            "healthContributionFrequency": {
                heading: "Con qué frecuencia pagas",
                label1: "¿Con qué frecuencia pagarías por la cobertura?",
                label1App: "¿Con qué frecuencia se pagaría esta cantidad?",
                placeholder1: "Seleccionar con qué frecuencia",
                invalidFeedback1: "Por favor selecciona una opción",
                option1: "Semanalmente",
                option2: "Cada 2 semanas",
                option3: "Dos veces al mes",
                option4: "Mensualmente",
                option5: "Cada tres meses",
                option6: "Anualmente"
            },
            "review": {
                heading: "Revisar y confirmar",
                paragraph1: "¿Esta información parece correcta? Si no es la correcta, puedes hacer cambios a continuación.",
                item1: "Total estimado de ingresos del hogar para",
                item2: "Con qué frecuencia pagas",
                item3: "Los costos de tu cobertura de salud",
                item4: "Costo de la cobertura familiar",
                item5: "Año de cobertura",
                edit: "Cambiar",
                every2Weeks: 'Cada 2 semanas',
                weekly: 'Semanalmente',
                twiceAMonth: 'Dos veces al mes',
                monthly: 'Mensualmente',
                quarterly: 'Cada tres meses',
                yearly: 'Anualmente',
            },
            "affordabilityDetermination": {
                heading: "Resultados de asequibilidad",
                headingApp: "Resultados de asequibilidad",
                subHeading1: "Solo para el empleado",
                subHeadingApp: "Esta oferta de cobertura ",
                result1Label1: "Costo de la cobertura de salud: ",
                result1LabelApp: "Costo de cobertura: ",
                result1Suffix: " de los ingresos",
                result1Label2: "La cobertura: ",
                result1Label2App: "La cobertura es asequible ",
                subHeading2: "Para la cobertura familiar",
                subHeadingApp2: "Esta oferta de cobertura ",
                result2Label1: "Costo de la cobertura de salud: ",
                result2Suffix: " de los ingresos",
                result2Label2: "La cobertura: ",
                result2LabelApp2: "Costo de cobertura: ",
                footer: "¿Tiene tu hogar otra oferta de cobertura patrocinada por el empleador que te gustaría revisar?",
                affordable: "Es asequible",
                notAffordable: "No es asequible",
                affordableApp: "es asequible",
                notAffordableApp: "no es asequible",
                individualAffordableTooltipText: INDIVIDUAL_AFFORDABLE_TOOLTIP_TEXT_ES,
                individualNotAffordableTooltipText: INDIVIDUAL_NOT_AFFORDABLE_TOOLTIP_TEXT_ES,
                familyAffordableTooltipText: FAMILY_AFFORDABLE_TOOLTIP_TEXT_ES,
                familyNotAffordableTooltipText: FAMILY_NOT_AFFORDABLE_TOOLTIP_TEXT_ES,
                affordableHeader: AFFORDABLE_HEADER,
                affordableHeaderApp: "No califica para la ayuda financiera",
                notAffordableHeader: NOT_AFFORDABLE_HEADER,
                notaffordableHeaderApp: "Podría calificar para la ayuda financiera",
                yes:"Sí",
                no: "No"
            }
        }        
    }
}

export default config;