import deactivateLiveAgentTimeout from './deactivateLiveAgentTimeout';
import newMessageCount from '../../new-message-notification/newMessageCount';

function setInactivityTimeout (t) {
  // console.log("SET inactivity TIMEOUT");
  t.setState({highestTimeoutId: setTimeout( () => {
    newMessageCount(t, true);
    // console.log("SET modal TIMEOUT");
    t.setState({modalTimeoutId: setTimeout( () => {
      t.state.store.dispatch({
        type: 'WEB_CHAT/SEND_EVENT',
        payload: {
          name: 'closeLiveChat',
          value: {
            reason:"User did not respond within the alloted time."
        }
        }
      });
      deactivateLiveAgentTimeout(t);
    },150000)}) 
  },210000)}); //210000
}

export default setInactivityTimeout;