class Activity{
    constructor(activity){
        this.activity = activity;
    }
    hasType(){
        if(!this.activity.type){
            return false;
        }
        return true;
    }
    getType(){
        if(!this.hasType()) throw new Error("Activity has no type");
        return this.activity.type;
    }
    hasId(){
        if(!this.activity.id) return false;
        return true;
    }
    getId(){
        if(!this.hasId()) throw new Error("Activity has no id");
        return this.activity.id;
    }
    isEvent(){
        if(!this.hasType()){
            return false;
        }
        if(this.getType() !== "event"){
            return false;
        }
        return true;
    }
    hasEventName(){
        if(this.activity.name) return true;
        return false;
    }
    getEventName(){
        if(!this.isEvent()) throw new Error("Activity is not an event");
        if(!this.hasEventName()) throw new Error("Activity has no name");
        return this.activity.name;
    }
    isFromBot(){
        if(!this.activity.from) return false;
        if(this.activity.from.role !== "bot") return false;
        return true;
    }
    getWatermark(){
        if(!this.hasId()) throw new Error("Activity has no watermark")
        return parseInt(this.activity.id.split("|")[1]);
    }
    hasConversationId(){
        if(!this.activity.conversation) return false;
        if(!this.activity.conversation.id) return false;
        return true;
    }
    getConversationId(){
        if(!this.hasConversationId()) throw Error("Activity has no conversation id");
        return this.activity.conversation.id;
    }
    isNew(startWatermark){
        if(startWatermark === null) throw Error("Watermark provided was invalid");
        if(startWatermark === "") throw Error("Watermark provided was invalid");
        if(isNaN(startWatermark)) throw Error("Watermark provided was invalid")
        if(this.getWatermark() > startWatermark){
            return true;
        }
        return false;
    }
}

export default Activity;