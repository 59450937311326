import Activity from "../activity/Activity";

class Action{
    constructor(action){
        this.action = action;
        /**
         * Set activity if it exists
         */
        this.activity = this.action.payload && this.action.payload.activity ? new Activity(this.action.payload.activity) : null;
    }
    hasPayload(){
        if(this.action.payload){
            return true;
        }
        return false;
    }
    hasType(){
        if(this.action.type){
            return true;
        }
        return false;
    }
    hasActivity(){
        if(this.activity){
            return true;
        }
        return false;
    }
    isUpdateForDirectlineConnectionSuccess(){
        if(!this.hasType()){
            return false;
        }
        if(this.getType() !== "DIRECT_LINE/UPDATE_CONNECTION_STATUS"){
            return false;
        }
        if(!this.action.payload){
            return false;
        }
        if(this.action.payload.connectionStatus !== 2){
            return false;
        }
        return true;
    }    
    getType(){
        if(!this.action.type){
            throw Error("Action does not have a type");
        }
        return this.action.type;
    }
    getPayload(){
        if(!this.action.payload){
            throw new Error("Action has no payload");
        }
        return this.action.payload;
    }   
    getActivity(){
        if(!this.hasActivity()){
            throw new Error("Action has no activity");
        }
        return this.activity;
    } 
}

export default Action;