import React from "react";
import translations from "../translation";

function sb260ThankYouPage({onClose, lang}) {
  const {
    thankyouHeading,
    thankyouIntro,
    thankyouClose,
  } = translations[lang];
  return (
    <div className="position-relative h-100 d-flex flex-column">
      <h3>{thankyouHeading}</h3>
      <p className="pb-5">{thankyouIntro}</p>
      <div className='form-group mt-auto'>
          <button className="btn btn-block ac-pushButton" onClick={onClose}>{thankyouClose}</button>
      </div>
    </div>
  );
}

export default sb260ThankYouPage;