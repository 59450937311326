import React from 'react';
import PlanCard from '../../components/PlanCard/PlanCard';
import { configURLs } from './../../../Constants';
import sb260Config from '../../config';
import translations from '../../translation';

const PLAN_NAMES = {
  'aetna': 'AETNA',
  'ambetter': 'AMBETTER',
  'anthem': 'ANTHEM',
  'balance': 'BALANCE',
  'blue': 'BLUE_SHIELD',
  'health-net': 'HEALTH_NET',
  'healthnet': 'HEALTH_NET',
  'health net': 'HEALTH_NET',
  'kaiser': 'KAISER',
  'la-care': 'LA_CARE',
  'lacare': 'LA_CARE',
  'la care': 'LA_CARE',
  'molina': 'MOLINA',
  'oscar': 'OSCAR',
  'sharp': 'SHARP',
  'valley': 'VHP',
  'vhp': 'VHP',
  'western': 'WESTERN'
};

function PlanDetailsSection({ plan, data, lang }) {
    const getPlanLogoPath = (plan) => {
      let planKey = Object.keys(PLAN_NAMES).find(key => plan.toLowerCase().includes(key));
      let planPath = PLAN_NAMES[planKey] || 'DEFAULT';
      return `${configURLs.url.BASE_URL}${sb260Config.PLAN_LOGO_URLS[planPath]}`;
    }

  const planLogoPath = plan ? getPlanLogoPath(plan) : '';
  const premiumAmountMessage = data && data.monthlyPremium !== undefined
    ? (data.monthlyPremium >= 0
      ? translations[lang].premiumAmountMessage(data.monthlyPremium)
      : translations[lang].premiumAmountMessage(Math.abs(data.monthlyPremium)) + ' (Negative Value)'
    )
    : '';

  return (
    <PlanCard planLogoPath={planLogoPath}>
      <div>
        <strong>{plan}</strong>
      </div>
      <div>{premiumAmountMessage}</div>
    </PlanCard>
  );
}

export default PlanDetailsSection;
