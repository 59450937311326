import React, { useEffect } from "react";
import translations from "../EnrollmentStatusTranslations";
import { configURLs } from "../../../Constants";
import MessageBox from "../components/MessageBox";
import './EnrollmentStatus.css'


function WelcomeScreen({onNext, onClose, lang, sendLoggingEventToBackend, onSubmit}) {

    const {welcomeHeading, welcomeBody1, medicalEnrollment, welcomeBody2, welcomeButton1, exitButton} = translations[lang]
    const submit = onSubmit;
    useEffect(() => {
        let intentName = (lang === "es" ? "enrollmentStatusStartedSpanish" : "enrollmentStatusStarted")
        let text = (lang === "es" ? "Enrollment Status Started Spanish" : "Enrollment Status Started")
        sendLoggingEventToBackend({
        intentName: intentName,
        text: text
        });
    }, [sendLoggingEventToBackend]);

    const handleOnNext=() =>{
        onNext();
        console.log("going to the next page")
        //submit();       
    }


    return(
        <div className="position-relative h-100 d-flex flex-column">
           <h2 style={{marginBottom:'20px'}}>{welcomeHeading}</h2>
            <p className="pb-5 welcomeBody1">{welcomeBody1} </p>
            <div className="sub-heading">
                <h5><b className="medicalEnrollment">{medicalEnrollment}</b></h5>
                <hr style={{height:'2px', marginTop:'20px', marginBottom:'20px', border:'0', borderTop:'5px',color: '#A2AFCC'}}/>
                <div style={{ display: 'flex', alignItems: 'center', verticalAlign:'middle'}}>
                    <MessageBox messageText= {welcomeBody2}/>
                </div>
            </div>
            <div className='form-group mt-auto row'>
                <button className="btn btn-block ac-pushButton" onClick={handleOnNext}>{welcomeButton1}</button>
                <button className="btn btn-block btn-outline ac-pushButton" 
                    style= {{border:'none', background: 'none'}} 
                    type="button" 
                    onClick={onClose}
                    >{exitButton}</button>
            </div>
        </div>
            
    );
    
}

export default WelcomeScreen;