import React, { useEffect, useState, useRef } from "react";
import sendEventToDataLayer from "../datalayer-event-logger-for-transactions/sendEventToDataLayer";
import config from "./config";
import "./affordability.css";
import useConsumerName from './hooks/useConsumerName';
import useAffordabilityDetermination from "./hooks/useAffordabilityDetermination";
import useIcons from "./hooks/useIcons";
import useInteractiveHandlers from "./hooks/useInteractiveHandlers";
import useAppId from "./hooks/useAppId";

function AffordabilityDetermination(props) {
  const INDIVIDUAL = "individual";
  const lang = document.documentElement.lang;
  const { startOver, onSubmit } = props;
  const [showCloseAffordability, setShowCloseAffordability] = useState(false);
  const { AppId, SetAppId } = useAppId();
  const [showCheckAnother, setShowCheckAnother] = useState(false);
  const initialText = config.copy[lang].utilities
    ? config.copy[lang].utilities.startOver
    : "";
  const { AppConsumerName, setAppConsumerName } = useConsumerName();
  const isInApplication = process.env.REACT_APP_IN_APPLICATION === "true";
  const { currentIcon, currentIcon1, alertIcon, showMessage, setShowMessage, showMessage1, setShowMessage1 } = useIcons();
  const { handleYesClick, handleNoClick, handleGoToApplication, handleCheckAnotherClick, checkAnotherButtonRef, text, handleCheckAnotherClickForConsumer, setText, setStep } = useInteractiveHandlers(
    initialText,
    lang,
    setShowCloseAffordability,
    setShowCheckAnother,
    props.startOver,
    props.close,
    props.sendLoggingEventToBackend,
    props.setStep,
    props.clickCheckAnotherOfferInApp,
    props.localeValue
  );

  const { localeValue } = props;


  const today = new Date();
  const cutoffDate = new Date(today.getFullYear(), 10, 30);
  const showIntent = today <= cutoffDate;

  const intentName = isInApplication
    ? (props.localeValue === "en" ? "GetresultsInApp" : "GetresultsInAppSpanish")
    : showIntent
      ? props.localeValue === "en"
        ? `affordabilityToolResult${props.selectedYear}`
        : `affordabilityToolResult${props.selectedYear}Spanish`
      : props.localeValue === "en"
        ? "affordabilityToolResult2024"
        : "affordabilityToolResult2024Spanish";

  const textLogging = isInApplication
    ? (props.localeValue === "en" ? "Get Results In App" : "Get results in app Spanish")
    : (props.localeValue === "en" ? "Affordability Tool Started" : "Affordability Tool Started Spanish");

  useEffect(() => {
    // #affordability-tool-logging starts
    sendEventToDataLayer({
      event: "chatbot",
      eventDetails: {
        category: "CiCi",
        action: "Affordability Tool Clicks",
        label: "Affordability Tool Result",
      },
    });

    const loggingEvent = {
      intentName: intentName,
      text: textLogging
    };

    props.sendLoggingEventToBackend(loggingEvent);
  }, []);

  useEffect(() => {
    if (showCheckAnother) {
      if (checkAnotherButtonRef.current) {
        checkAnotherButtonRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [showCheckAnother, checkAnotherButtonRef.current]);


  const affordabilityIndividualResult = useAffordabilityDetermination(
    props.affordabilityIndividual,
    "individual",
    props.localeValue
  );

  const affordabilityFamilyResult = useAffordabilityDetermination(
    props.affordabilityFamily,
    "family",
    props.localeValue
  );

  const handleSubmit = () => {
    props.sendEventToBackend("affordabilityTool", props.calculations)
    let intentName =  props.localeValue === "en" ? "ApplyResultsInApp" : "ApplyResultsInAppSpanish"
    let text = props.localeValue === "en"? "Apply results in App" : "Apply results in App Spanish"
    props.sendLoggingEventToBackend({
      intentName: intentName,
      text: text
    })
    props.submit();
    startOver();
  }

  return (
    <div className="calculator-modal__affordability-determination">
      <div className="topHeading">
        {
          process.env.REACT_APP_IN_APPLICATION && isInApplication
            ? localeValue &&
              config.copy[localeValue].affordabilityDetermination &&
              config.copy[localeValue].affordabilityDetermination.headingApp
              ? config.copy[localeValue].affordabilityDetermination.headingApp
              : null
            : config.copy[lang].affordabilityDetermination.heading
        }


      </div>
      {(props.yesNoSelection === "yes" || props.yesNoSelection === "") && (
        <div id="employeeResult" className="calculator-modal__card">
          <div>
            <h3 className="coverage">
              {process.env.REACT_APP_IN_APPLICATION && isInApplication ? (
                // Correcting by enclosing the conditional logic properly in {}
                <>
                  {localeValue && config.copy[localeValue].affordabilityDetermination &&
                    config.copy[localeValue].affordabilityDetermination.subHeadingApp}
                  <strong>{affordabilityIndividualResult.component}</strong>
                </>
              ) : (
                config.copy[lang].affordabilityDetermination.subHeading1
              )}
            </h3>


            <div className="line"></div>

            <div className="mid">
              <div className="midBlock">
                {process.env.REACT_APP_IN_APPLICATION &&
                  isInApplication ? (
                  <>

                    {props.localeValue === "en" ? "Coverage cost: " : "Costo de cobertura: "}
                    {props.localeValue === "en" ? `${props.percentageOfHouseholdIncomeForIndividual}% of Income` : `${props.percentageOfHouseholdIncomeForIndividual}% de los ingresos`}
                  </>
                ) : (
                  <>
                    {config.copy[lang].affordabilityDetermination.result1Label1}
                    <strong>{props.percentageOfHouseholdIncomeForIndividual} %
                      {config.copy[lang].affordabilityDetermination.result1Suffix}</strong>
                  </>
                )}
              </div>
              {process.env.REACT_APP_IN_APPLICATION &&
                isInApplication ? null : (
                <>
                  <div className="midBlock">
                    {/* {localeValue &&
                      config.copy[localeValue].AffordabilityDetermination &&
                      config.copy[localeValue].AffordabilityDetermination.result1Label2 +
                      " "}
                    &nbsp;
                    <strong>{affordabilityIndividualResult.component}</strong> */}
                    {config.copy[lang].affordabilityDetermination.result1Label2}
                    &nbsp;<strong>{affordabilityIndividualResult.component}</strong>
                  </div>
                </>
              )}
              {process.env.REACT_APP_IN_APPLICATION &&
                isInApplication ?
                <div className="midBlock">
                  <strong>{affordabilityIndividualResult.headertext}</strong>
                </div> : null}
            </div>
            {process.env.REACT_APP_IN_APPLICATION &&
              isInApplication ? null : (
              <>
                <div id="whatDoYouMean1" className="downContext">
                  <button
                    className="whatDoesThisMean"
                    onClick={() => setShowMessage(!showMessage)}
                  >
                    {showMessage
                      ? props.localeValue === "en"
                        ? "Hide Explanation"
                        : "Ocultar explicación"
                      : props.localeValue === "en"
                        ? "What does this mean?"
                        : "¿Qué significa esto?"}
                  </button>
                  <img
                    className="downImage"
                    src={currentIcon.props.src}
                    alt="Arrow Icon"
                    width="9"
                    height="12"
                    onClick={() => setShowMessage(!showMessage)}
                  />
                </div>
                <div
                  id="messageDetails"
                  className="messageDetails"
                  style={{ display: showMessage ? "block" : "none" }}
                >
                  {affordabilityIndividualResult.downcontext}
                </div>
              </>
            )}
          </div>
        </div>
      )}

      {(props.yesNoSelection === "no" || props.yesNoSelection === "") && props.isFamilyCoverageApplicable && (
        <div id="affordabilityResults" className="calculator-modal__card">
          <div>
            <h3 className="coverage">
              {process.env.REACT_APP_IN_APPLICATION &&
                isInApplication
                ? (
                  <>
                    {props.localeValue === "en" ? "This coverage offer is " : "Esta oferta de cobertura "}
                    {/* { localeValue &&
                config.copy[localeValue].AffordabilityDetermination &&
                config.copy[localeValue].AffordabilityDetermination.subHeadingApp2} */}
                    <strong>{affordabilityFamilyResult.component}</strong>
                  </>
                ) : (config.copy[lang].affordabilityDetermination.subHeading2)}
            </h3>
            <div id="line" className="line"></div>

            <div className="mid">
              <div className="midBlock">
                {process.env.REACT_APP_IN_APPLICATION &&
                  isInApplication ? (
                  <>
                    {props.localeValue === "en" ? "Coverage cost: " : "Costo de cobertura: "}
                    {props.localeValue === "en" ? `${props.percentageOfHouseholdIncomeForFamily}% of Income` : `${props.percentageOfHouseholdIncomeForFamily}% de los ingresos`}

                  </>
                ) : (
                  <>
                    {/* {
                      localeValue &&
                      config.copy[localeValue].AffordabilityDetermination &&
                      config.copy[localeValue].AffordabilityDetermination.result2Label1}
                    <strong>{props.percentageOfHouseholdIncomeForFamily} %
                      {config.copy[lang].affordabilityDetermination.result2Suffix}</strong> */}
                        {config.copy[lang].affordabilityDetermination.result2Label1}
                    <strong>{props.percentageOfHouseholdIncomeForFamily} %
                      {config.copy[lang].affordabilityDetermination.result2Suffix}</strong>
                  </>
                )}
              </div>
              {process.env.REACT_APP_IN_APPLICATION &&
                isInApplication ? null : (
                <>
                  <div className="midBlock">
                    {config.copy[lang].affordabilityDetermination.result2Label2}
                    &nbsp;<strong>{affordabilityFamilyResult.component}</strong>
                  </div>
                </>
              )}
              {process.env.REACT_APP_IN_APPLICATION &&
                isInApplication ?
                <div className="midBlock">
                  <strong>{affordabilityFamilyResult.headertext}</strong>
                </div>
                : null}
            </div>
            {process.env.REACT_APP_IN_APPLICATION &&
              isInApplication ? null : (
              <>
                <div id="whatDoYouMean2" className="downContext">
                  <button
                    className="whatDoesThisMean"
                    onClick={() => setShowMessage1(!showMessage1)}
                  >
                    {showMessage1
                      ? props.localeValue === "en"
                        ? "Hide Explanation"
                        : "Ocultar explicación"
                      : props.localeValue === "en"
                        ? "What does this mean?"
                        : "¿Qué significa esto?"}
                  </button>
                  <img
                    className="downImage"
                    src={currentIcon1.props.src}
                    alt="Arrow Icon"
                    width="9"
                    height="12"
                    onClick={() => setShowMessage1(!showMessage1)}
                  />
                </div>
              </>
            )}
            <div
              id="messageDetails"
              className="messageDetails"
              style={{ display: showMessage1 ? "block" : "none" }}
            >
              {affordabilityFamilyResult.downcontext}
            </div>
          </div>
        </div>
      )}
      {process.env.REACT_APP_IN_APPLICATION ? (
        <div class="alert-box">
          <i class="icon-information"></i>
          {alertIcon}
          {props.localeValue === "en" ?
            <span className="alert-text">
              After checking{" "}
              <span className="italic-text">all coverage offers </span>for{" "}
              {AppConsumerName}, use "Apply results" to update your application.
            </span>
            :
            <span className="alert-text">
              Después de revisar{" "}
              <span className="italic-text">todas las ofertas de cobertura para </span>
              {AppConsumerName}, utiliza "Aplicar resultados" para actualizar tu solicitud.
            </span>
          }
        </div>
      ) : (
        ""
      )}

      {isInApplication ? null : (
        <div className="form-group  row">
          <div className="col-12 mb-2">
            <p className="footer">
              {config.copy[lang].affordabilityDetermination.footer}
            </p>
          </div>
          <div className="col-12 mb-2 parentButtonDiv">
            <div className="buttonsDiv">
              <button className="yesButton" onClick={handleYesClick}>
                {config.copy[lang].affordabilityDetermination.yes}
              </button>
              <button className="noButton" onClick={handleNoClick}>
                {config.copy[lang].affordabilityDetermination.no}
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="calculator-modal__buttons-container">
        {showCheckAnother && (
          <button
            ref={checkAnotherButtonRef}
            id="showCheckAnotherButton"
            onClick={handleCheckAnotherClick}
            type="button"
            className="calculator-modal__button calculator-modal__button--primary"
          >
            {text}
          </button>
        )}

        {showCloseAffordability && (
          <button
            id="closeAffordabilityButton"
            onClick={() => {
              handleGoToApplication();
            }}
            type="button"
            className="calculator-modal__button calculator-modal__button--secondary"
          >
            {props.localeValue === "en"
              ? "Close Affordability Tool"
              : "Cerrar la Herramienta de Asequibilidad"}
          </button>
        )}

        {isInApplication ? (
          <button
            type="button"
            className="calculator-modal__button calculator-modal__button--primary"
            onClick={handleSubmit}
          >
            {localeValue &&
              config.copy[localeValue].utilities &&
              config.copy[localeValue].utilities.applyResults}
          </button>
        ) : null}

        {isInApplication ? <button
          type="button"
          id="showCheckAnotherButton1"
          onClick={handleCheckAnotherClickForConsumer}
          className="calculator-modal__button calculator-modal__button--secondary"
        >
          {props.localeValue === "en" ? `Check another offer for ${AppConsumerName}` : `Revisar otra oferta para ${AppConsumerName}`}

        </button> : null}
      </div>

    </div>
  );
}

export default AffordabilityDetermination;
