class EsiOffers{
    constructor(esiOffers){
        this.esiOffers = esiOffers;
    }

    isAffordable(){
        try {
            for(let i=0; i<this.esiOffers.length; i++){
                if(this.esiOffers[i].affordabilityDetermination.toLowerCase().trim() === "affordable") return true;
                if(this.esiOffers[i].affordabilityDetermination.toLowerCase().trim() === "es asequible") return true;
            }
            return false;
        } catch (error) {
            return false;
        }
    }
}

export default EsiOffers;