import setSendBox from "botframework-webchat-core/lib/actions/setSendBox";
import removeContainer from "./removeContainer";

function bindClickEvent(button, ciciStore) {
  button.addEventListener("click", function (e) {
    e.stopPropagation();
    handleIdealQuestionClick(this.innerHTML, ciciStore);
  });
}
function handleIdealQuestionClick(buttonText, ciciStore) {
  ciciStore.dispatch({
    type: "WEB_CHAT/SEND_MESSAGE",
    payload: {
      text: buttonText,
    },
  });
  removeContainer();
  ciciStore.dispatch(setSendBox(""));
}
function displayIdealQuestions(ciciTextBox, parent, referenceNode, idealQuestions, ciciStore) {
  // create the outer container
  let autoCompleteResultsContainer = document.createElement("div");
  autoCompleteResultsContainer.setAttribute("id", "auto-complete-container");

  // insert outer container into chatbot
  parent.insertBefore(autoCompleteResultsContainer, referenceNode);

  for (var i = 0; i < idealQuestions.length && i < 4; i++) {
    // add title if query yielded idealQuestions
    if (i === 0) {
      let title = document.createElement("div");
      title.setAttribute("class", "trending-topics-title");
      // title.style.backgroundColor = "#F8F8F8";
      let titleTextNode;
      if (document.documentElement.lang === "en") {
        titleTextNode = document.createTextNode("Suggestions");
      } else {
        titleTextNode = document.createTextNode("Suggestions (spanish)");
      }
      title.appendChild(titleTextNode);

      autoCompleteResultsContainer.appendChild(title);
    }
    // create button where each of ideal questions go
    let autoCompleteResult = document.createElement("button");

    // create a text node thats equal to an ideal question
    let buttonText = document.createTextNode(idealQuestions[i]);
    autoCompleteResult.setAttribute("class", "suggested-item");
    autoCompleteResult.setAttribute("type", "button");

    // insert text node into button
    autoCompleteResult.appendChild(buttonText);

    // insert button into outer container
    autoCompleteResultsContainer.appendChild(autoCompleteResult);

    // bind a click listener to the button
    bindClickEvent(autoCompleteResult, ciciStore);
  }
}

export default displayIdealQuestions;
