import React, { Component } from 'react';
import './Cici.css';

function AffordabilityToolHeader({
    headerText,
    closeHandler
}){
    return (
        <div className="cici__header" style={{ height: '48px' }}>
            <div className="at__header-content">
                <ul className="cici__buttons hidden-xxs" style={{ display: "flex", height: "100%" }}>
                    <li className="at__name">
                        {headerText}
                    </li>
                </ul>
                <ul className="cici__buttons visible-xxs">
                    <li className="at__name visible-xxs">
                        {headerText}
                    </li>
                </ul>
            </div>
            <div className="at__button-container">
                <div onClick={closeHandler} className="at__close-icon" tabIndex="0">
                    <span className="material-icons at-close">close</span>
                </div>
            </div>
        </div>
    );
}

export default AffordabilityToolHeader;
