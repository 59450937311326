class PreviousPageLocalStorageRepository{
    constructor(storageClient){
        this.client = storageClient;
    }
    getPreviousPage(){
        return this.client.getItem("previousPage");
    }
    setPreviousPage(page){
        this.client.setItem("previousPage", page );
    }
}

export default PreviousPageLocalStorageRepository;