import { useState, useEffect } from 'react';

import useElemPositionListener from './useElemPositionListener';

const useCalcTracker = () => {
  const [isPassed, setIsPassed] = useState(false); // check if we have scrolled past calculator
  const trackedElem = document.getElementById("calculator-hq"); // unique calculator id

  // update to check that we pass n% of the calculator
  const scrollPercentage = 0.6;

  // Used by the Event Listener Hook 
  const isScrollYPastCalc = elem_scrollY => window.scrollY > (elem_scrollY * scrollPercentage); 
  
  // action performed will be passed in the the eventListener
  const actionPerformed = () => {
    if (isScrollYPastCalc((trackedElem.offsetTop + trackedElem.offsetHeight)))
      setIsPassed(prev => prev ? prev : true);
    else 
      setIsPassed(prev => prev ? false : prev);
  }

  // Custom Event Listener Hook to set up a scroll eventListener
  useElemPositionListener(trackedElem, actionPerformed);
  
  // UseEffect will run on initial render and every time isPassed changes
  useEffect(() => {
    if (trackedElem !== null) {
      let cbc = document.getElementById('talk-bubble');
      if (isPassed) {
        cbc.style.display = "block";
        cbc.setAttribute('aria-hidden', false);
      } else {
        cbc.style.display = "none";
        cbc.setAttribute('aria-hidden', true);      
      }
    } 
  }, [isPassed]);

}

export default useCalcTracker;