import React from 'react';
import './PlanCard.css';
import './PayYourBill.css'

function PlanCard ({planLogoPath, children}) {
    return (
        <div className="plan-card">
            <div className='plan-card__icon'>
                <img src={`${planLogoPath}`}/>
            </div>
            <div className='plan-card__content flex__important'>
                {children}
            </div>
        </div>
    )
}

export default PlanCard;