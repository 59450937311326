import React, { useState, useEffect } from "react";
import translations from "../EnrollmentStatusTranslations";

function EnrollmentYear(props) {
  const lang = document.documentElement.lang;
  const [selectedOption, setSelectedOption] = useState("");
  const [isValid, setIsValid] = useState(true);

  const {
    yearOfEnrollmentExit,
    yearOfEnrollmentContinue,
    yearOfCoverageSubHeading,
    yearOfCoverageOption,
    yearOfCoverageHeading,
  } = translations[lang];

  useEffect(() => {
    if (props.selectedYear !== undefined) {
      setSelectedOption(props.selectedYear.toString());
    }
  }, [props.selectedYear]);

  const handleDropdownChange = (event) => {
    const value = event.target.value;
    setSelectedOption(value);
    props.setSelectedYear(Number(value));
    setIsValid(_isValid(value));
  };

  const handleYearSelection = (year) => {
    props.setSelectedYear(year);
  };
  const _isValid = (value) => {
    if (isNaN(value) || value === "") {
      return false;
    }
    return true;
  };

  // const handleContinueClick = () => {
  //   props.checkPlansAndNavigate(selectedOption);
  // };
  useEffect(() => {
    setSelectedOption(props.yearOfCoverage);
  }, [props.yearOfCoverage]);

  const incomeInputClasses = isValid
    ? "form-control"
    : "form-control is-invalid";
  const nextInputClasses = _isValid(selectedOption)
    ? "btn btn-block ac-pushButton style-default"
    : "btn btn-block ac-pushButton style-default btn-disabled";
  const nextButtonDisabled =
    !_isValid(selectedOption) || selectedOption === "2023";

  useEffect(() => {
    setSelectedOption(props.yearOfCoverage);
    console.log(selectedOption)
    let intentName = (lang === "es" ? "enrollmentStatusCoverageYear"+selectedOption+"Spanish" : "enrollmentStatusCoverageYear"+selectedOption);
    let text = (lang==="es" ? "Enrollment Status Coverage Year "+selectedOption+" Spanish" : "Enrollment Status Coverage Year" + selectedOption);
    props.sendLoggingEventToBackend({
      intentName: intentName,
      text: text      
    })
  }, [props.yearOfCoverage]);

  const handleClose = () => {
    if (props.onClose) {
      props.onClose(); // Call the onClose function passed from the parent
    }
  };
  return (
    <div className="h-100 d-flex flex-column">
      <h2>{yearOfCoverageHeading}</h2>
      <div className="form-group">
        <label htmlFor="income" style={{ width: "100%" }}>
          {yearOfCoverageSubHeading}
        </label>
        <select
          id="yearOfCoverage"
          value={selectedOption}
          onChange={handleDropdownChange}
          className={`form-control mt-2 ${incomeInputClasses}`}
        >
          <option value="" disabled hidden>
            {yearOfCoverageOption}
          </option>
          <option value="2023" selected={selectedOption === "2023"}>
            2023
          </option>
          <option value="2024">2024</option>
        </select>
      </div>
      <div className="form-group mt-auto">
        <button
          className="btn btn-block ac-pushButton"
          type="button"
          onClick={() => props.onNext()} 
          disabled={nextButtonDisabled}
        >
          {yearOfEnrollmentContinue}
        </button>
        <button className="btn btn-block btn-outline ac-pushButton" onClick={handleClose}>
          {yearOfEnrollmentExit}
        </button>
      </div>
    </div>
  );
}

export default EnrollmentYear;
