const config = {
    EVENT_NAME: "SB260_TRANSACTION",
    PAGES: {
        AUTHENTICATION_PAGE: "AUTHENTICATION_FORM",
        NO_ACCOUNT_PAGE: "OPTIONS_FOR_NO_ACCOUNT",
        CONSENT_PAGE: "CONSENT_PAGE",
        KEEP_PLAN_DETAILS_PAGE: "KEEP_PLAN_DETAILS_PAGE", 
        OPT_IN_PAGE: "OPT_IN_PAGE",
        OPT_IN_RESULT_PAGE: "OPT_IN_RESULT_PAGE",
        CANCEL_PLAN_DETAILS_PAGE: "CANCEL_PLAN_DETAILS_PAGE",
        CANCEL_RESULT_PAGE: "CANCEL_RESULT_PAGE"
    },
    ACTIVITY_NAMES: {
        START_SB260_TRANSACTION:"START_SB260_TRANSACTION",
        AUTHENTICATION_SUCCESS:"AUTHENTICATION_SUCCESS",
        AUTHENTICATION_FAILED_USER_NOT_FOUND:"AUTHENTICATION_FAILED_USER_NOT_FOUND",
        AUTHENTICATION_FAILED_SERVICE_ERROR:"AUTHENTICATION_FAILED_SERVICE_ERROR",
        UPDATE_CONSENT_SUCCESS:"UPDATE_CONSENT_SUCCESS",
        UPDATE_CONSENT_FAILED_USER_NOT_FOUND:"UPDATE_CONSENT_FAILED_USER_NOT_FOUND",
        UPDATE_CONSENT_FAILED_SERVICE_ERROR:"UPDATE_CONSENT_FAILED_SERVICE_ERROR",
        UPDATE_OPT_STATUS_SUCCESS_ZERO_PREMIUM:"UPDATE_OPT_STATUS_SUCCESS_ZERO_PREMIUM",
        UPDATE_OPT_STATUS_SUCCESS_NON_ZERO_PREMIUM:"UPDATE_OPT_STATUS_SUCCESS_NON_ZERO_PREMIUM",
        UPDATE_OPT_STATUS_FAILED_USER_NOT_FOUND:"UPDATE_OPT_STATUS_FAILED_USER_NOT_FOUND",
        UPDATE_OPT_STATUS_FAILED_SERVICE_ERROR:"UPDATE_OPT_STATUS_FAILED_SERVICE_ERROR",
    },
    POPUP_DETAILS_En: {
        POPUP_TITLE: "Need Help?",
        POPUP_CONTENT: "There are several ways to get support. Click \"Contact Support\" below to see your options.\nRemember, help is always free and confidential.",
        POPUP_CALLBACK: "Contact Support"
    },
    POPUP_DETAILS_Es: {
        POPUP_TITLE: "¿Necesitas ayuda?",
        POPUP_CONTENT: "Hay varias maneras para obtener apoyo. Haz clic en \"Comunícate con el equipo de apoyo” \ a continuación para ver tus opciones. \n Recuerda, la ayuda siempre es gratuita y confidencial.",
        POPUP_CALLBACK: "Comunícate con el equipo de apoyo "
    },
    PLAN_LOGO_URLS: {
        AETNA: "/images/botImages/plans/Aetna-CVS.svg",
        AMBETTER: "/images/botImages/plans/ambetter.svg",
        ANTHEM: "/images/botImages/plans/anthem.svg",
        BALANCE: "/images/botImages/plans/balance-cchp.svg",
        BLUE_SHIELD: "/images/botImages/plans/blue.svg",
        HEALTH_NET: "/images/botImages/plans/health-net.svg",
        KAISER: "/images/botImages/plans/kaiser.svg",
        LA_CARE: "/images/botImages/plans/la-care.svg",
        MOLINA: "/images/botImages/plans/molina.svg",
        OSCAR: "/images/botImages/plans/oscar.svg",
        SHARP: "/images/botImages/plans/Sharp-health-plan.svg",
        VHP: "/images/botImages/plans/VHP.svg",
        WESTERN: "/images/botImages/plans/western-health.svg",
        DEFAULT: "/images/botImages/plans/checkmark.svg"
    }
}

export default config;