import React from 'react';
import {connectToWebChat} from 'botframework-webchat';
import LiveChatCard from './live-agent/live-chat-card/liveChatCard'


const ConnectedAttachmentWithLiveChat = connectToWebChat(
  ({ postActivity }) => ({ postActivity })
)(props => 
<LiveChatCard {...props} />
)

export default ConnectedAttachmentWithLiveChat;