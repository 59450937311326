import React from "react";
import activityToDataLayerEventMapping from './activityToDataLayerEventMapping.json';
class ActivityWithDataLayerLogging extends React.Component {

  constructor(props){
    super(props);
    this.props = props;
  }

  componentDidMount = () => {
    this.sendToDataLayer(this.props.children.props.activity.value.event);
  };

  sendToDataLayer = (activityEvent) => {
    if(this.doesDataLayerEventExistForActivity(activityEvent)){
      window.dataLayer.push({event: 'dotComClick', eventDetails: {...activityToDataLayerEventMapping[activityEvent]}});
    }
  }

  doesDataLayerEventExistForActivity(activityEvent){
    if(activityToDataLayerEventMapping[activityEvent] && activityToDataLayerEventMapping[activityEvent] !== {}){
      return true;
    }
    return false;
  }

  render() {
    return (
      <React.Fragment>
        {this.props.children}
      </React.Fragment>
    );
  }
}

export default ActivityWithDataLayerLogging;
