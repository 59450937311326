import React from "react";
import "./Activity.css";
import FeedbackButtons from "./FeedbackButtons";
import SuggestedActions from "./suggestedActions";
import updateAvatar from "./live-agent/avatar-manager/avatarManager";

class ActivityWithFeedback extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      feedbackGivenPositive: false,
      feedbackGivenNegative: false,
    };
  }

  componentDidMount = () => {
    // check for all activities not from user, and required props are present
    if (this.props.from === "user" && this.props.activityID === undefined) {
      this.props.context.clearInactivityTimeout();
      if (localStorage.hasOwnProperty("liveChatSession")) {
        localStorage.setItem("timeoutForSessionStorageReset", new Date().getTime());
      }
    }
    if (this.props.from !== "user") {
      if (this.props.children && typeof this.props.children.props !== "undefined") {
        if (typeof this.props.children.props.activity !== "undefined") {
          // clears inactivity timer if set
          this.props.context.clearInactivityTimeout();
          // if message is from live agent, start inactivity timer
          if (
            this.props.children.props.activity.hasOwnProperty("entities") &&
            this.props.children.props.activity.entities[0].type === "agentMessage"
          ) {
            this.props.context.setInactivityTimeout();
            this.props.context.deactivateLiveAgentTimeout();
            localStorage.setItem("timeoutForSessionStorageReset", new Date().getTime());
          }

          // if(this.props.children.props.activity.hasOwnProperty("entities") && this.props.children.props.activity.entities[0].type === "liveChatRequest")
          // {
          //   this.props.context.showPrivacyDisclaimer();
          // }

          if (
            this.props.children.props.activity.hasOwnProperty("entities") &&
            this.props.children.props.activity.entities[0].type === "initiatedLiveChat"
          ) {
            localStorage.setItem("liveChatSession", true);
            this.props.context.showEndChatButton();
            localStorage.setItem("timeoutForSessionStorageReset", new Date().getTime());
          }

          if (
            this.props.children.props.activity.hasOwnProperty("entities") &&
            this.props.children.props.activity.entities[0].type === "endLiveChat"
          ) {
            localStorage.removeItem("liveChatSession");
            //Newly added
            localStorage.setItem("isSessionStorageSet", true);
            this.props.context.hideEndChatButton();
            this.props.context.deactivateLiveAgentTimeout();
            localStorage.setItem("timeoutForSessionStorageReset", new Date().getTime());
          }

          // increments new message notification count as new messages come in from bot
          // using type typing because refreshing page counts all previous type messages
          // if (this.props.children.props.activity.type === 'message'){
          if (this.props.children.props.activity.type === "typing") {
            this.props.context.handleNewMessageNotification();

            // Code to detect did you mean counts and prompt for feedback on 3rd didYouMean
            // if(this.props.children.props.activity.hasOwnProperty("entities") && (this.props.children.props.activity.entities[0].type === "didYouMean" || this.props.children.props.activity.entities[0].type === "None"))
            // {
            //   var didYouMeanCount = localStorage.getItem('didYouMeanCount') || ""
            //   localStorage.setItem('didYouMeanCount', didYouMeanCount+" ")

            //   if(localStorage.getItem('didYouMeanCount') !== null && localStorage.getItem('didYouMeanCount').length === 3)
            //   {
            //     let props = this.props;

            //     setTimeout(function(){
            //       props.postActivity({
            //         type: "event",
            //         value: document.documentElement.lang,
            //         from: { id: "me" },
            //         name: "provideFeedback"
            //       })

            //       var didYouMeanCount = localStorage.getItem('didYouMeanCount') || ""
            //       localStorage.setItem('didYouMeanCount', didYouMeanCount+" ")

            //     }, 5000)

            //   }

            // }
          }
        }
      }

      // update avatars if necessary
      updateAvatar();
    }

    //For fading feedback-container
    let currentContainerIndex = document.getElementsByClassName("feedback-container").length;
    let currentContainer = document.getElementsByClassName("feedback-container")[currentContainerIndex - 1];
    if (!currentContainer) {
      return;
    }
    currentContainer.addEventListener("animationend", () => {
      currentContainer.classList.remove("animateOpacity");
    });
    currentContainer.addEventListener("animationcancel", () => {
      currentContainer.classList.remove("animateOpacity");
    });
    currentContainer.classList.add("animateOpacity");
  };

  handleDownvoteButton = () => {
    if (!this.state.feedbackGivenNegative) {
      var tmpThumbsDownCount = parseInt(sessionStorage.getItem("thumbsDownCount"), 10);
      tmpThumbsDownCount++;
      sessionStorage.setItem("thumbsDownCount", tmpThumbsDownCount);

      this.props.postActivity({
        type: "event",
        name: "messageNotHelpful",
        value: {
          conversationID: this.props.children.props.activity.conversation.id,
          activityID: this.props.activityID,
          messageText: this.props.children.props.activity.text,
          helpful: -1,
          timestamp: this.props.children.props.activity.timestamp,
          intentName:
            this.props.children.props.activity.entities[0].type === "welcomeMessageARP"
              ? "welcomeMessage"
              : this.props.children.props.activity.entities[0].type,
          thumbsDownCount: tmpThumbsDownCount,
        },
      });
    }
    if (this.state.feedbackGivenPositive) {
      this.setState({ feedbackGivenPositive: false });
    }
    this.setState({ feedbackGivenNegative: !this.state.feedbackGivenNegative });
  };

  handleUpvoteButton = () => {
    if (!this.state.feedbackGivenPositive) {
      this.props.postActivity({
        type: "event",
        name: "messageHelpful",
        value: {
          conversationID: this.props.children.props.activity.conversation.id,
          activityID: this.props.activityID,
          messageText: this.props.children.props.activity.text,
          helpful: 1,
          timestamp: this.props.children.props.activity.timestamp,
          intentName:
            this.props.children.props.activity.entities[0].type === "welcomeMessageARP"
              ? "welcomeMessage"
              : this.props.children.props.activity.entities[0].type,
        },
      });
    }
    if (this.state.feedbackGivenNegative) {
      this.setState({ feedbackGivenNegative: false });
    }
    this.setState({ feedbackGivenPositive: !this.state.feedbackGivenPositive });
  };

  sendEndChatNotification = (properties) => {};

  sendClicksToDataLayer(event) {
    if (event.target.href && event.currentTarget.dataset.intentname) {
      // Bubble clicks
      console.log(event.currentTarget.dataset.intentname)
      window.dataLayer.push({
        event: "dotComClick",
        eventDetails: {
          category: "CiCi",
          action: event.currentTarget.dataset.intentname + " Intent Link Clicks",
          label: event.target.innerHTML.replace(/"/g, "") + ":" + event.target.href,
        },
      });
    } else if (event.currentTarget.dataset.intentname) {
      // Did you mean clicks
      window.dataLayer.push({
        event: "dotComClick",
        eventDetails: {
          category: "CiCi",
          action: event.currentTarget.dataset.intentname + " Card Clicks",
          label: event.target.innerText,
        },
      });
    }
  }

  render() {
    //console.log("111111111111111111111111111111111111",this.props.children)
    const bubbleLeftArrowStyle = {
      content: "",
      position: "absolute",
      top: "12px",
      left: "30px",
      width: "0px",
      borderRight: "5px solid #ffffff",
      borderBottom: "5px solid transparent",
      borderTop: "5px solid transparent",
      marginLeft: "20px",
      boxShadow: "#888888",
      //box-shadow: "0 2px 2px #888888"
    };
    const bubbleRightArrowStyle = {
      content: "",
      position: "absolute",
      top: "12px",
      right: "25px",
      width: "0px",
      borderLeft: "5px solid #2e3fbd",
      borderBottom: "5px solid transparent",
      borderTop: "5px solid transparent",
      marginLeft: "20px",
    };

    // the expression determines whether the tail gets displayed in webchat
    const tail = this.props.children ? (
      <span className="tail" style={this.props.from === "bot" ? bubbleLeftArrowStyle : bubbleRightArrowStyle}></span>
    ) : (
      ""
    );

    //if activity is from user, returns message with NO feedback buttons
    if (this.props.from === "user") {
      //{this.props.children.props.activity && this.props.children.props.activity.value && this.props.children.props.activity.value.intentName ?  this.props.children.props.activity.value.intentName: ""}
      return (
        <div className="some-div "data-intentname = "some-div" >
          <div className="activity" >
            {tail}
            {this.props.children}
          </div>
        </div>
      );
    }

    // if activity type is typing, returns message with NO feedback buttons
    if (this.props.children && typeof this.props.children.props !== "undefined") {
      if (typeof this.props.children.props.activity !== "undefined") {
        if (this.props.children.props.activity.type === "typing") {
          return (
            <div className="some-div" data-intentname = {this.props.children.props.activity && this.props.children.props.activity.value && this.props.children.props.activity.value.intentName ?  this.props.children.props.activity.value.intentName: ""}>
              <div className="activity">
                {tail}
                {this.props.children}
              </div>
            </div>
          );
        }
      }
    }

    // if activity contains suggested actions, returns message with NO feedback buttons
    if (this.props.children && typeof this.props.children.props !== "undefined") {
      if (typeof this.props.children.props.activity !== "undefined") {
        if (typeof this.props.children.props.activity.suggestedActions !== "undefined") {
          return (
            <div className="some-div" data-intentname = {this.props.children.props.activity && this.props.children.props.activity.value && this.props.children.props.activity.value.intentName ?  this.props.children.props.activity.value.intentName: ""}>
              <div className="activity">
                {tail}
                {this.props.children}
                <SuggestedActions activity={this.props.children.props.activity} props={this.props} />
              </div>
            </div>
          );
        }
      }
    }

    // setup data intent name for incoming activities
    const childrenProps = this.props.children ? this.props.children.props : null;
    let dataIntentName = null;
    if (childrenProps) {
      if (this.props.children.props.activity.entities && this.props.children.props.activity.entities[0]) {
        dataIntentName = this.props.children.props.activity.entities[0].type;
      }
    }

    // if activity is from bot, returns message WITH feedback buttons
    return (
      <div className="some-div">

        <div className="activity">
          {tail}
          <div className="message-container">
            <div
              className="content-container"
              data-intentname={dataIntentName}
              onClick={(event) => {
                this.sendClicksToDataLayer(event);
              }}
            >
              {this.props.children}
            </div>
            <div className="feedback-container">
              <FeedbackButtons
                activity={this.props.children ? this.props.children.props : null}
                handleUpvoteButton={this.handleUpvoteButton}
                handleDownvoteButton={this.handleDownvoteButton}
                positive={this.state.feedbackGivenPositive}
                negative={this.state.feedbackGivenNegative}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ActivityWithFeedback;
