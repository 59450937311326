import IdealQuestionsLoaded from "./IdealQuestionsLoaded";
import loadIdealQuestions from "./loadIdealQuestions";
import loadTrendingTopics from "./loadTrendingTopics";
import displayIdealQuestions from "./displayIdealQuestions";
import testDynamicStyling from "./testDynamicStyling";
import { removeTrendingTopicsContainer } from "../trending-topics/trendingTopics";
import AutoCompleteSuggestions from "./AutoCompleteSuggestions";
import ReactDOM from "react-dom";
import React from "react";

function AutoComplete() {
  // UI Elements
  let ciciTextBox = document.querySelector('input[data-id="webchat-sendbox-input"');
  let ciciSendBoxForm = ciciTextBox.parentElement;
  let parent = ciciTextBox.parentElement.parentElement.parentElement;
  let referenceNode = parent.querySelector(".main");
  let ciciSendButton = referenceNode.getElementsByTagName("button")[0];
  let ciciRoot = document.getElementById("chatbot-root");
  const autoCompleteRootNodeId = "auto-complete-root";
  const MIN_INPUT_LENGTH_FOR_AUTO_COMPLETE = 4;

  return {
    setupAutoComplete: function (idealQuestions, ciciStore, lang) {
      bindEvents(idealQuestions, ciciStore, lang);
      addRootNodeToDom(parent, referenceNode, autoCompleteRootNodeId);
    },
    testDynamicStyling: function () {
      return testDynamicStyling();
    },
    displayIdealQuestions: function (idealQuestions) {
      return displayIdealQuestions(ciciTextBox, parent, referenceNode, idealQuestions);
    },
    loadTrendingTopics: function (lang) {
      return loadTrendingTopics(lang);
    },
    loadIdealQuestions: function (lang) {
      return loadIdealQuestions(lang);
    },
    idealQuestionsLoaded: function (idealQuestions) {
      return IdealQuestionsLoaded(idealQuestions);
    },
  };

  function bindEvents(idealQuestions, ciciStore, lang) {
    ciciTextBox.addEventListener("input", async function () {
      const userInput = this.value;
      removeTrendingTopicsContainer();
      handleInput(userInput, ciciStore, lang, idealQuestions);
    });
    ciciSendBoxForm.addEventListener("submit", function () {
      renderAutoCompleteComponent(autoCompleteRootNodeId, "", idealQuestions, ciciStore, lang);
    });
    ciciSendButton.addEventListener("click", function () {
      renderAutoCompleteComponent(autoCompleteRootNodeId, "", idealQuestions, ciciStore, lang);
    });
    ciciRoot.addEventListener("ciciMinimize", function () {
      renderAutoCompleteComponent(autoCompleteRootNodeId, "", idealQuestions, ciciStore, lang);
    });
  }

  function addRootNodeToDom(parent, referenceNode, rootNodeId) {
    let autoCompleteResultsContainer = document.createElement("div");
    autoCompleteResultsContainer.setAttribute("id", rootNodeId);
    parent.insertBefore(autoCompleteResultsContainer, referenceNode);
  }

  function renderAutoCompleteComponent(autoCompleteRootNodeId, userInput, idealQuestions, ciciStore, lang) {
    ReactDOM.render(
      <AutoCompleteSuggestions
        idealQuestions={idealQuestions}
        userInput={userInput}
        ciciStore={ciciStore}
        lang={lang}
      />,
      document.getElementById(autoCompleteRootNodeId)
    );
  }

  function isRootNodeInDom(rootNodeIdSelector) {
    if (document.getElementById(rootNodeIdSelector)) {
      return true;
    }
    return false;
  }

  function isUserInputValidForAutoComplete(userInput) {
    if (!userInput) {
      return false;
    }
    if (userInput === "") {
      return false;
    }
    if (userInput.length < MIN_INPUT_LENGTH_FOR_AUTO_COMPLETE) {
      return false;
    }
    return true;
  }

  async function handleInput(userInput, ciciStore, lang, idealQuestions) {
    if (isUserInputValidForAutoComplete(userInput) && isRootNodeInDom(autoCompleteRootNodeId)) {
      renderAutoCompleteComponent(autoCompleteRootNodeId, userInput, idealQuestions, ciciStore, lang);
    } else {
      renderAutoCompleteComponent(autoCompleteRootNodeId, "", idealQuestions, ciciStore, lang);
    }
  }
}

export default AutoComplete;
