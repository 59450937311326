import React from "react";
import { configURLs } from "../../../Constants";
import enrollmentStausConfig from "../enrollmentStatusConfig";
import "./enrollmentDetails.css";
import translations from "../EnrollmentStatusTranslations";
import { useEffect } from "react";
import { useState } from "react";

function EnrollmentDetails({ data, lang }) {
  const PLAN_NAMES = {
    aetna: "AETNA",
    ambetter: "AMBETTER",
    anthem: "ANTHEM",
    balance: "BALANCE",
    blue: "BLUE_SHIELD",
    "health-net": "HEALTH_NET",
    healthnet: "HEALTH_NET",
    "health net": "HEALTH_NET",
    kaiser: "KAISER",
    "la-care": "LA_CARE",
    lacare: "LA_CARE",
    "la care": "LA_CARE",
    molina: "MOLINA",
    oscar: "OSCAR",
    sharp: "SHARP",
    valley: "VHP",
    vhp: "VHP",
    western: "WESTERN",
    delta: "DELTA",
  };
  const { amountYouPay, Savings, PremiumSavings,PremiumSavings1, perMonth} = translations[lang]
  const getPlanLogoPath = (carrierName) => {
    const normalizedCarrierName = carrierName
      .toLowerCase()
      .replace(/\s+/g, "-");
    let planKey = Object.keys(PLAN_NAMES).find((key) =>
      normalizedCarrierName.includes(key)
    );
    let planPath = planKey ? PLAN_NAMES[planKey] : "DEFAULT";
    return `${configURLs.url.BASE_URL}${enrollmentStausConfig.PLAN_LOGO_URLS[planPath]}`;
  };
  const [isMobileView, setIsMobileView] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth >= 250 && window.innerWidth <= 600);
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Initial check on component mount
    return () => window.removeEventListener("resize", handleResize);
  }, []);


  const planLogoPath = data.carrierName
    ? getPlanLogoPath(data.carrierName)
    : "";

  return (
    <div className="enrollment-details">
      {/* <div className="plan-logo-container">
      <img src={planLogoPath} alt="Plan Logo" className="plan-logo" />
    </div> */}
      <div className="plan-details">
        <div className="plan-name">
          {data.carrierName} {data.planName}
        </div>
        <div className="premium-info">
          {data.planType === "Health" && (
            <>
              <div className="premium-detail">
                <span className="premium-label">
                {isMobileView ? PremiumSavings1 : PremiumSavings} 
                  </span>
                <span className="premium-value">
                  ${Number(data.grossPremiumAmt).toFixed(2)}{perMonth}
                </span>
              </div>
              <div className="premium-detail">
                <span className="premium-label">{Savings}</span>
                <span className="premium-value">
                  -${Number(data.savings).toFixed(2)}{perMonth}
                </span>
              </div>
            </>
          )}
          <div className="premium-detail">
            <span className="premium-label font-bold ">{amountYouPay}</span>
            <span className="premium-value font-bold">
              ${Number(data.netPremiumAmount).toFixed(2)}{perMonth}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EnrollmentDetails;
