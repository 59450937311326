import React, { useState } from 'react';
import { configURLs
 } from '../../Constants';
function useIcons() {
  const [showMessage, setShowMessage] = useState(false);
  const [showMessage1, setShowMessage1] = useState(false);

  const downArrowIcon = (
    <img
      className="downImage"
      src={configURLs.url.BASE_URL + "/images/botImages/arrow.svg"}
      alt="Down Arrow Icon"
      width="9"
      height="12"
    />
  );

  const upArrowIcon = (
    <img
      className="upImage"
      src={configURLs.url.BASE_URL + "/images/botImages/UpArrow.svg"}
      alt="Up Arrow Icon"
      width="9"
      height="12"
    />
  );

  const alertIcon = (
    <img
      className="alert-icon"
      src={configURLs.url.BASE_URL + "/images/botImages/grey-alert-icon.svg"}
    />
  );

  const currentIcon = showMessage ? upArrowIcon : downArrowIcon;
  const currentIcon1 = showMessage1 ? upArrowIcon : downArrowIcon;

  return { currentIcon, currentIcon1, alertIcon, showMessage, setShowMessage, showMessage1, setShowMessage1 };
}

export default useIcons;
