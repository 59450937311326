import React from "react";
import "./planCardEs.css";
import translations from "../EnrollmentStatusTranslations";
import EnrollmentDetails from "./enrollmentDetails";
import { useEffect } from "react";
import { useState } from "react";

function formatDate(dateString) {
  const options = { year: "numeric", month: "2-digit", day: "2-digit" };
  const date = new Date(dateString);
  return date.toLocaleDateString("en-US", options);
}
function PlanCard({
  lang,
  planType,
  totalPlans,
  healthHouseholdMembers,
  dentalHouseholdMembers,
  enrollmentStatus,
  enrollmentStartDate,
  enrollmentEndDate,
  plans,
  index,
}) {
  const {
    HealthPlans,
    dentalPlans,
    houseHoldMembers,
    houseHoldMembers1,
    enrollmentStatusHeading,
    enrollmentStatusHeading1,
  } = translations[lang];
  const formattedStartDate = formatDate(enrollmentStartDate);
  const formattedEndDate = formatDate(enrollmentEndDate);
  const [isMobileView, setIsMobileView] = useState(false);

  console.log("window.innerWidth", window.innerWidth )

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth >= 250 && window.innerWidth <= 600);
    };

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  function formatStatus(status, lang) {
    if (lang === "es") {
      if (status.toUpperCase() === "CONFIRM") {
        return "Inscrito";
      } else if (status.toUpperCase() === "PENDING") {
        return "Pendiente";
      } else if (status.toUpperCase() === "CANCEL") {
        return "Cancelado";
      } else if (status.toUpperCase() === "TERM") {
        return "Terminado";
      }
    } else if (lang === "en") {
      if (status.toUpperCase() === "CONFIRM") {
        return "Enrolled";
      } else if (status.toUpperCase() === "CANCEL") {
        return "Cancelled";
      } else if (status.toUpperCase() === "TERM") {
        return "Terminated";
      }
    }
    return status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
  }

  const displayStatus = formatStatus(enrollmentStatus, lang);
  // console.log("Props in PlanCard:", {
  //   lang,
  //   planType,
  //   totalPlans,
  //   enrollmentStatus,
  //   enrollmentStartDate,
  //   enrollmentEndDate,
  //   plans,
  //   healthHouseholdMembers,
  //   dentalHouseholdMembers,
  // });

  return (
    <div
      className="plan-cardEs"
      style={{ display: "flex", padding: "0px", margin: "unset", paddingTop:"8px" }}
    >
      <div className="plan-card__contentEs">
        <div className="plan-card__contentEs__planDetails">
          <p className="group" style={{ lineHeight: "0.2", margin: "unset" }}>
          {planType === "Health" && totalPlans >= 1
    ? lang === "en" 
        ? `Group ${index + 1}` 
        : lang === "es" 
            ? `Grupo ${index + 1}` 
            : null
    : planType === "Health"
        ? HealthPlans
        : dentalPlans}

          </p>

          <p style={{ lineHeight: "0.2" }}>
            <b className="houseHold">
              {isMobileView ? houseHoldMembers1 : houseHoldMembers}{" "}
              {planType === "Health"
                ? healthHouseholdMembers
                : dentalHouseholdMembers}
            </b>
          </p>
        </div>
        <div
          className="plan-card__contentEs__premiumAmount"
          style={{
            display: "flex",
            alignItems: "flex-end",
            flexDirection: "column",
          }}
        >
          {" "}
          <p className="status" style={{ lineHeight: "0.2", margin: "unset" }}>
            {isMobileView ? enrollmentStatusHeading1 : enrollmentStatusHeading}{" "}
            {displayStatus}
          </p>
          <p className="align-right" style={{ lineHeight: "0.2" }}>
            <b className="houseHold">
              {formattedStartDate} - {formattedEndDate}
            </b>
          </p>
        </div>
      </div>
    </div>
  );
}

export default PlanCard;
