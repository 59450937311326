import React from "react";
import "./MessageBox.css";
import { configURLs } from "../../../Constants"


function MessageBox({messageText}){
    return (
        // <div className="message-box">
        //     <div className="message-content">
        //         <div className="message-icon">
        //             <img src= {configURLs.url.BASE_URL + "images/botImages/errorEclipse.svg"} style={{marginRight:'30px'}}></img>
        //         </div>
                <div className="message-text">
                    {messageText}
                </div>
        //     </div>
        // </div>
    )
}

export default MessageBox