class ConsoleLogger{
    log(name, value){
        if(process.env.NODE_ENV === 'development'){
            console.log("***");
            console.log("*** " + name);
            console.log("***");
            console.log(value || value === 0 || value === false ? value : "");
            console.log("\n");
        }
    }
}

export default ConsoleLogger;