import React, { Component } from 'react';
import './Cici.css';

class CiciHeaderLoading extends Component {

  render() {
    const supportText = 'Support';
    const supportSpanishText = 'Apoyo';
    //const headerImg = 'https://www.coveredca.com/images/botImages/ribbon-help-bubble.png';

    let headerLanguage = {};

    if (this.props.lang === 'es') {
      headerLanguage = {
        support: supportSpanishText
      }
    } else {
      headerLanguage = {
        support: supportText
      }
    }

    return (
      <div className="cici__header" style={{ height: '55px' }}>
        <div className="cici__header-content">
          <div className="cici__name">
            <b>{headerLanguage.support}</b>
          </div>
        </div>
        <div className="button-container">
          <div onKeyPress={(e) => { this.props.handleKeyPress(e, this.props.handleMinimize) }} className="close-button" onClick={this.props.handleMinimize} tabIndex="0"><span className="material-icons">arrow_drop_down</span></div>
        </div>
      </div>
    )

  }
}

export default CiciHeaderLoading;























            