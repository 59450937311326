function loadTrendingTopics(lang) {
  let trendingTopicsArray;
  if (lang === "en") {
    trendingTopicsArray = [
      "Login Help",
      "Medi-Cal Information",
      "Application Help",
      "Financial Help",
    ];
  } else {
    trendingTopicsArray = [
      "Ayuda para iniciar una sesión",
      "Información sobre Medi-Cal",
      "Ayuda con la aplicación",
      "Información sobre la Ayuda financiera",
    ];
  }
  return trendingTopicsArray;
}

export default loadTrendingTopics;
