import React, { useState, useEffect } from 'react';
import config from './config';

function YearOfCoverage(props) {
    const lang = document.documentElement.lang; 
    const [selectedOption, setSelectedOption] = useState("");  
    const [isValid, setIsValid] = useState(true);
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const comparisonYear = currentDate.getMonth() === 0 ? currentYear - 1 : currentYear; 
    const isAfterNov30 = currentDate > new Date(comparisonYear, 10, 30);
    
    const handleDropdownChange = (event) => {
        const value = event.target.value;
        setSelectedOption(value);
        props.setSelectedYear(Number(value));
        setIsValid(_isValid(value));  
    };

    const _isValid = (value) => {
        if (isNaN(value) || value === "") {
            return false;
        }
        return true;
    }

    useEffect(() => {
        setSelectedOption(props.yearOfCoverage);
    }, [props.yearOfCoverage]);

    const incomeInputClasses = isValid ? "form-control" : 'form-control is-invalid';
    const nextInputClasses = _isValid(selectedOption) || isAfterNov30 ? "btn btn-block ac-pushButton style-default" : "btn btn-block ac-pushButton style-default btn-disabled";
    const nextButtonDisabled = isAfterNov30 ? false : !_isValid(selectedOption) || (selectedOption === "2023" && !isAfterNov30);

    useEffect(() => {
        setSelectedOption(props.yearOfCoverage || (isAfterNov30 ? "2024" : ""));
    }, [props.yearOfCoverage, isAfterNov30]);

   
    return (
        <>
            <div className='calculator-modal__screen-header'>
                <h2>{config.copy[lang].yearOfCoverage.heading}</h2>
            </div>
            <div className='calculator-modal__screen-body'>
                <div className='form-group'>
                    <label htmlFor='income' style={{ width: "100%" }}>
                        <span>{config.copy[lang].yearOfCoverage.label}</span>
                    </label>
                    <select
                        id="yearOfCoverage"
                        value={selectedOption}
                        onChange={handleDropdownChange}
                        className={`form-control mt-2 ${incomeInputClasses}`}>
                        {isAfterNov30 ?
                            null :
                            (
                                <option value="" disabled hidden>
                                    {config.copy[lang].yearOfCoverage.yearOfCoveragePlaceholder}
                                </option>
                            )
                        }

                        {isAfterNov30 ? null : <option value="2023">2023</option>}
                        <option value="2024">2024</option>
                    </select>
                    <div className="invalid-feedback">
                        {config.copy[lang].yearOfCoverage.invalidFeedback}
                    </div>
                </div>
            </div>
            <div className='form-group mt-auto row'>
                <div className='col-12 mb-2'>
                    <button onClick={props.stepUp} type="button" className={nextInputClasses} disabled={nextButtonDisabled}>{config.copy[lang].utilities.next}</button>
                </div>
                <div className='col-12 mb-2'>
                    <button onClick={props.stepDown} type="button" className='btn btn-block btn-outline ac-pushButton style-default '>{config.copy[lang].utilities.back}</button>
                </div>
             
               
            </div>
        </>
    )
}

export default YearOfCoverage;
