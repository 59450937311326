const AUTOCOMPLETE_API_URL_DEVELOPMENT = "https://cici-auto-complete.azurewebsites.net/api/ciciAutoCompleteHttpTrigger?q=";
const AUTOCOMPLETE_API_URL_PRODUCTION = "https://cici-autocomplete-function-app.azurewebsites.net/api/ciciAutoCompleteHttpTrigger?q=";


function doesUrlContain(str){
  if(window.location.href.indexOf(str) > -1){
    return true;
  }
  return false;
}

function isProduction(){
  if(doesUrlContain("coveredca.com")){
    return true;
  }
  return false;
}

function getAutoCompleteApiUrl(){
  if(isProduction()){
    return AUTOCOMPLETE_API_URL_PRODUCTION;
  }
  return AUTOCOMPLETE_API_URL_DEVELOPMENT;
}

const AUTOCOMPLETE_API_URL = getAutoCompleteApiUrl();

if(!isProduction()){
  console.log("This build is for the development site.");
  console.log("AUTOCOMPLETE_API_URL: " + AUTOCOMPLETE_API_URL);
}

async function getAutoCompleteSuggestions(userInput) {
  let response;
  try {
    response = await fetch(
      AUTOCOMPLETE_API_URL + userInput
    );
  } catch (error) {
    return [];
  }
  const searchResults = await response.json();
  return searchResults.searchResults;
}

export default getAutoCompleteSuggestions;
