const isServiceOpen = (openingHours, languageCode) => {
    const schedule = openingHours[languageCode];
    const currentDate = new Date();
    const currentDay = currentDate.getDay(); 
    const currentHour = currentDate.getHours();

    if (currentDay === 0 || currentDay === 6) {
        return false; 
    }

    const weekDayHours = schedule.weekDayHours[0];
    let weekDayMatches = weekDayHours.match(/(\d{1,2})\s*(a\.m\.|p\.m\.)\s*(to|a)\s*(\d{1,2})\s*(a\.m\.|p\.m\.)/i);
    if (weekDayMatches && weekDayMatches.length === 6) {
        let startHour = parseInt(weekDayMatches[1]);
        let endHour = parseInt(weekDayMatches[4]);
        const startPeriod = weekDayMatches[2].toLowerCase();
        const endPeriod = weekDayMatches[5].toLowerCase();

        if (startPeriod === "p.m." && startHour !== 12) {
            startHour += 12;
        } else if (startHour === 12 && startPeriod === "a.m.") {
            startHour = 0;
        }

        if (endPeriod === "p.m." && endHour !== 12) {
            endHour += 12;
        } else if (endHour === 12 && endPeriod === "a.m.") {
            endHour = 0;
        }

        return currentHour >= startHour && currentHour < endHour;
    }

const extendedHours = schedule.extendedHours.find(entry => {
    const dateMatch = entry.match(/(\d{1,2}\s*(?:de\s*\w+)?)/i);
    if (dateMatch && dateMatch.length === 2) {
        const dayOfMonth = parseInt(dateMatch[1]);
        return currentDate.getDate() === dayOfMonth;
    }
    return false;
});

if (extendedHours) {
    const extendedMatches = extendedHours.match(/(\d{1,2})\s*a\.m\.\s*to\s*(\d{1,2})\s*(a\.m\.|p\.m\.)/i);

    if (extendedMatches && extendedMatches.length === 4) {
        const startHour = parseInt(extendedMatches[1]);
        const endHour = parseInt(extendedMatches[2]);
        const period = extendedMatches[3].toLowerCase();

        if (period === "p.m." && endHour !== 12) {
            endHour += 12;
        }

        return currentHour >= startHour && currentHour <= endHour;
    }
}

return false; 
}

export default isServiceOpen;