function clearInactivityTimeout (t) {
  if(t.state.highestTimeoutId){
    // console.log("CLEAR inactivity TIMEOUT")
    clearTimeout(t.state.highestTimeoutId);
    t.setState({highestTimeoutId: null});
  }
  if(t.state.modalTimeoutId){
    // console.log("CLEAR modal TIMEOUT")
    clearTimeout(t.state.modalTimeoutId);
    t.setState({modalTimeoutId: null});
  }
}

export default clearInactivityTimeout;