import React from "react";
import PlanCard from "../components/PlanCardEs";
import EnrollmentDetails from "../components/enrollmentDetails";
import translations from "../EnrollmentStatusTranslations";
import { useEffect } from "react";
import "./EnrollmentStatus.css";

function EnrollmentStatusResults({
  onNext,
  onBack,
  onClose,
  data,
  lang,
  sendLoggingEventToBackend,
  selectedYear,
  navigationSource,
}) {
  const { resultHeading, provideFeedback, back, exit } = translations[lang];

  console.log("11111",selectedYear)

  let intentName = (lang === "es" ? "enrollmentStatusResults"+selectedYear+"Spanish" : "enrollmentStatusResults"+selectedYear);
  let text = (lang==="es" ? "Enrollment Status Results "+selectedYear+" Spanish" : "Enrollment Status Results" + selectedYear);

  useEffect(() => {
    const loggingEvent =
        {
          intentName: intentName,
          text: text
          }
    sendLoggingEventToBackend(loggingEvent);
  }, []);

  console.log("navigationSource", navigationSource);
  const backButtonLabel = navigationSource === "authentication" ? exit : back;
  const backButtonAction =
    navigationSource === "authentication" ? onClose : onBack;

  const getDetails = selectedYear
    ? {
        [selectedYear]:
          (data.getDetails && data.getDetails[selectedYear]) || {},
      }
    : data.getDetails || {};

  const navigateBack = () => {
    console.log("navigationSource", navigationSource);
    if (navigationSource === "authentication") {
      onClose(); // Go back to authentication screen
    } else {
      onBack(); // Go back to enrollment year screen
    }
  };

  return (
    <div className="h-100 d-flex flex-column media-height">
      <div className="scroll-container">
        <h3 style={{ marginBottom: "30px" }}>
          {/* <strong>{resultHeading}</strong> */}
        </h3>
        {Object.entries(getDetails).map(([year, details]) => (
          <div key={year}>
            {details.Health.map((healthPlan, index) => (
              <div key={index}>
                {console.log("healthPlan", healthPlan)}{" "}
                <PlanCard
                  year={year}
                  enrollmentStatus={healthPlan.enrollmentStatus}
                  enrollmentStartDate={
                    healthPlan.enrollmentBenefitEffectiveStartDate
                  }
                  enrollmentEndDate={
                    healthPlan.enrollmentBenefitEffectiveEndDate
                  }
                  planDetails={healthPlan.planName}
                  healthHouseholdMembers={healthPlan.memberCount}
                  lang={lang}
                  planType="Health"
                  totalPlans={details.Health.length}
                  index={index}
                />
                <EnrollmentDetails
                  data={healthPlan}
                  lang={lang}
                ></EnrollmentDetails>
              </div>
            ))}

            {details.Dental.map((dentalPlan, index) => (
              <div key={index}>
                <PlanCard
                  year={year}
                  enrollmentStatus={dentalPlan.enrollmentStatus}
                  enrollmentStartDate={
                    dentalPlan.enrollmentBenefitEffectiveStartDate
                  }
                  enrollmentEndDate={
                    dentalPlan.enrollmentBenefitEffectiveEndDate
                  }
                  planDetails={dentalPlan.planName}
                  dentalHouseholdMembers={dentalPlan.memberCount}
                  lang={lang}
                  planType="Dental"
                  totalPlans={details.Dental.length}
                  index={index}
                />
                <EnrollmentDetails
                  data={dentalPlan}
                  lang={lang}
                ></EnrollmentDetails>
              </div>
            ))}
          </div>
        ))}
      </div>
      <div className="fixed-footer">
        <div className="fade-overlay fade-overlay--first "></div>
        <div className="button-container-1">
          <div className="form-group mt-auto">
            <button className="btn btn-block ac-pushButton" onClick={onNext}>
              {provideFeedback}
            </button>
            <button
              className="btn btn-block btn-outline ac-pushButton"
              type="button"
              onClick={backButtonAction}
            >
              {backButtonLabel}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EnrollmentStatusResults;
