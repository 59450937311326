import React, { useEffect, useState } from 'react';
import config from './config';

const labelToNumberMapping = (label) => {
    switch (label) {
        case 'Every 2 weeks':
            return 26;
        case 'Weekly':
            return 52;
        case 'Twice a month':
            return 24;
        case 'Monthly':
            return 12;
        case 'Quarterly':
            return 4;
        case 'Yearly':
            return 1;
        default:
            return 0; 
    }
};

function HealthContributionFrequency(props) {
    const lang = document.documentElement.lang;
    const [hasInteracted, setHasInteracted] = useState(false); 
    const [selectedOption, setSelectedOption] = useState(sessionStorage.getItem('selectedOption') || '');

    useEffect(() => {
        sessionStorage.removeItem('hasInteracted');
    }, []);

   

    const _isValid = (value) => {
        if (isNaN(value) || value === '' || value === 0) {
            return false;
        }
        return true;
    };

    const handleDropdownChange = (event) => {
        const labelValue = event.target.value;

        setSelectedOption(labelValue);
        props.setEmployerCollectionFrequency(labelToNumberMapping(labelValue));
        sessionStorage.setItem('selectedOption', labelValue);
        sessionStorage.setItem(
            'hasInteracted',
            _isValid(labelToNumberMapping(labelValue)) ? 'true' : 'false'
        );

        setHasInteracted(true);
    };


    const onNext = () => {
        if (selectedOption !== '') {
            props.stepUp();
        }
    };
    

    const dropdownClasses = hasInteracted && !_isValid(props.employerCollectionFrequency) ? 'form-control is-invalid' : 'form-control';

    return (
        <>
            <div className='form-group'>
                <label htmlFor='contribution-frequency'>
                    {config.copy[lang].healthContributionFrequency.label1}
                </label>
                <select
                    onChange={handleDropdownChange}
                    id="contribution-frequency"
                    className={`form-control mt-2 ${dropdownClasses}`}
                    value={selectedOption}
                >
                    <option value="" disabled hidden>{config.copy[lang].healthContributionFrequency.placeholder1}</option>
                    <option value="Weekly">{config.copy[lang].healthContributionFrequency.option1}</option>
                    <option value="Every 2 weeks">{config.copy[lang].healthContributionFrequency.option2}</option>
                    <option value="Twice a month">{config.copy[lang].healthContributionFrequency.option3}</option>
                    <option value="Monthly">{config.copy[lang].healthContributionFrequency.option4}</option>
                    <option value="Quarterly">{config.copy[lang].healthContributionFrequency.option5}</option>
                    <option value="Yearly">{config.copy[lang].healthContributionFrequency.option6}</option>
                </select>
                {!_isValid(props.employerCollectionFrequency) && hasInteracted && (
                    <div className="invalid-feedback">
                        {config.copy[lang].healthContributionFrequency.invalidFeedback1}
                    </div>
                )}
            </div>
        </>
    )
}

export default HealthContributionFrequency;