function shouldActivitySendDataLayerEvent(activity){
  if(!activity){
    return false;
  }
  if(activity.name && activity.name === "logEvent"){
    return false;
  }
  if(!activity.value){
    return false;
  }
  if(!activity.value.shouldActivitySendDataLayerEvent){
    return false;
  }
  return true;
}
export default shouldActivitySendDataLayerEvent;