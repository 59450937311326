import React from "react";
import config from "./config";
import Tooltip from "./Tooltip";
import sendEventToDataLayer from "../datalayer-event-logger-for-transactions/sendEventToDataLayer";
import { configURLs } from "../Constants";
import useConsumerName from "./hooks/useConsumerName";

function AppIntro(props) {
  const { AppConsumerName } = useConsumerName();
  const { localeValue } = props;


  let appIntroConfig = {};
  if (config.copy[localeValue] && config.copy[localeValue].appIntro) {
    appIntroConfig = config.copy[localeValue].appIntro;
  }


  const MINIMUM_VALUE_STANDARD_TOOLTIP_TEXT =
    appIntroConfig.bullet2TooltipContent;
  const INTRO_EN = `Let's check the affordability of coverage offers for <b>${AppConsumerName}.</b>`;
  const INTRO_ES = `Revisemos la asequibilidad de las ofertas de cobertura para <b>${AppConsumerName}.</b>`;
  const introIcon = (
    <img
      className="introIcon"
      src={configURLs.url.BASE_URL + "/images/botImages/intro.gif"}
      alt="Intro Icon"
      width="198px"
      height="51px"
    />
  );


  const isApp = process.env.REACT_APP_IN_APPLICATION === "true";
  const intentName = isApp ? props.localeValue === "en" ? "GetStartedInApp" : "GetStartedInAppSpanish" : props.localeValue === "en" ? "affordabilityToolStarted" : "affordabilityToolStartedSpanish";
  const text = isApp ? props.localeValue === "en" ? "Get started in app" : "Get started in app Spanish" : props.localeValue === "en" ? "Affordability Tool Started" : "Affordability Tool Started Spanish";


  return (
    <div className="calculator-modal__app-intro">
      {introIcon}
      <h4 className="calculator-modal__app-intro-title">
        {localeValue === "en" ? (
          <span dangerouslySetInnerHTML={{ __html: INTRO_EN }} />
        ) : (
          <span dangerouslySetInnerHTML={{ __html: INTRO_ES }} />
        )}
      </h4>
      <p className="">
        {localeValue &&
          config.copy[localeValue].appIntro &&
          config.copy[localeValue].appIntro.subHeading}
      </p>
      <ul>
        <li id="bullet1" className="lineHeight">
          {localeValue &&
            config.copy[localeValue].appIntro &&
            config.copy[localeValue].appIntro.bullet1}
        </li>
        <li id="bullet2">
          {localeValue &&
            config.copy[localeValue].appIntro &&
            config.copy[localeValue].appIntro.bullet2}
          <Tooltip
            content={MINIMUM_VALUE_STANDARD_TOOLTIP_TEXT}
            useDefaultIcon={true}
            placement={"left"}
            maxWidth="700"
            mobilePlacement={"left"}
            tooltipType="appIntro"
          ></Tooltip>
        </li>
      </ul>
      <p>
        {localeValue &&
          config.copy[localeValue].appIntro &&
          config.copy[localeValue].appIntro.new}
      </p>
      <div className="calculator-modal__buttons-container">
        <button
          onClick={() => {
            props.stepUp();
            sendEventToDataLayer({
              event: "chatbot",
              eventDetails: {
                category: "CiCi",
                action: "Affordability Tool Clicks",
                label: "Get Started",
              },
            });
            props.sendLoggingEventToBackend({
              intentName,
              text,
            });
          }}
          type="button"
          className="calculator-modal__button calculator-modal__button--primary"  style={{ fontFamily:  'Roboto, sans-serif' }}
        >
          {localeValue &&
            config.copy[localeValue].appIntro &&
            config.copy[localeValue].appIntro.getStarted}
        </button>
      </div>
    </div>
  );
}

export default AppIntro;