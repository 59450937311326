import React from 'react'; 
import config from '../config';

function useAffordabilityDetermination(affordability, type, lang) {
  let tooltipContent;
  let headertext;
  const isInApplication = process.env.REACT_APP_IN_APPLICATION === "true";

  if (affordability === "AFFORDABLE") {
    headertext = isInApplication ? (
      <span style={{ color: "#D50000" }}>
        {config.copy[lang].affordabilityDetermination.affordableHeaderApp}
      </span>
    ) : (
      config.copy[lang].affordabilityDetermination.affordableHeader
    );

    tooltipContent = type === "individual"
      ? config.copy[lang].affordabilityDetermination.individualAffordableTooltipText
      : config.copy[lang].affordabilityDetermination.familyAffordableTooltipText;

    return {
      component: isInApplication && isInApplication ? (
        config.copy[lang].affordabilityDetermination.affordableApp + " "
      ) : (
        <span className="text-danger">
          {config.copy[lang].affordabilityDetermination.affordable + " "}
        </span>
      ),
      headertext: headertext,
      downcontext: tooltipContent,
    };
  } else {
    tooltipContent = type === "individual"
      ? config.copy[lang].affordabilityDetermination.individualNotAffordableTooltipText
      : config.copy[lang].affordabilityDetermination.familyNotAffordableTooltipText;

    headertext = isInApplication ? (
      <span style={{ color: "#008B00" }}>
        {config.copy[lang].affordabilityDetermination.notaffordableHeaderApp}
      </span>
    ) : (
      config.copy[lang].affordabilityDetermination.notAffordableHeader
    );

    return {
      component: isInApplication && isInApplication ? (
        config.copy[lang].affordabilityDetermination.notAffordableApp + " "
      ) : (
        <span className="text-success">
          {config.copy[lang].affordabilityDetermination.notAffordable + " "}
        </span>
      ),
      headertext: headertext,
      downcontext: tooltipContent,
    };
  }
}

export default useAffordabilityDetermination;
