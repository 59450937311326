import { useState } from "react";
import React from "react";
import { FaStar } from "react-icons/fa";
import '../../../sb260/components/FeedBack/StarRating.css'
import translations from '../EnrollmentStatusTranslations'

const colors = {
    yellow: "#FFD700",
    gray: "#ABABAB"
}
function EnrollmentStatusFeedbackPage({onClose, onNext, sendLoggingEventToBackend, lang}) {
    const { feedbackHeading, feedbackIntro, feedbackPlaceHolderText, feedbackRatingHeading , feedbackSkip, submitfeedBack } = translations[lang];
    const [hoverValue, setHoverValue] = useState(null);
    const stars = Array(5).fill(0);
    const [rating, setrating] = useState(null)
    const [commentText, setCommentText] = useState(null)

    const getComment = (e) => {
        setCommentText(e.target.value)
    }

    const handleSubmit = (e) => {
        onNext();
        console.log("****")
        sendLoggingEventToBackend({
            intentName: "EnrollmentStatusFeedback",
            text: "Enrollment Status Feedback",
            rating: rating,
            comment: commentText
            });
        e.stopPropagation();
    }


    return (
        <div className='h-100 d-flex flex-column'>
                <h3>{feedbackHeading}</h3>
                <div className="stars">
                    {stars.map((_, index)=> {
                        const ratingValue = index+1
                        return(
                            <lable>
                                <input 
                                type="radio" 
                                name="rating" 
                                value={ratingValue} 
                                onClick={() => setrating(ratingValue)}
                                />
                            <FaStar
                                className="star"
                                size={24}
                                color= {ratingValue <= (hoverValue || rating) ? colors.yellow : colors.gray}
                                style={{
                                    cursor: "pointer"
                                }}
                                onMouseEnter={() => setHoverValue(ratingValue)}
                                onMouseLeave={() => setHoverValue(null)}
                                onClick={() => setrating(ratingValue)}
                                />
                                </lable>
                        )
                            })}
                </div>
                <textarea 
                    className="textBlock"
                    placeholder= {feedbackPlaceHolderText}
                    onChange={getComment} 
                />
                
                <div className='form-group mt-auto'>
                    <button className='btn btn-block ac-pushButton' type="button" disabled={!rating} onClick={handleSubmit}>{submitfeedBack}</button>
                    <button className='btn btn-block ac-pushButton' onClick={onClose}>{feedbackSkip}</button>
                </div>
            </div>
             
             
      
            
    )
}




export default EnrollmentStatusFeedbackPage;