import React from 'react';

function WizardUtilities(props){
    const { children } = props;
    return(
        <div className='wizard__utilities'>
            {children}
        </div>
    )
}

export default WizardUtilities;