import { useEffect, useState } from "react";
import React from 'react';
import translations from "../translation";

function Sb260ContactSupportMixedHouseHoldPage({ onClose, sendLoggingEventToBackend, lang, chatWithRepresentative }) {
    const [serviceCenterHours, setServiceCenterHours] = useState({ en: '', es: '' });
    const { mixedHouseHoldheading, mixedHouseHoldIntro, mixedHouseHoldBody, mixedHouseHoldCloseText, mixedHouseHoldServiceCenterHoursHeading } = translations[lang];

    useEffect(() => {
        const fetchData = async () => {
          const url = "https://charming-mallard.cloudvent.net/api/hours.json";
          const response = await fetch(url);
          const data = await response.json();
          Object.keys(data).forEach(key => {
            if (key === "en") {
              data[key] = data[key].replace('p.m.Saturday', 'p.m.\nSaturday');
            } else if (key === "es") {
              data[key] = data[key].replace('p.m.Sábado', 'p.m.\nSábado');
            }
          });
          setServiceCenterHours(data);
        };
        fetchData().catch((error) => console.error("Error fetching data:", error));
      }, []);

    const introWithItalicReminder = mixedHouseHoldIntro.replace('Reminder:', '<i>Reminder:</i>');

    return (
        <div className="h-100 d-flex flex-column">
            <h2>{mixedHouseHoldheading}</h2>
            <p dangerouslySetInnerHTML={{ __html: introWithItalicReminder }}></p>
            {lang === "en" ? (
                <p className='pb-5'>
                     You can <span onClick={chatWithRepresentative} style={{textDecoration: 'underline', cursor: 'pointer', color: '#4F63F9'}}>chat with a representative</span> or call us at (855) 708-4590.
              </p>
            ) : (
                <p className='pb-5'>
                    Puedes <span onClick={chatWithRepresentative}style={{textDecoration: 'underline', cursor: 'pointer', color: '#4F63F9'}}>chatear con un representante</span>o llamarnos al (855) 708-4590.
                </p>
            )}
            <h4 style={{fontSize: "19px", fontWeight: "bold", marginTop: "-21px"}}>{mixedHouseHoldServiceCenterHoursHeading}</h4>
            <p className="pb-5" >
                {serviceCenterHours[lang].split("\n")[1]}<br />  
                {serviceCenterHours[lang].split("\n")[2]}
            </p>
                    <div className='form-group mt-auto'>
                <button className='btn btn-block ac-pushButton' onClick={onClose}>{mixedHouseHoldCloseText}</button>
            </div>
        </div>
    )
}

export default Sb260ContactSupportMixedHouseHoldPage;
