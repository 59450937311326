import displayTrendingTopics from "../auto-complete/displayTrendingTopics";
import loadTrendingTopics from "../auto-complete/loadTrendingTopics";

function trendingTopics(ciciStore) {
  let ciciTextBox = getCiciTextBoxElement();

  if (!ciciTextBox) {
    return;
  }

  let lang = getPageLang();
  let parent = ciciTextBox.parentElement.parentElement.parentElement;
  let referenceNode = parent.querySelector(".main");
  let trendingTopics = loadTrendingTopics(lang);

  // delete the trending-topics-container
  removeTrendingTopicsContainer();

  // create the outer container
  displayTrendingTopics(trendingTopics, ciciTextBox, parent, referenceNode, ciciStore, lang);

  // save in local storage that trending topics has been shown
  localStorage.setItem("trendingTopicsShown", "true");
}

function getPageLang() {
  return document.documentElement.lang;
}

function getCiciTextBoxElement() {
  return document.querySelector('input[data-id="webchat-sendbox-input"');
}

export function removeTrendingTopicsContainer() {
  const trendingTopicsContainer = document.getElementById("trending-topics-container");
  if (!trendingTopicsContainer) {
    return;
  }
  trendingTopicsContainer.remove();
}

export default trendingTopics;
