import React from "react";

const translations = {
    en :{
        welcomeHeading:"Let’s check the status of your enrollment.",
        welcomeBody1: "If you have completed an application, you can quickly check the status of your Covered California enrollment and details.",
        note: "Note:",
        medicalEnrollment:"Medi-Cal Enrollment",
        welcomeBody2:<>For help with Medi-Cal enrollment, contact your <a href="https://www.dhcs.ca.gov/services/medi-cal/Pages/CountyOffices.aspx">local county office</a>.</>,
        welcomeButton1:"Get Started",
        exitButton: "Exit",
        authHeading:"To begin, confirm your identity.",
        dobLabel:"Date of Birth",
        ssnLabel:"Social Security Number",
        zipLabel:"Zip code",
        dobErrorMessage : "Please enter a valid date of birth.",
        ssnErrorMessage : "Please enter a 9-digit Social Security Number.",
        zipErrorMessage : "Please enter a valid 5-digit ZIP Code." ,
        authButton1:"Continue",
        resultHeading:"You are enrolled in coverage.",
        planDetailsEnrollmentId: "Enrollment ID:",
        planDetailsYouPay:"You pay",
        perMonth:"/mo",
        provideFeedback: "Provide Feedback",
        noEnrollmentStatusHeading: "You are not enrolled in coverage.",
        noEnrollmentStatusBody: <> I could not find any enrollment details for your account.<br/><br/>
        For more information, you can <a href="https://covered-ca.my.salesforce-sites.com/chat/?lang=en_US"> chat with a representative</a> or give us call.</>,
        serviceCenterHeading: "Service Center",
        feedbackHeading:"How did I do today?",
        feedbackPlaceHolderText:"Tell us more about your experience!",
        feedbackSkip:"Exit",
        submitfeedBack:"Submit",
        thankyouHeading: "Thank you!",
        thankyouIntro:"Your feedback means a lot! With your help, we can make our services even better for you.",
        contactSupportHeading:"Contact Support",
        contactSupportBody1: "I have found multiple records under this account.",
        contactSupportBody2: "Please contact a service center representative for assistance.",
        contactSupportMultipleBody1: "Please contact a service center representative for assistance.",
        serviceCenterHoursHeading:"Service Center Hours",
        thankyouIntro:"Your feedback means a lot! With your help, we can make our services even better for you.",
        yearOfCoverageHeading: "Choose your coverage year",
        yearOfCoverageSubHeading: "Which year would you like to see?",
        yearOfEnrollmentContinue: "Continue",
        yearOfEnrollmentExit: "Exit",
        yearOfCoverageOption: "Select Year",
        back:"Back",
        HealthPlans: "Health Plans",
        dentalPlans: "Dental Plans",
        houseHoldMembers: "Household members:",
        houseHoldMembers1: "Members:",
        enrollmentStatusHeading:"Enrollment Status:",
        enrollmentStatusHeading1: "Status: ",
        PremiumSavings: "Premium before savings",
        PremiumSavings1: "Premium",
        Savings: "Savings",
        amountYouPay: "Amount you pay",
        exit: "Exit",
        perMonth: "/mo"
    },
    es:{
        welcomeHeading: "Verifiquemos el estatus de tu inscripción.",
        welcomeBody1:"Si completaste una aplicación, puedes verificar rápidamente el estatus y los detalles de tu inscripción de Covered California.",
        note:"Nota:",
        medicalEnrollment: "Inscripción en Medi-Cal",
        welcomeBody2:<>Para obtener ayuda con la inscripción en Medi-Cal, comunícate con la <a href="https://www.dhcs.ca.gov/services/medi-cal/Pages/CountyOffices.aspx">oficina local de tu condado.</a></>,
        welcomeButton1:"Comenzar",
        exitButton: "Salir",
        authHeading:"Para comenzar, confirma tu identidad.",
        dobLabel:"Fecha de nacimiento",
        ssnLabel:"Número de Seguro Social",
        zipLabel:"Código postal",
        dobErrorMessage : "Por favor ingresa una fecha de nacimiento válida.",
        ssnErrorMessage : "Por favor ingresa un número de Seguro Social de 9 dígitos.",
        zipErrorMessage : "Por favor ingresa un código postal válido.",
        authButton1:"Continuar",
        resultHeading:"Estás inscrito en cobertura.",
        planDetailsEnrollmentId: "ID de inscripción:",
        planDetailsYouPay:"Tú pagas",
        perMonth:"/mes",
        provideFeedback: "Dame tus comentarios",
        noEnrollmentStatusHeading: "No estás inscrito en la cobertura.",
        noEnrollmentStatusBody: <> No pude encontrar ningunos detalles de inscripción para tu cuenta.<br/><br/>
        Para más información, puedes <a href="https://covered-ca.my.salesforce-sites.com/chat/?lang=es"> chatear con un representante</a> o llamarnos.</>,
        serviceCenterHeading: "Centro de servicio",
        feedbackHeading:"¿Cómo fue tu experiencia hoy?",
        feedbackPlaceHolderText:"Cuéntanos más sobre tu experiencia.",
        feedbackSkip:"Salir",
        submitfeedBack:"Enviar",
        thankyouHeading:"¡Gracias!",
        thankyouIntro:"¡Tus comentarios significan mucho!Con tu ayuda, podemos hacer que nuestros servicios sean aún mejores para ti.",
        contactSupportHeading: "Comunícate con el centro de apoyo.",
        contactSupportBody1: "Encontré varios registros en esta cuenta.",
        contactSupportBody2: "Por favor comunícate con un representante del centro de servicio para obtener ayuda.",
        contactSupportMultipleBody1: "Un representante del centro de servicio tendrá que ayudarte con el estatus y los detalles de tu inscripción.",
        serviceCenterHoursHeading:"Horario del Centro de Servicio",
        yearOfCoverageHeading: "Elije tu año de cobertura",
        yearOfCoverageSubHeading: "¿Qué año te gustaría ver?",
        yearOfEnrollmentContinue: "Continuar",
        yearOfEnrollmentExit: "Salir",
        yearOfCoverageOption: "Seleccionar año",
        back:"Volver",
        HealthPlans: "Planes de salud",
        dentalPlans: "Planes dentales",
        houseHoldMembers: "Miembros del hogar:",
        houseHoldMembers1:"Miembros: ",
        enrollmentStatusHeading: "Estatus de inscripción:",
        enrollmentStatusHeading1:"Estatus: ",
        PremiumSavings: "Prima antes de ahorros",
        PremiumSavings1:"Prima",
        Savings: "Ahorros",
        amountYouPay: "Cantidad que pagas",
        exit:"Salir",
        perMonth:"/mes"
    }
}

export default translations