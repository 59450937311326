import React from 'react';
import { configURLs } from "../Constants";
import copyConfig from './config';

function Warning(props) {
    const lang = document.documentElement.lang;
    return (
        <>
            <div>
                <img src={configURLs.url.BASE_URL + "/images/botImages/Exclamation.svg"} style={{maxWidth: "54px", maxHeight: "54px", marginBottom: "20px"}}/>
                <h2>{copyConfig.copy[lang].warning.heading}</h2>
            </div>
            
            <p>{copyConfig.copy[lang].warning.paragraph3} <br />{copyConfig.copy[lang].warning.paragraph4}</p>

            <div className='form-group mt-auto row'>
                <div className='col-12 mb-2'>
                    <button onClick={props.stepUp} type="button" className='btn btn-block ac-pushButton style-default'>{copyConfig.copy[lang].utilities.next}</button>
                </div>
                <div className='col-12'>
                    <button onClick={props.stepDown} type="button" className='btn btn-block btn-outline ac-pushButton style-default'>{copyConfig.copy[lang].utilities.back}</button>
                </div>
               
              
            </div>
        </>
    )
}

export default Warning;