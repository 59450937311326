import React, { useEffect, useRef, useState } from "react";
import Tooltip from "./Tooltip";
import config from "./config";
import useDollarAmountFormatter from "../hooks/useDollarAmountFormatter";

function HealthContributionCost(props) {
  const didMount = useRef(false);
  const [isFamilyCostValid, setIsFamilyCostValid] = useState(true);
  const [isEmployeeCostValid, setIsEmployeeCostValid] = useState(true);
  const lang = document.documentElement.lang;
  const { getFormattedDollarAmount } = useDollarAmountFormatter();
  const [
    displayEmployeeContributionForIndividual,
    setDisplayEmployeeContributionForIndividual,
  ] = useState("");
  const [
    displayEmployeeContributionForFamily,
    setDisplayEmployeeContributionForFamily,
  ] = useState("");
  const [employerCollectionFrequency, setEmployerCollectionFrequency] =
    useState(0);
  const [hasInteracted, setHasInteracted] = useState(false);
  const [selectedOption, setSelectedOption] = useState(() => {
    const storedOption = sessionStorage.getItem("selectedOption");
    return storedOption && storedOption !== "undefined" ? storedOption : "";
  });
 const [isAlignMarginsChecked, setIsAlignMarginsChecked] = useState(() => {
    const storedState = sessionStorage.getItem("isAlignMarginsChecked");
    return storedState === "true"; 
  });
  
  useEffect(() => {
    sessionStorage.setItem("isAlignMarginsChecked", isAlignMarginsChecked);
  }, [isAlignMarginsChecked]);
  
  useEffect(() => {
    setIsEmployeeCostValid(true);
    setIsFamilyCostValid(true);
    const storedOption = sessionStorage.getItem("selectedOption");
    if (storedOption && storedOption !== "undefined") {
      const frequency = labelToNumberMapping(storedOption);
      setEmployerCollectionFrequency(frequency);
    }
  }, []);

  useEffect(() => {
    if (
      props.employeeContributionForIndividual &&
      isCostValid(props.employeeContributionForIndividual)
    ) {
      const formattedValue =
        "$" + getFormattedDollarAmount(props.employeeContributionForIndividual);
      setDisplayEmployeeContributionForIndividual(formattedValue);
    }

    if (
      props.employeeContributionForFamily &&
      isCostValid(props.employeeContributionForFamily)
    ) {
      const formattedValue =
        "$" + getFormattedDollarAmount(props.employeeContributionForFamily);
      setDisplayEmployeeContributionForFamily(formattedValue);
    }

    sessionStorage.removeItem("hasInteracted");
  }, [
    props.employeeContributionForIndividual,
    props.employeeContributionForFamily,
    getFormattedDollarAmount,
  ]);

  const labelToNumberMapping = (label) => {
    switch (label) {
      case "Every 2 weeks":
        return 26;
      case "Weekly":
        return 52;
      case "Twice a month":
        return 24;
      case "Monthly":
        return 12;
      case "Quarterly":
        return 4;
      case "Yearly":
        return 1;
      default:
        return 0;
    }
  };

  const handleDropdownChange = (event) => {
    const labelValue = event.target.value;
    const frequency = labelToNumberMapping(labelValue);
    setSelectedOption(labelValue);
    setEmployerCollectionFrequency(frequency);
    props.setEmployerCollectionFrequency(frequency);
    sessionStorage.setItem("selectedOption", labelValue);
    setHasInteracted(true);
  };

  const validate = () => {
    if (!_isEmployeeCostValid()) {
      setIsEmployeeCostValid(false);
    } else {
      setIsEmployeeCostValid(true);
    }
    if (!_isFamilyCostValid()) {
      setIsFamilyCostValid(false);
    } else {
      setIsFamilyCostValid(true);
    }
  };

  const isCostValid = (cost) => {
    const numericValue = cost.replace(/[^0-9.-]/g, "");
    if (isNaN(numericValue) || numericValue === "") {
      return false;
    }
    return true;
  };

  const _isEmployeeCostValid = () => {
    const cleanedEmployeeCost = props.employeeContributionForIndividual.replace(
      /[^0-9.-]/g,
      ""
    );
    return isCostValid(cleanedEmployeeCost);
  };

  const _isFamilyCostValid = () => {
    if (!props.isFamilyCoverageApplicable) {
      return true;
    } else {
      const cleanedFamilyCost = props.employeeContributionForFamily.replace(
        /[^0-9.-]/g,
        ""
      );
      return isCostValid(cleanedFamilyCost);
    }
  };

  const handleChangeIndividual = (e) => {
    const rawValue = e.target.value;
    props.setEmployeeContributionForIndividual(
      rawValue.startsWith("$") ? rawValue.slice(1) : rawValue
    );
    setDisplayEmployeeContributionForIndividual(rawValue);
  };

  const handleChangeFamily = (e) => {
    const rawValue = e.target.value;
    props.setEmployeeContributionForFamily(
      rawValue.startsWith("$") ? rawValue.slice(1) : rawValue
    );
    setDisplayEmployeeContributionForFamily(rawValue);
  };

  const handleFocus = (e) => {
    const value = e.target.value.trim();
    if (value !== "" && !value.startsWith("$")) {
      e.target.value = "$" + value;
    }
  };

  const handleBlur = (e) => {
    const cleanedValue = e.target.value.replace(/[^0-9.-]/g, "").trim();

    if (cleanedValue === "") {
      if (e.target.id === "contribution-per-paycheck-individual") {
        props.setEmployeeContributionForIndividual(cleanedValue);
        setDisplayEmployeeContributionForIndividual("");
      } else if (e.target.id === "contribution-per-paycheck-family") {
        props.setEmployeeContributionForFamily(cleanedValue);
        setDisplayEmployeeContributionForFamily("");
      }
    } else {
      if (e.target.id === "contribution-per-paycheck-individual") {
        props.setEmployeeContributionForIndividual(cleanedValue);
        setDisplayEmployeeContributionForIndividual(
          "$" + getFormattedDollarAmount(cleanedValue)
        );
      } else if (e.target.id === "contribution-per-paycheck-family") {
        props.setEmployeeContributionForFamily(cleanedValue);
        setDisplayEmployeeContributionForFamily(
          "$" + getFormattedDollarAmount(cleanedValue)
        );
      }
    }
  };

  const isDropdownValid = () => {
    return employerCollectionFrequency > 0;
  };

  const _isValid = () => {
    const employeeValid = _isEmployeeCostValid();
    const familyValid = _isFamilyCostValid();
    const dropdownValid = isDropdownValid();
    return employeeValid && familyValid && dropdownValid;
  };

  const MINIMUM_VALUE_STANDARDS_TOOLTIP_TEXT_ES =
    "\"Solo para el empleado\" también se podría referir como cobertura “individual\".";
  const MINIMUM_VALUE_STANDARDS_TOOLTIP_TEXT_EN =
  "\"Employee-only\" may also be referred to as \"Single\"."
  const TOOLTIP_TEXT_HEALTHCONTRIBUTION_FAMILY_EN=
     "Amount may vary by number of family members. Enter the amount for your family size.";
  const TOOLTIP_TEXT_HEALTHCONTRIBUTION_FAMILY_ES=
  "El costo podría variar según el número de miembros de la familia. Ingresa el costo para el tamaño de tu familia.";

  const employeeInputClasses = isEmployeeCostValid
    ? "form-control"
    : "form-control is-invalid";
  const familyInputClasses = isFamilyCostValid
    ? "form-control"
    : "form-control is-invalid";
  const nextInputClasses = _isValid()
    ? "btn btn-block ac-pushButton style-default"
    : "btn btn-block ac-pushButton style-default btn-disabled";
  const tooltipText =
    lang === "es"
      ? MINIMUM_VALUE_STANDARDS_TOOLTIP_TEXT_ES
      : MINIMUM_VALUE_STANDARDS_TOOLTIP_TEXT_EN;
  const tooltipText1 =
    lang === "es"
    ? TOOLTIP_TEXT_HEALTHCONTRIBUTION_FAMILY_ES
    : TOOLTIP_TEXT_HEALTHCONTRIBUTION_FAMILY_EN;
  const dropdownClasses =
    hasInteracted && !isDropdownValid()
      ? "form-control is-invalid"
      : "form-control-affordability";
  return (
    <>
      <h2>{config.copy[lang].healthContributionCost.heading}</h2>
      <p
        id="contributionLabel"
        >
        {" "}
        {config.copy[lang].healthContributionCost.label4}
        </p>
      <div className="form-group no-margin">
        <label id="contribution-per-paycheck-individual"
          htmlFor="contribution-per-paycheck-individual"
          style={{ width: "100%" }}
        >
          {config.copy[lang].healthContributionCost.label1}
          <Tooltip
          content={tooltipText}
          useDefaultIcon={true}
          placement={"right"}
          mobilePlacement={"right"}
          marginLeft="50"
          maxWidth="600"
          tooltipType="healthcontribution"
        ></Tooltip>
        </label>
        <input
          type={"text"}
          placeholder={config.copy[lang].healthContributionCost.placeholder1}
          onChange={handleChangeIndividual}
          value={displayEmployeeContributionForIndividual}
          className={employeeInputClasses}
          onBlur={(e) => handleBlur(e)}
          onFocus={(e) => handleFocus(e)}
          id="contribution-per-paycheck-individual"
        />
        <div className="invalid-feedback">
          {config.copy[lang].healthContributionCost.invalidFeedback1}
        </div>
      </div>

      <div
        // className={
        //   props.isFamilyCoverageApplicable ? "form-group mt-auto" : "form-group"
        // }
      >
        {props.isFamilyCoverageApplicable ? (
          <label id="contribution-per-paycheck-family"
            htmlFor="contribution-per-paycheck-family"
            style={{ width: "100%" }}
          >
            {config.copy[lang].healthContributionCost.label2}
            <Tooltip
          content={tooltipText1}
          useDefaultIcon={true}
          placement={"right"}
          mobilePlacement={"right"}
          marginLeft="50"
          maxWidth="600"
          tooltipType="healthcontributionFamily"
        ></Tooltip>
          </label>
        ) : null}
<div className={`d-flex align-items-center healthContributionCheckbox ${isAlignMarginsChecked ? 'align-margins-with-margin' : ''}`}>
  <input
    type="checkbox"
    id="family-coverage-not-applicable-checkbox"
    value={!props.isFamilyCoverageApplicable}
    checked={!props.isFamilyCoverageApplicable}
    onChange={() => {
      props.setIsFamilyCoverageApplicable(!props.isFamilyCoverageApplicable);
      setIsAlignMarginsChecked(!isAlignMarginsChecked); // Toggle the state when checkbox changes
    }}
  />
  <label
    htmlFor="family-coverage-not-applicable-checkbox"
    className="mb-0 ml-2"
  >
    {config.copy[lang].healthContributionCost.label3}
  </label>
</div>
        {props.isFamilyCoverageApplicable ? (
          <>
            <input
              type={"text"}
              placeholder={
                config.copy[lang].healthContributionCost.placeholder2
              }
              onChange={handleChangeFamily}
              value={displayEmployeeContributionForFamily}
              className={familyInputClasses}
              id="contribution-per-paycheck-family"
              onBlur={(e) => handleBlur(e)}
              onFocus={(e) => handleFocus(e)}
            ></input>

            <div className="invalid-feedback">
              {config.copy[lang].healthContributionCost.invalidFeedback2}
            </div>
          </>
        ) : null}
        <div className="form-group no-margin">
          <label htmlFor="contribution-frequency" id="contributionFrequencyNotApp">
            {config.copy[lang].healthContributionFrequency.label1}
          </label>
          <select
            onChange={handleDropdownChange}
            id="contribution-frequency"
            className={dropdownClasses}
            value={selectedOption}
          >
            <option id="frequency" value="" disabled hidden>
              {config.copy[lang].healthContributionFrequency.placeholder1}
            </option>
            <option value="Weekly">
              {config.copy[lang].healthContributionFrequency.option1}
            </option>
            <option value="Every 2 weeks">
              {config.copy[lang].healthContributionFrequency.option2}
            </option>
            <option value="Twice a month">
              {config.copy[lang].healthContributionFrequency.option3}
            </option>
            <option value="Monthly">
              {config.copy[lang].healthContributionFrequency.option4}
            </option>
            <option value="Quarterly">
              {config.copy[lang].healthContributionFrequency.option5}
            </option>
            <option value="Yearly">
              {config.copy[lang].healthContributionFrequency.option6}
            </option>
          </select>
          {!_isValid(props.employerCollectionFrequency) && hasInteracted && (
            <div className="invalid-feedback">
              {config.copy[lang].healthContributionFrequency.invalidFeedback1}
            </div>
          )}
        </div>
      </div>

      <div className="form-group mt-auto row" style={{ marginTop: "65px !important"}}>
        <div className="col-12 mb-2">
          <button
            onClick={props.stepUp}
            type="button"
            className={nextInputClasses}
            disabled={!_isValid()}
          >
            {config.copy[lang].utilities.next}
          </button>
        </div>
        <div className="col-12 mb-2">
          <button
            onClick={props.stepDown}
            type="button"
            className="btn btn-block btn-outline ac-pushButton style-default "
          >
            {config.copy[lang].utilities.back}
          </button>
        </div>

      </div>
    </>
  );
}

export default HealthContributionCost;
