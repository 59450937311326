import React from 'react';
const translations = {
    en: {
      heading: "Let's get started!",
      intro: "First, I need to confirm your identity.",
      ssnLabel: "Social Security Number",
      zipLabel: "ZIP Code",
      dobLabel: "Date of Birth",
      submitText: "Submit",
      authenticateSubmitText: "Continue",
      keepheading: "Confirm your enrollment.",
      keepintro: "You are choosing to enroll into:",
      keepPlanContinue: "Continue",
      premiumAmountMessage: (monthlyPremium) => <>You will pay <strong>${monthlyPremium}</strong> each month for this plan.</>,
      backText: "Back",
      authenticationExit:"Exit",
      acceptTermsheading : "Accept terms and conditions.",
      acceptTermsintro : "By accepting the plan and financial help listed in your notice, you agree to the following:",
      acceptTermstermConditionItems : [
          "I will file an income tax return for the benefit year",
          "If I’m married, I will file a joint tax return for the benefit year",
          "I will claim deductions for all members of my family listed in this application on my benefit year tax return; and",
          "No one else can claim me as a tax dependent for benefit year",
          "If applicable, I will use binding arbitration to resolve disputes or claims with my health plan",
          "I give consent for Covered California to check federal and state records to determine my eligibility"
      ],
      termConditionsFooterText : "For full details see the Terms & Conditions section in the letter you received from Covered California.",
      acceptText : "I accept the Covered California Terms & Conditions.",
      acceptTermssubmitText :"Confirm Enrollment",
      nonZeroPremiumheading : "Your enrollment has been updated.",
      nonZeroPremiumIntro :   <strong>You have to pay your bill before your coverage can start.</strong>,
      nonZeroPremiumbody : (
        <>
          {"To \"Pay Now\","}{" "}
          <a href="https://apply.coveredca.com/static/lw-web/account-creation/case-details?apsFlag=Y" target="_blank" rel="noreferrer">
            sign in
          </a> 
          {" and visit your Enrollment Dashboard. You can also wait for your health insurance company to send you a bill with payment instructions."}
        </>
      ),
      
      nonZeroPremiumWarningHeading : "Payment Reminder",
      nonZeroPremiumWarningBody : "You must make your first payment within 30 days or your coverage will be canceled.",
      nonZeroPremiumCloseText : "Continue",
      mixedHouseHoldCloseText:"Close",
      zeroPremiumheading: "Your enrollment has been updated.",
      zeroPremiumIntro : "Your coverage will start on the 1st of next month.",
      zeroPremiumBody : (
        <>
          <a href="https://apply.coveredca.com/static/lw-web/account-creation/case-details?apsFlag=Y" target="_blank" rel="noreferrer">
            Sign in
          </a> 
          {" and visit your Enrollment Dashboard for more details about your plan. You will also receive additional information from your health insurance company."}
        </>
      ),
      cancelHeading : "Cancel your enrollment.",
      cancelIntro : "You are choosing to cancel your enrollment into:",
      cancelSubmitText : "Continue",
      sourceOfHealthHeading : "Source of health coverage.",
      sourceOfHealthIntro : "What will be your main source of health coverage after canceling your plan from Covered California?",
      sourceOfHealthSubmitText :"Confirm Cancelation",
      cancelResultHeading : "Your enrollment has been updated.",
      cancelResultIntro : "Thanks, I have updated your account with your selection. You will also receive a letter confirming your cancelation.",
      cancelResultBody : <>You have until the end of your <a href="https://www.coveredca.com/special-enrollment/" target="_blank">special enrollment</a> period to enroll, if you change your mind.</>,
      cancelResultCloseText : "Continue",
      dobErrorMessage : "Please enter a valid date of birth.",
      ssnErrorMessage : "Please enter a 9-digit Social Security Number.",
      zipErrorMessage : "Please enter a valid 5-digit ZIP Code." ,
      mixedHouseHoldheading: "Contact Support." ,
      mixedHouseHoldIntro: "A service center representative will need to help manage your move. <i>Reminder:</i> Your new plan will be canceled unless you take action.",
      mixedHouseHoldBody:<>You can <a href="https://covered-ca.my.salesforce-sites.com/chat/?lang=en_US" target="_blank" rel="noreferrer">chat with a representative</a> or call us at (855) 708-4590.</>,
      mixedHouseHoldServiceCenterHoursHeading:"Service Center Hours",
      mixedHouseHoldServiceCenterHoursBody: "Monday to Friday: 8am to 6pm Saturday",
      isNotApsIndividualheading: "Contact support.",
      isNotApsIndividualIntro: "Please contact a service center representative for assistance.",
      notApsindividualServiceCenterHoursHeading:"Service Center Hours",
      isNotApsIndividualHoldBody: <>You can <a href="https://covered-ca.my.salesforce-sites.com/chat/?lang=en_US" target="_blank" rel="noreferrer">chat with a representative</a> or call us at (855) 708-4590.</>,  
      feedbackHeading: "We're all ears!",
      feedbackIntro: "Covered California values your feedback. Your thoughts and opinions about your move to Covered California can help us improve.",
      feedbackPlaceHolderText: "Please tell us about what we did well and where you think we have room for improvement.",
      feedbackRatingHeading : <strong>How would you rate your experience?</strong>,
      feedbackSkip: "Skip",
      submitfeedBack: "Submit",
      thankyouHeading:<strong>Thank you!</strong>,
      thankyouIntro: "Your feedback means a lot and we truly appreciate it. With your help, we can keep making our services even better for you! Thanks again",
      thankyouClose: "Close",
      keepAndPayBillHeading:"To keep your plan, pay your bill.",
      keepAndPayBillIntro:<strong>To keep the plan we chose for you and start your coverage, you need to pay your first month's bill.</strong>,
      keepAndPayBillBody:(
        <>
          {"To \"Pay Now\","}{" "}
          <a href="https://apply.coveredca.com/static/lw-web/account-creation/case-details?apsFlag=Y" target="_blank" rel="noreferrer">
          log in or create an account 
          </a> 
          {" and visit your Enrollment Dashboard. You can also wait for your health insurance company to send you a bill with payment instructions. Once you pay, you will get your insurance identification card and can start using your coverage."}
        </>
      ),
      keepAndPayBillNote: (
        <>
          <strong>Important: </strong>
          <>If you do not pay your bill by the end of your first month of coverage, the plan will be canceled.</>
        </>
      )
      
    },
    es: {
      heading: "Comencemos.",
      intro: "Primero, tenemos que confirmar tu identidad.",
      ssnLabel: "Número de Seguro Social",
      zipLabel: "Código postal",
      dobLabel: "Fecha de nacimiento",
      submitText: "Continúa",
      keepPlanContinue: "Continúa",
      keepheading: "Confirma tu inscripción.",
      keepintro: "Estás eligiendo inscribirte en:",
      premiumAmountMessage: (monthlyPremium) => <>Pagarás <strong>${monthlyPremium}</strong> cada mes por este plan.</>,
      backText: "Volver",
      acceptTermsheading : "Aceptar los Términos y Condiciones.",
      acceptTermsintro : "Al aceptar el plan y la ayuda financiera anotados, tú aceptas lo siguiente:",
      acceptTermstermConditionItems : [
          "Presentaré una declaración de impuestos de ingresos para el año de beneficios",
          "Si estoy casado, presentaré una declaración de impuestos en conjunto para el año de beneficios",
          "Reclamaré deducciones en mi declaración de impuestos para el año de beneficios por todos los miembros de mi familia anotados en esta solicitud; y",
          "Nadie más puede reclamarme como dependiente tributario para el año de beneficios",
          "Si es aplicable, usaré arbitraje obligatorio para resolver disputas o reclamaciones con mi plan de salud",
          "Doy consentimiento para que Covered California revise registros federales y del estado para determinar mi elegibilidad"
      ],
      termConditionsFooterText : "Para ver los detalles completos, lee la sección de Términos y   condiciones de la carta que recibiste de Covered California.",
      authenticationExit:"Salida",
      authenticateSubmitText: "Continúa",
      acceptText : "Acepto los Términos y Condiciones de Covered California.",
      acceptTermssubmitText :"Confirmación de Inscripción",
      nonZeroPremiumheading : "Tu inscripción fue actualizada.",
      nonZeroPremiumIntro :<strong>Tienes que pagar tu factura antes de que tu cobertura comience.</strong>,
      nonZeroPremiumbody : (
        <>
        {" Para  \"Pagar Ahora\","}{" " }
          <a href="https://apply.coveredca.com/static/lw-web/account-creation/case-details?apsFlag=Y" target="_blank" rel="noreferrer">
            inicia sesión en tu cuenta 
          </a> 
          {" y visita tu Panel de Inscripción. También puedes esperar a que tu compañía de seguros de salud te envíe una factura con las instrucciones de pago."}
        </>
      ),
      nonZeroPremiumWarningHeading : "Recordatorio de pago",
      nonZeroPremiumWarningBody : "Debes hacer tu primer pago dentro de 30 días o se cancelará tu cobertura.",
      nonZeroPremiumCloseText : "Continúa",
      mixedHouseHoldCloseText :"Cerrar",
      zeroPremiumheading: "Tu inscripción fue actualizada.",
      zeroPremiumIntro : "Tu cobertura comenzará el día 1 del mes siguiente.",
      zeroPremiumBody : (
        <>
          <a href="https://apply.coveredca.com/static/lw-web/account-creation/case-details?apsFlag=Y" target="_blank" rel="noreferrer">
          Inicia sesión
          </a> 
          {"  para visitar tu Panel de Inscripción y obtener más detalles sobre tu plan. También recibirás información adicional de tu compañía de seguros de salud."}
        </>
      ),
      cancelHeading : "Cancelar tu inscripción.",
      cancelIntro : "Estás eligiendo cancelar tu inscripción en:",
      cancelSubmitText : "Confirmar la cancelación",
      sourceOfHealthHeading : "Fuente de cobertura.",
      sourceOfHealthIntro : "¿Cuál será su fuente de cobertura de salud principal después de cancelar su plan de Covered California?",
      sourceOfHealthSubmitText :"Continúa",
      cancelResultHeading : "Tu inscripción fue actualizada.",
      cancelResultIntro : "Gracias, actualicé tu cuenta con tu selección. También recibirás una carta confirmando tu cancelación.",
      cancelResultBody :" Tienes hasta el final de tu período de inscripción especial para inscribirte, si cambias de opinión.",
      cancelResultCloseText : "Continúa",
      dobErrorMessage : "Por favor ingresa una fecha de nacimiento válida.",
      ssnErrorMessage : "Por favor ingresa un número de Seguro Social de 9 dígitos.",
      zipErrorMessage : "Por favor ingresa un código postal válido.",
      mixedHouseHoldheading: "Comunícate con el Centro de apoyo." ,
      mixedHouseHoldIntro: "Un representante del centro de servicio te ayudará a administrar tu cambio. Recordatorio: Tu plan nuevo se cancelará si no tomas acción.",
      mixedHouseHoldBody:<>Puedes <a href="https://covered-ca.my.salesforce-sites.com/chat/?lang=es" target="_blank" rel="noreferrer">chatear con un representante</a> o llamarnos al (855) 708-4590.</>,
      mixedHouseHoldServiceCenterHoursHeading:"Horario de atención",
      mixedHouseHoldServiceCenterHoursBody: "Monday to Friday: 8am to 6pm Saturday",
      isNotApsIndividualheading: "Comunícate con el Centro de apoyo.",
      isNotApsIndividualIntro: "Por favor, comunícate con un representante del centro de servicio para obtener ayuda.",
      notApsindividualServiceCenterHoursHeading:"Horario de atención",
      isNotApsIndividualHoldBody: <>Puedes <a href="https://covered-ca.my.salesforce-sites.com/chat/?lang=es" target="_blank" rel="noreferrer">chatear con un representante</a> o llamarnos al (855) 708-4590.</>,
      feedbackHeading: <strong>¡Estamos todos oídos!</strong>,
      feedbackIntro: "Covered California valora tus comentarios. Tus pensamientos y opiniones sobre tu cambio a Covered California pueden ayudarnos a mejorar.",
      feedbackPlaceHolderText: "Por favor, cuéntanos sobre lo que hicimos bien y en qué áreas crees que tenemos espacio para mejorar.",
      feedbackRatingHeading : <strong>¿Cómo calificarías tu experiencia?</strong>,
      feedbackSkip: "Saltar",
      submitfeedBack: "Enviar",
      thankyouHeading: <strong>¡Gracias!</strong>,
      thankyouIntro: "Tu opinión es muy valiosa y la apreciamos mucho. ¡Con tu ayuda, podemos seguir mejorando nuestros servicios para ti! ¡Gracias de nuevo!",
      thankyouClose: "Cerrar",
      keepAndPayBillHeading:"Para mantener tu plan, debes pagar tu factura.",
      keepAndPayBillIntro:<strong>Para mantener el plan que elegimos para ti y comenzar tu cobertura, necesitas pagar tu factura del primer mes.</strong>,
      keepAndPayBillBody:(
        <>
          {"Para \"Pagar Ahora\","}{" "}
          {/* <a href="https://apply.coveredca.com/static/lw-web/account-creation/case-details?apsFlag=Y" target="_blank" rel="noreferrer">
          Pagar Ahora 
          </a>  */}
          {" inicia sesión o crea una cuenta y visita tu Panel de Inscripción. También puedes esperar a que tu compañía de seguros de salud te envíe una factura con instrucciones de pago. Una vez que pagues, recibirás tu tarjeta de identificación de seguro y podrás empezar a utilizar tu cobertura."}
        </>
      ),
      keepAndPayBillNote: (
        <>
          <strong>Importante: </strong>
          <> Si no pagas tu factura al finalizar tu primer mes de cobertura, el plan será cancelado.</>
        </>
      )
    },
  };
  
  export default translations;