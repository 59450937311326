import React, { useEffect, useState } from 'react';
import BubbleText from './BubbleText';
import useCalcTracker from '../../hooks/useCalcTracker';
import './TalkBubble.css';

const TalkBubble = ({ cici, text, lang }) => {
  const [message, setMessage] = useState(text);

  useEffect(() => {
    if (window.location.href.includes('new-plan') && lang === 'en') {
      setMessage("Need help with your move? I can help!");
    } else if (window.location.href.includes('new-plan') && lang === 'es') {
      setMessage("¿Necesitas ayuda con tu mudanza? Te puedo ayudar.");
    } else {
      setMessage(text);
    }
  }, [lang, text]);

  useCalcTracker();

  return (
    <div id="talk-bubble" onClick={cici.handleOpen} className={lang === 'en' ? null : 'spanish'}>
      <div className="bubble-box bubble-arrow">
        <BubbleText message={message}></BubbleText>
      </div>
    </div>
  );
};

export default TalkBubble;
