import React, { useEffect } from 'react';
import translations from '../translation';
import PlanDetailsSection from '../components/PlanDetails/PlanDetails';


function Sb260WizardKeepyourplanAndPayBillPage({ onNext, lang, data, onClose }) { // accept data as a prop
    const { keepAndPayBillHeading, keepAndPayBillIntro, keepAndPayBillBody, nonZeroPremiumCloseText, keepAndPayBillNote , authenticationExit} = translations[lang];
    const plan = data && data.plan ? data.plan : '';
    return (
        <div className='h-100 d-flex flex-column'>
          <h2>{keepAndPayBillHeading}</h2>
          <PlanDetailsSection plan={plan} data={data} lang={lang} />
          <br></br>
            <p>{keepAndPayBillIntro}</p>
            <p>{keepAndPayBillBody}</p>
            <p>{keepAndPayBillNote}</p>
            <div className='form-group mt-auto'>
                <button className='btn btn-block ac-pushButton' onClick={onNext}>{nonZeroPremiumCloseText}</button>
                <button className='btn btn-block btn-outline ac-pushButton' onClick={onClose}>{authenticationExit}</button>
            </div>
        </div>
    )
}

export default Sb260WizardKeepyourplanAndPayBillPage;
