import React, { Component } from "react";
import ReactWebChat, { createStore } from "botframework-webchat";

import { DirectLine } from "botframework-directlinejs";

import { configURLs } from "./Constants";
import CiciHeader from "./CiciHeader";
import AffordabilityToolHeader from "./AffordabilityToolHeader";
import CiciHeaderLoading from "./CiciHeaderLoading";

import CiciModal from "./CiciModal";
import AffordabilityToolExitModal from "./AffordabilityToolExitModal";

// activity middleware stuff
import activityMiddleware from "./ActivityMiddleware";

// attachment middleware stuff
import attachmentMiddleware from "./AttachmentMiddleware";

import "./Cici.css";

import AutoComplete from "./auto-complete/AutoComplete";
import {
  clearInactivityTimeout,
  deactivateLiveAgentTimeout,
  endLiveAgentButtonClick,
  setInactivityTimeout,
} from "./live-agent/timeout-manager/TimeoutManager";
import { newMessageAnimation, newMessageCount } from "./new-message-notification/NewMessageNotification";
//import { showPrivacyDisclaimer, hidePrivacyDisclaimer } from './live-agent/privacy-disclaimer/privacyDisclaimerManager';
import { hideEndChatButton, showEndChatButton } from "./live-agent/endChatButton/endChatButtonManager";

import TalkBubble from './components/TalkBubble/TalkBubble';
import talkBubbleMessages from './data/messages.json';
import messageGenerator from "./utils/messageGenerator";

import './Cici-new.css';
import "./Cici.css";

import trendingTopics from "./trending-topics/trendingTopics";
import sendEventToDataLayer from "./datalayer-event-logger-for-transactions/sendEventToDataLayer";
import getGaId from "./get-ga-id/getGaId";

import CalculatorModal from './calculator/CalculatorModal';
import activityToDataLayerEventMapping from './datalayer-event-logger-for-transactions/activityToDataLayerEventMapping.json';

import Sb260Wizard from "./sb260/Sb260Wizard";

import Banner from "./components/Banner/Banner";
import Sb260EventDataMocks from './sb260/Sb260EventDataMocks';
import sb260Config from "./sb260/config";
import SuggestedActions from "./suggestedActions";
import ConsoleLogger from "./console-logger/ConsoleLogger";
import Action from "./action/Action";
import LocalStorageWatermarkRepository from "./watermark/LocalStorageWatermarkRepository";
import PreviousPageLocalStorageRepository from "./previous-page/PreviousPageLocalStorageRepository";
import AffordabilityCalculatorModal from "./calculator/AffordabilityCalculatorModal";
import isServiceOpen from "./calculator/utilities/ServiceHour";
import ATHelpModal from "./calculator/ATHelpModal";
import ATHelpModalAfterServiceHour from "./calculator/ATHelpModalAfterServiceHour";
import ClearOverlay from "./components/ClearOverlay/ClearOverlay";
import config from "./calculator/config";
import { getLocaleValue } from "./calculator/localeUtils";

import EnrollmentStatusWizard from "./enrollmentStatus/enrollmentStatus/EnrollmentStatusWizard";
import enrollmantStatusMockEvents from "./enrollmentStatus/enrollmentStatus/enrollmantStatusMockEvents";
import enrollmentStatusConfig from "./enrollmentStatus/enrollmentStatus/enrollmentStatusConfig";
//import isServiceOpen from "./calculator/utitilies/ServiceHours";
const consoleLogger = new ConsoleLogger();
const watermarkRepo = new LocalStorageWatermarkRepository(localStorage);
const previousPageRepo = new PreviousPageLocalStorageRepository(localStorage);


class Cici extends Component {
  constructor(props) {
    super(props);
    this.state = {
      directlineObject: null,
      isInitialized: false,
      open: false,
      lang: document.documentElement.lang,
      userId: null,
      conversationId: null,
      welcomed: localStorage.getItem("isWelcomed"),
      languageSwitched: this.languageSwitched(),
      isPrivacyActive: false,
      isHowToTalkToCiciActive: false,
      isATWarningActive: false,
      idealQuestions: [],
      idealQuestionsLoaded: false,
      autoCompleteLoadAttempts: 0,
      autoCompleteLoaded: false,
      headerDropdown: false,
      store: this.getCreateStore(),
      newMessages: 0,
      liveAgent: false,
      isLiveAgentTimeoutActive: false,
      animateDiv: document.querySelector("#chatbot-root"),
      highestTimeoutId: null,
      modalTimeoutId: null,
      isLiveChatDisplayed: false,
      isLiveAgentEndChatButtonDisplayed: false,
      showCiCi: true,
      autoTriggerIntents: [],
      didAutoTrigger: false,
      isOpenAffordabilityTool: false,
      isOpenHelpModal: false,
      isAffordabilityTool: process.env.REACT_APP_IN_APPLICATION == "true",
      isServiceHour: false,
      isAffordabilityToolExitOutsideOfCici: false,
      startWaterMark: null,
      isConnectedToBotserviceThroughDirectline: false,
      isBannerOpen: false,
      bannerData: null,
      activeBannner: null,
      isOpenSb260Wizard: false,
      serviceHourData: null,
      sb260Event: {
        name: "",
        data: {}
      },
      isOpenEnrollmentStatusWizard: false,
      enrollmentStatusEvent: {
        name:"",
        data: {}
      },
      localeValue: 'en', 
  };
}
  

  async componentDidMount() {
    this.determineLocale();
    

    /**
    * bubbleLinkClickHandler
    */
    this.bubbleLinkClickHandler();


   /** 
    Fetching hours for in-app
   */
    if (process.env.REACT_APP_IN_APPLICATION == "true") {
        await this.fetchServiceHour();
     }

    await this.fetchServiceHour();
    /**
     * displayBanner
     */
    try {
      await this.displayBanner();
    } catch (error) {
      console.error(error);
    }

    

    var tmpVersion = localStorage.getItem("version");
    if (localStorage.hasOwnProperty("isSessionStorageSet")) {
      tmpVersion = localStorage.getItem("version");
      localStorage.clear();
      sessionStorage.clear();
      localStorage.setItem("version", tmpVersion);
    }

    if (localStorage.hasOwnProperty("timeoutForSessionStorageReset")) {
      var lastMessageTimestamp = localStorage.getItem("timeoutForSessionStorageReset");
      if (new Date().getTime() - Number(lastMessageTimestamp) >= 900000) {
        //if((new Date().getTime() - Number(lastMessageTimestamp)) >= 90)
        tmpVersion = localStorage.getItem("version");
        localStorage.clear();
        sessionStorage.clear();
        localStorage.setItem("version", tmpVersion);
      }
    }

    /****
     * this will be true if user started password reset or forgot username
     */
    // console.log("hasPiiInConversation: " + this.hasPiiInConversation());
    if (this.hasPiiInConversation() || this.didUserNavigateToSb260() || this.didUserNavigateFromSb260()) {
      console.log("clearing browser storage");
      this.clearBrowserStorage();
    }

    /**
     * Once sb260 page check is done, set previousPage to current page
     */
    previousPageRepo.setPreviousPage(this.isSb260Page() ? "sb260" : "nonSb260");

    /**
     * handle open cici through url params
     */
    if (this.isAutoTrigger()) {
      const autoTriggerIntent = this.getAutoTriggerIntent();
      this.pushAutoTriggerIntentToState(autoTriggerIntent);
      await this.handleOpen();
    }

    var tmpLiveChatSession = localStorage.getItem("liveChatSession");
    //console.log(tmpLiveChatSession)
    if (tmpLiveChatSession !== "true") {
      this.setState({
        isInitialized: false,
      });
    } else if (!this.state.isInitialized) {
      this.initiateCici();
      this.setState({
        isInitialized: true,
      });
    }


    // this.setState({
    //   isOpenSb260Wizard: true,
    //   sb260Event: {
    //     ...Sb260EventDataMocks.SHOW_SERVICE_UNAVAILABLE_ERROR,
    //     data: {
    //       ...Sb260EventDataMocks.SHOW_SERVICE_UNAVAILABLE_ERROR.data,
    //       plan: "western"
    //     }
    //   }
    // });
    //this.handleOpen();
  }

  componentDidUpdate = async (prevProps, prevState) => {

    /**
     * Auto Trigger
     */
    if (this.state.directlineObject && this.state.autoTriggerIntents.length > 0 && this.state.isConnectedToBotserviceThroughDirectline) {
      const autoTriggerIntents = [...this.state.autoTriggerIntents];
      const currentAutoTriggerIntent = autoTriggerIntents.pop();
      if (currentAutoTriggerIntent === "Affordability Tool") {
        this.triggerModal();
      }else if(currentAutoTriggerIntent === "Enrollment Status"){
        this.triggerModalEnrollmentStatus();
      } else {
        this.state.store.dispatch({
          meta: {
            method: "keyboard"
          },
          type: 'DIRECT_LINE/POST_ACTIVITY',
          payload: {
            activity: {
              type: "event",
              value: {
                autoTriggerIntent: currentAutoTriggerIntent
              },
              locale: document.documentElement.lang,
              from: { id: "me" },
              name: "autoTrigger",
              pageUrl: window.location.pathname,
              gaId: getGaId()
            }
          }
        });
      }
      this.setState((prevState) => ({
        autoTriggerIntents: autoTriggerIntents
      }));
    }

    // checking if directline object changed and set
    //console.log(this.state)
    if (prevState.directlineObject !== this.state.directlineObject && this.state.directlineObject) {
      if (this.state.open) {
        if (this.isNewSession()) {
          this.welcomeUser();
        }
      }
      //console.log("Inside component did mount!")
    }

    /**
     * Once conversationId has been set in state, initialize the startWaterMark state
     */
    if (prevState.conversationId !== this.state.conversationId && this.state.conversationId && !this.state.startWaterMark) {
      this.setState({
        startWaterMark: watermarkRepo.initialize(this.state.conversationId)
      });
    }

    if(prevState.open !== this.state.open && this.state.open === true){
      this.determineLocale();
    }

    /**
     * setup auto trigger through UI events
     */
    const chatbot = document.getElementById("chatbot-root");
    chatbot.removeEventListener('autoTriggerCustomEvent', this.autoTriggerCustomEventHandler);
    chatbot.addEventListener('autoTriggerCustomEvent', this.autoTriggerCustomEventHandler);
  };

  autoTriggerCustomEventHandler = (event) => {
    if (event.detail && event.detail.intent && event.detail.intent === "affordability-calculator") {
      this.handleOpen();
      this.setState({ isOpenAffordabilityTool: true, isATWarningActive: false });
    }
  }

  /***
   * returns a createStore that's been setup to process some actions
   */
  getCreateStore() {
    return createStore({}, ({ dispatch }) => next => action => {
      return this.processActionFromReduxMiddleware(next, action);
    });
  }

  /***
   * middleware to process actions from redux
   *  - everything that happens in the chat window comes through as an action in the redux middleware
   *  - we should make this function to be as high level as possible. Try not to include too many implementation details
   */
  processActionFromReduxMiddleware(next, action) {
    /**
     * create an Action from redux store action
     */
    const actionFromReduxStore = new Action(action);

    /**
     * determines whether front end isConnectedToBotserviceThroughDirectline
     * used by:
     *    auto trigger functionality
     */
    if (actionFromReduxStore.isUpdateForDirectlineConnectionSuccess()) {
      this.setState({ isConnectedToBotserviceThroughDirectline: true });
    }

    /**
     * Update watermark when actions with activity Id goes through redux middleware
     */
    if (actionFromReduxStore.hasActivity() && actionFromReduxStore.getActivity().hasId() && actionFromReduxStore.getActivity().isNew(this.state.startWaterMark)) {
      const activity = actionFromReduxStore.getActivity();
      watermarkRepo.update(activity.getConversationId(), activity.getWatermark());
    }

    if (action && action.payload && action.payload.activity && action.payload.activity.type === "event" && action.payload.activity.name === "logEvent" && action.payload.activity.from && action.payload.activity.from.role && action.payload.activity.from.role === 'bot') {
      const currentWaterMark = parseInt(action.payload.activity.id.split("|")[1]);
      if (currentWaterMark > this.state.startWaterMark) {
        sendEventToDataLayer(activityToDataLayerEventMapping[action.payload.activity.value.event]);
      }

    }

    if (action && action.payload && action.payload.activity && action.payload.activity.text === "My Medi-cal is ending|||||suggestedAction" && this.isPostActivity(action.type)) {
      sendEventToDataLayer({
        event: "chatbot",
        eventDetails: {
          name: "cici_mct_medi_cal_is_ending"
        }
      });
    }

    if (action && action.payload && action.payload.activity && action.payload.activity.text === "Manage my move|||||suggestedAction" && this.isPostActivity(action.type)) {
      sendEventToDataLayer({
        event: "chatbot",
        eventDetails: {
          name: "cici_mct_manage_my_move_menu"
        }
      });
    }

    if (action && action.payload && action.payload.activity && action.payload.activity.text === "Contact support|||||suggestedAction" && this.isPostActivity(action.type)) {
      sendEventToDataLayer({
        event: "chatbot",
        eventDetails: {
          name: "cici_mct_contact_support"
        }
      });
    }

    ////////////////////////////////////////////////
    //////////// May 19 - June 19 Only ////////////
    ////////////////////////////////////////////////
    if (action && action.payload && action.payload.activity && action.payload.activity.text === "What are my choices?|||||suggestedAction" && this.isPostActivity(action.type)) {
      sendEventToDataLayer({
        event: "chatbot",
        eventDetails: {
          name: "cici_mct_what_are_my_choices_menu"
        }
      });
    }

    if (action && action.payload && action.payload.activity && action.payload.activity.text === "Keep my plan|||||suggestedAction" && this.isPostActivity(action.type)) {
      sendEventToDataLayer({
        event: "chatbot",
        eventDetails: {
          name: "cici_mct_keep_my_plan"
        }
      });
    }

    if (action && action.payload && action.payload.activity && action.payload.activity.text === "Cancel my plan|||||suggestedAction" && this.isPostActivity(action.type)) {
      sendEventToDataLayer({
        event: "chatbot",
        eventDetails: {
          name: "cici_mct_cancel_my_plan"
        }
      });
    }

    if (action && action.payload && action.payload.activity && action.payload.activity.text === "Change my plan|||||suggestedAction" && this.isPostActivity(action.type)) {
      sendEventToDataLayer({
        event: "chatbot",
        eventDetails: {
          name: "cici_mct_change_my_plan"
        }
      });
    }

    if (action && action.payload && action.payload.activity && action.payload.activity.text === "Manage my move|||||suggestedAction" && this.isPostActivity(action.type)) {
      sendEventToDataLayer({
        event: "chatbot",
        eventDetails: {
          name: "cici_mct_manage_my_move_menu"
        }
      });
    }

    if (action && action.payload && action.payload.activity && action.payload.activity.text === "Contact support|||||suggestedAction" && this.isPostActivity(action.type)) {
      sendEventToDataLayer({
        event: "chatbot",
        eventDetails: {
          name: "cici_mct_contact_support"
        }
      });
    }

    ////////////////////////////////////////////////
    //////////// May 19 - June 19 Only ////////////
    ////////////////////////////////////////////////
    if (action && action.payload && action.payload.activity && action.payload.activity.text === "What are my choices?|||||suggestedAction" && this.isPostActivity(action.type)) {
      sendEventToDataLayer({
        event: "chatbot",
        eventDetails: {
          name: "cici_mct_what_are_my_choices_menu"
        }
      });
    }

    if (action && action.payload && action.payload.activity && action.payload.activity.text === "Keep my plan|||||suggestedAction" && this.isPostActivity(action.type)) {
      sendEventToDataLayer({
        event: "chatbot",
        eventDetails: {
          name: "cici_mct_keep_my_plan"
        }
      });
    }

    if (action && action.payload && action.payload.activity && action.payload.activity.text === "Cancel my plan|||||suggestedAction" && this.isPostActivity(action.type)) {
      sendEventToDataLayer({
        event: "chatbot",
        eventDetails: {
          name: "cici_mct_cancel_my_plan"
        }
      });
    }

    if (action && action.payload && action.payload.activity && action.payload.activity.text === "Change my plan|||||suggestedAction" && this.isPostActivity(action.type)) {
      sendEventToDataLayer({
        event: "chatbot",
        eventDetails: {
          name: "cici_mct_change_my_plan"
        }
      });
    }

    ////////////////////////////////////////////////
    ////////////////////////////////////////////////
    ////////////////////////////////////////////////

    /**
     * Sb260 Feature Integration
     * action has an activity
     * activity is an event
     * event has a name of {sb260Config.EVENT_NAME}
     * event was sent by the bot
     */
    if (this.isSb260TriggerAction(actionFromReduxStore)) {
      // sb260-feature: todo -> make sure that if cici loads the previous conversation on load that the wizard doesnt start.
      this.setState({
        isOpenSb260Wizard: true,
        sb260Event: {
          name: action.payload.activity.value.name,
          data: action.payload.activity.value.data
        }
      });
    }

    if (this.isEnrollmentStatusTrigger(actionFromReduxStore))  {
      // sb260-feature: todo -> make sure that if cici loads the previous conversation on load that the wizard doesnt start.
      this.setState({
        isOpenEnrollmentStatusWizard: true,
        enrollmentStatusEvent: {
          name: action.payload.activity.value.name,
          data: action.payload.activity.value.data
        }
      });
    }
    
    if (this.isActivityToTriggerAffordabilityTool(action) || (actionFromReduxStore.hasActivity() && actionFromReduxStore.getActivity().isEvent() && actionFromReduxStore.getActivity().getEventName() === "affordabilitytool" && actionFromReduxStore.getActivity().isNew(this.state.startWaterMark))) {
      this.triggerModal();
      return;
    }

    if (this.isActivityToTriggerEnrollmentStatus(action)){
      this.triggerModalEnrollmentStatus();
      return;
    }

    if (this.isPostActivity(action.type)) {
      // https://stackoverflow.com/questions/59236966/send-custom-data-to-ms-bot-from-web-chat-directline
      action = this.addGaIdAndPageUrlToActionPayloadActivity(action);
    }
    return next(action);
  }

  /**
   * Checks if action is trigger for SB260 Wizard
   * @param {*} action: Action 
   * @returns boolean
   */
  isSb260TriggerAction = (action) => {
    if (!action.hasActivity()) return false;
    if (!action.getActivity().isEvent()) return false;
    if (!action.getActivity().hasEventName()) return false;
    if (action.getActivity().getEventName() !== sb260Config.EVENT_NAME) return false;
    if (!action.getActivity().isFromBot()) return false;
    //if (!action.getActivity().isNew(this.state.startWaterMark)) return false;
    return true;
  }

  isEnrollmentStatusTrigger = (action) =>{
    if (!action.hasActivity()) return false;
    if (!action.getActivity().isEvent()) return false;
    if (!action.getActivity().hasEventName()) return false;
    if (action.getActivity().getEventName() !== enrollmentStatusConfig.EVENT_NAME) return false;
    if (!action.getActivity().isFromBot()) return false;
    //if (!action.getActivity().isNew(this.state.startWaterMark)) return false;
    return true;

  }

  handleBannerStateChange = (isOpen) => {
    this.setState({
      isBannerOpen: isOpen,
    });
  };

  determineLocale = () => {
    const cookieValue = document.cookie;
    const locale = cookieValue
      .split(";")
      .map((item) => item.trim())
      .find((item) => item.startsWith("ahbx_locale="));

    if (locale) {
      const localeValue = locale.split("=")[1];
      this.setState({ localeValue });
    } else {
      const defaultLang = document.documentElement.lang || "en";
      this.setState({ localeValue: defaultLang });
    }
  };

  /**
   * bubbleLinkClickHandler
   */
  bubbleLinkClickHandler = () => {
    const clickEvent = document.getElementById("chatbot-root");
    clickEvent.addEventListener("click", (event) => {
      if (event.target.tagName.toLowerCase() === "a") {
        var currentElement = event.target.parentElement;
        while (currentElement && currentElement.className.indexOf("some-div") === -1) {
          currentElement = currentElement.parentElement;
        }
        if (currentElement) {
          sendEventToDataLayer({
            event: "chatbot",
            eventDetails: {
              intentName: currentElement.getAttribute("data-intentname"),
              name: "cici_sb260_manage_my_move_link_click"
            }
          })
        }
      }
    });
  }

  /**
   * displayBanner
   * - display banner based on .com outage data file ciciBanner.json
   */
  displayBanner = async () => {
    let url;
    if (process.env.NODE_ENV === 'development') {
      url = 'https://charming-mallard.cloudvent.net/ciciBanner.json';
         } else {
      url = `${window.location.origin}/ciciBanner.json`;
    }
    const response = await fetch(url); 
        const responseText = await response.text();
    const data = JSON.parse(responseText);
    let activeBanner;
    if (document.documentElement.lang === 'en') {
      activeBanner = Array.isArray(data) && data.find((item, index) => item.active && index === 0);
    } else if (document.documentElement.lang === 'es') {
      activeBanner = Array.isArray(data) && data.find((item, index) => item.active && index === 1);
    }
    this.setState({
      bannerData: data,
      activeBanner: activeBanner,
      isBannerOpen: activeBanner && activeBanner.active ? true : false,
    });
  }

  /**
   * Fetch service hour
   */
  fetchServiceHour = async () => {
    try {
      let url1;
      if (process.env.NODE_ENV === 'development') {
        url1 = 'https://spring-coconut.cloudvent.net/api/hours.json';
           } 
      else {
        url1 = `https://www.coveredca.com/api/hours.json`;
      }

      const response = await fetch(url1);
      const data = await response.json();
      const lang = document.documentElement.lang;
      const result = isServiceOpen(data, lang);
            this.setState({
        isServiceHour: result,
        serviceHourData: data,
      });
      } catch (error) {
      console.error('Error fetching service data: ', error);
    }
  };

  openATHelpModal = (value) => {
    this.setState({
      isOpenHelpModal: value,
    });
  }
  /**
   * check if activity is a POST_ACTIVITY or DIRECT_LINE/POST_ACTIVITY
   */
  isPostActivity(actionType) {
    if (actionType === 'DIRECT_LINE/POST_ACTIVITY' || actionType === 'POST_ACTIVITY') {
      return true;
    }
    return false;
  }

  /***
   * check if the action is an activity that is meant to trigger the tool (contains ideal utterance for affordability tool)
   */
  isActivityToTriggerAffordabilityTool(action) {
    const activityTextAffordabilityToolTrigger = document.documentElement.lang === "es" ? "Herramienta de asequibilidad|||||suggestedAction" : "Affordability Tool|||||suggestedAction";
    if (!this.isPostActivity(action.type)) {
      return false;
    }
    if (action.payload.activity.text !== activityTextAffordabilityToolTrigger) {
      return false;
    }
    return true;
  }

  isActivityToTriggerEnrollmentStatus(action) {
    const activityTextEsTrigger = document.documentElement.lang === "es" ? "Herramienta de asequibilidad|||||suggestedAction" : "Check Enrollment status|||||suggestedAction";
    if (!this.isPostActivity(action.type)) {
      return false;
    }
    if (action.payload.activity.text !== activityTextEsTrigger) {
      return false;
    }
    return true;
  }

  //Adding Device type 
  getDeviceType() {
    const width = window.innerWidth;
    if (width > 1200) {
      return 'desktop';
    } else if (width > 768) {
      return 'tablet';
    } else {
      return 'mobile';
    }
  }

  /**
   * addGaIdAndPageUrlToActionPayloadActivity
   */
  addGaIdAndPageUrlToActionPayloadActivity(action) {
    // this is to catch post activities that contains text - we do this to sort out suggested action clicks
    // if the text contains ||||| then the post activity was generated via clicking suggested action.

    if (action && action.payload && action.payload.activity && action.payload.activity.text) {
      if (action.meta.method === "imBack") {
        const isFromWelcomeMessage = true
        const textArray = action.payload.activity.text.split("|||||");
        const text = textArray[0];
        const isSuggestedAction = !!textArray[1];
        const deviceType = this.getDeviceType()
        return { ...action, payload: { ...action.payload, activity: { ...action.payload.activity, text, pageUrl: window.location.pathname, gaId: getGaId(), isSuggestedAction, isFromWelcomeMessage, deviceType } } }
      }
      else {
        const textArray = action.payload.activity.text.split("|||||");
        const text = textArray[0];
        const isSuggestedAction = !!textArray[1];
        const isFromWelcomeMessage = false
        const deviceType = this.getDeviceType()
        return { ...action, payload: { ...action.payload, activity: { ...action.payload.activity, text, pageUrl: window.location.pathname, gaId: getGaId(), isSuggestedAction, isFromWelcomeMessage, deviceType } } }
      }
    }

    return { ...action, payload: { ...action.payload, activity: { ...action.payload.activity, pageUrl: window.location.pathname, gaId: getGaId() } } }
  }

  /**
     * triggerModal
     */
  triggerModal(modalToTrigger) {
    const urlArray = window.location.href.split("?");
    const lang = document.documentElement.lang;
    let payload = {
      intentName: lang === "en" ? "affordabilityToolOpenedThroughMenu" : "affordabilityToolOpenedThroughMenuSpanish",
      text: lang === "en" ? "Affordability Tool Opened Through Menu" : "Affordability Tool Opened Through Menu Spanish"
    }
    if (urlArray.includes("open-cici=affordabilityTool")) {
      payload = {
        intentName: lang === "en" ? "affordabilityToolOpenedThroughUrl" : "affordabilityToolOpenedThroughUrlSpanish",
        text: lang === "en" ? "Affordability Tool Opened Through URL" : "Affordability Tool Opened Through URL Spanish"
      }
    }
    if(process.env.REACT_APP_IN_APPLICATION === "true"){
      let locale = getLocaleValue();
      payload = {
        intentName: locale === "en" ? "affordabilityToolOpenedIn-App" : "affordabilityToolOpenedIn-AppSpanish",
        text: locale === "en" ? "Affordability Tool Opened In-App" : "Affordability Tool Opened In-App Spanish"
      }
    }

    // #affordability-tool-logging
    sendEventToDataLayer({
      event: "chatbot",
      eventDetails: {
        category: "CiCi",
        action: "Affordability Tool Clicks",
        label: "Affordability Tool Started"
      }
    });
    setTimeout(() => {
      this.sendLoggingEventToBackend(payload);
    }, 1000);
    this.setState({ isOpenAffordabilityTool: true });
  }

  triggerModalEnrollmentStatus(modalToTrigger) {
    const urlArray = window.location.href.split("?");
    const lang = document.documentElement.lang;
    let payload = {
      intentName: lang === "en" ? "EnrollmentStatusOpenedThroughMenu" : "EnrollmentStatusOpenedThroughMenuSpanish",
      text: lang === "en" ? "Enrollment Status Opened Through Menu" : "Enrollment Status Opened Through Menu Spanish"
    }
    if (urlArray.includes("open-cici=enrollmentStatus")) {
      payload = {
        intentName: lang === "en" ? "EnrollmentStatusOpenedThroughUrl" : "EnrollmentStatusOpenedThroughUrlSpanish",
        text: lang === "en" ? "Enrollment Status Opened Through URL" : "Enrollment Status Opened Through URL Spanish"
      }
      setTimeout(() => {
        this.sendLoggingEventToBackend(payload);
      }, 1000);
      this.setState({ isOpenEnrollmentStatusWizard: true });
    }
    
    }
  /**
   * isAutoTriggerForModal 
   */
  isAutoTriggerForModal = (autoTriggerIntent) => {
    switch (autoTriggerIntent) {
      case 'affordabilityTool':
        return true;
      case 'enrollmentStatus':
        return true;
      default:
        return false;
    }
  }

  /**
   * Sends message 
   */
  sendMessage = (text) => {
    this.state.store.dispatch({
      meta: {
        method: "keyboard"
      },
      type: 'DIRECT_LINE/POST_ACTIVITY',
      payload: {
        activity: {
          type: "message",
          text: text,
          locale: document.documentElement.lang,
          pageUrl: window.location.pathname,
          gaId: getGaId()
        }
      }
    });
  }

  /****
   * sends an event to the backend to log frontend events from the backend (CosmosDb/Dashbot)
   */
  sendLoggingEventToBackend = (payload) => {
    if (payload.intentName === "Providefeedback-MCT" || payload.intentName === "EnrollmentStatusFeedback") {
      this.state.store.dispatch({
        meta: {
          method: "keyboard"
        },
        type: 'DIRECT_LINE/POST_ACTIVITY',
        payload: {
          activity: {
            type: "event",
            name: "logEvent",
            value: {
              intent: {
                name: payload.intentName || "undefined",
                text: payload.text || "undefined"
              },
              data: {
                rating: payload.rating,
                comment: payload.comment
              }
            }
          }
        }
      });
    }
    else {
      this.state.store.dispatch({
        meta: {
          method: "keyboard"
        },
        type: 'DIRECT_LINE/POST_ACTIVITY',
        payload: {
          activity: {
            type: "event",
            name: "logEvent",
            value: {
              intent: {
                name: payload.intentName || "undefined",
                text: payload.text || "undefined"
              },
              locale: getLocaleValue()
            }
          }
        }
      });
    }
  }


  sendEventToBackend = (name, value)=>{
    this.state.store.dispatch({
      meta: {
        method: "keyboard"
      },
      type: 'DIRECT_LINE/POST_ACTIVITY',
      payload: {
        activity: {
          type: "event",
          name: name,
          value: value,
          }
        }
    });

  }

  /**
   * sb260 update 
   */
  isSb260Page = () => {
    const url_string = window.location.href;
    if (url_string.indexOf('new-plan') > -1) {
      return true;
    }
    return false;
  }

  /**
   * didUserNavigateFromSb260 checks if user navigated from sb260 page.
   * - user loaded sb260 page and talked to cici, then user went to the homepage, then opened cici
   * - should return true;
   */
  didUserNavigateFromSb260 = () => {
    if (!this.isSb260Page() && previousPageRepo.getPreviousPage() === 'sb260') {
      console.log("user navigated from sb260 page.");
      return true;
    }
    return false;
  }

  didUserNavigateToSb260 = () => {
    if (this.isSb260Page() && previousPageRepo.getPreviousPage() !== 'sb260') {
      console.log("user navigated to sb260 page.");
      return true;
    }
    return false;
  }

  /**
   * isAutoTrigger
   * - returns true if url parameter "cici-open" is in the URL
   */
  isAutoTrigger = () => {
    var url_string = window.location.href;
    var url = new URL(url_string);
    var openCiciCommand = "open-cici";
    var intentName = url.searchParams.get(openCiciCommand);
    if (intentName) {
      return true;
    }
    return false;
  }

  /**
   * getAutoTriggerIntent
   * - retrieves the intentName that user wants to auto trigger from the URL
   * - ex. url?cici-open=passwordReset will return passwordReset
   */
  getAutoTriggerIntent = () => {
    var url_string = window.location.href;
    var url = new URL(url_string);
    var openCiciCommand = "open-cici";
    var intentName = url.searchParams.get(openCiciCommand);
    if (intentName) {
      return intentName;
    }
    return null;
  }

  /**
   * pushAutoTriggerIntentToState 
   * - pushes the intentname that user wants to auto trigger into state.
   * - once the intent has been pushed to state, componentDidUpdate will run.
   */
  pushAutoTriggerIntentToState = (intentName) => {
    if (!intentName) {
      return;
    }
    console.log(intentName)
    let finalIntentName;
    if (intentName === 'true') {
      finalIntentName = "forgot password"
    } else if (intentName === 'affordabilityTool') {
      finalIntentName = "Affordability Tool"
    }else if (intentName === 'enrollmentStatus') {
      console.log(intentName)
      finalIntentName = "Enrollment Status"
      console.log(finalIntentName)
    } else {
      finalIntentName = intentName;
    }
    this.setState((prevState) => ({
      autoTriggerIntents: [...prevState.autoTriggerIntents, finalIntentName]
    }));
  }

  loadIdealQuestions = () => {
    // console.log('loading IdealQuestions');
    fetch(configURLs.url.API_URL + "/api/getIdealQuestions/" + this.state.lang, { method: "GET" })
      .then((res) => {
        if (!res.ok) {
          throw Error({ errorMessage: "failed to fetch idealQuestions" });
        }
        return res.json();
      })
      .then((data) => {
        // console.log('idealQuestions loaded');
        this.setState((prevState) => ({
          idealQuestions: data,
          idealQuestionsLoaded: true,
          autoCompleteLoadAttempts: prevState.autoCompleteLoadAttempts + 1,
        }));
      })
      .catch((err) => {
        console.log("the error: " + err);
        // console.log('idealQuestions errored out');
        this.setState((prevState) => ({
          autoCompleteLoadAttempts: prevState.autoCompleteLoadAttempts + 1,
        }));
      });
  };

  setUpAutoComplete = () => {
    if (!this.autoCompleteLoaded) {
      let autoComplete = new AutoComplete();
      autoComplete.setupAutoComplete(this.state.idealQuestions.idealQuestions, this.state.store, this.state.lang);
      this.setState((prevState) => ({
        autoCompleteLoaded: true,
      }));
    }
  };

  CiciHasBeenInitialized = () => {
    let ciciInitialized = localStorage.getItem("ciciInitialized");
    if (ciciInitialized.toString() !== "null" || !ciciInitialized);
  };

  // This can be in a different file
  isNewSession = () => (localStorage.getItem("isWelcomed") !== "true" || !localStorage.getItem("isWelcomed"));

  // This can be in a different file
  languageSwitched = () => {
    let documentLanguage = document.documentElement.lang;
    let storageLanguage = localStorage.getItem("lang") || sessionStorage.getItem("lang");

    if (documentLanguage !== storageLanguage) {
      return true;
    }
    return false;
  };

  createDirectlineObject = async (token) => {
    // console.log('in createDirectlineObject()');
    if (process.env.NODE_ENV === 'development') {
      //return new DirectLine({ token: token });
      return new DirectLine({ token: token, domain: 'https://glorious-couscous-x5vrq7x47pj3pxpv-3002.app.github.dev/directline', webSocket: false });
    } else {
      return new DirectLine({ token: token });
    }
  };

  // get a token by calling getToken in the node server
  getToken = async () => {
    // console.log('in getToken()');
    if (this.BroswerStorageIsSet() && !this.state.languageSwitched) {
      // console.log('calling refreshToken()');
      return await this.refreshToken(localStorage.getItem("token"));
    } else {
      // console.log('calling requestForToken()');
      this.clearBrowserStorage();
      return await this.requestForToken();
    }
  };

  BroswerStorageIsSet = () => {
    if (this.LocalStorageIsSet() || this.sessionStorageIsSet()) {
      // console.log('broswer storage is set');
      return true;
    } else {
      // console.log('browser storage is not set');
      return false;
    }
  };

  LocalStorageIsSet = () => {
    let ciciLocalStorage = localStorage.getItem("conversationId");
    if (ciciLocalStorage !== "" && ciciLocalStorage !== null && ciciLocalStorage !== undefined) {
      // console.log('localStorageIsSet');
      return true;
    } else {
      // console.log('localStorage is not set');
      return false;
    }
  };

  sessionStorageIsSet = () => {
    let ciciSessionStorage = sessionStorage.getItem("conversationId");
    if (ciciSessionStorage !== "" && ciciSessionStorage !== null && ciciSessionStorage !== undefined) {
      // console.log('sessionStorageIsSet');
      return true;
    } else {
      // console.log('sessionStorage is not set');
      return false;
    }
  };

  clearBrowserStorage = () => {
    // console.log("in clearBrowserStorage");
    localStorage.removeItem("conversationId");
    localStorage.removeItem("streamUrl");
    localStorage.removeItem("token");
    localStorage.removeItem("isWelcomed");
    localStorage.removeItem("conversationMayContainPii");

    sessionStorage.removeItem("conversationId");
    sessionStorage.removeItem("streamUrl");
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("isWelcomed");
    sessionStorage.removeItem("thumbsDownCount");

  };

  requestForToken = async () => {
    let version =
      (document.body.getAttribute("data-version") === null ? false : document.body.getAttribute("data-version")) ||
      localStorage.getItem("version") ||
      sessionStorage.getItem("version");
    let routeString = configURLs.url.API_URL + "/getToken/";
    if (version && (version === "live-chat" || version === "c")) {
      routeString = configURLs.url.API_URL + "/getToken/c/";
      // console.log('requestForToken: version b');
    } else {
      // console.log('requestForToken: version a');
    }
    let tokenPromise = await fetch(routeString);
    let token = await tokenPromise.json();
    // console.log(token);
    return token;
  };

  refreshToken = async () => {
    // console.log('in refreshToken()');
    let conversationId = localStorage.getItem("conversationId") || sessionStorage.getItem("conversationId");
    let botVersion =
      JSON.stringify(localStorage.getItem("version")) || JSON.stringify(sessionStorage.getItem("version"));

    let refreshTokenRouteString = configURLs.url.API_URL + "/refreshToken/" + conversationId;

    if (botVersion && botVersion === JSON.stringify("c")) {
      refreshTokenRouteString = configURLs.url.API_URL + "/refreshToken/" + conversationId + "/c";
      // console.log('refreshToken: version b');
      // console.log(refreshTokenRouteString);
    } else {
      // console.log('refreshToken: version a');
    }

    // console.log('conversationId ' + conversationId);
    let tokenPromise = await fetch(refreshTokenRouteString);
    let token = await tokenPromise.json();
    let preparedToken = {
      token: token.token,
      conversationId: token.conversationId,
      streamUrl: token.streamUrl,
      version: token.version,
    };
    // Check if able to connect, if false try requstForToken again
    if (token.hasOwnProperty("error")) {
      preparedToken = await this.requestForToken();
      localStorage.setItem("isWelcomed", false);
      sessionStorage.setItem("isWelcomed", false);
    }
    return preparedToken;
  };

  // when the request from getToken comes back save the values in localStorage and sessionStorage
  saveTokenInBrowserStorage = (conversationId, token, streamUrl, isWelcomed, botVersion) => {
    // console.log('in saveTokenInBrowserStorage');
    // console.log(botVersion);
    // localStorage.setItem('sessionStart', "inProgress");
    localStorage.setItem("conversationId", conversationId);
    localStorage.setItem("token", token);
    localStorage.setItem("streamUrl", streamUrl);
    localStorage.setItem("version", botVersion);
    localStorage.setItem("isWelcomed", isWelcomed);
    //sessionStorage.setItem('sessionStart', "inProgress");
    sessionStorage.setItem("conversationId", conversationId);
    sessionStorage.setItem("token", token);
    sessionStorage.setItem("streamUrl", streamUrl);
    sessionStorage.setItem("version", botVersion);
    sessionStorage.setItem("isWelcomed", isWelcomed);

    localStorage.setItem("lang", document.documentElement.lang);
    sessionStorage.setItem("lang", document.documentElement.lang);

    localStorage.setItem("messagesSent", 0);
    sessionStorage.setItem("messagesSent", 0);
    sessionStorage.setItem("thumbsDownCount", 0);
  };

  getLang = () => (document.documentElement.lang === "es") ? "es" : "en";

  getUserId = () => this.state.conversationId;

  getUserName = () => (document.documentElement.lang === "es") ? "tu" : "You";

  handleMinimize = () => {
    this.setState({
      open: false,
    });
    this.removeActiveClassRootNode();
    // console.log('minimized cici');
    localStorage.removeItem("trendingTopicsShown");
  };

  addActiveClassRootNode = () => {
    const root = document.getElementById("chatbot-root");
    const ciciContainer = root.getElementsByClassName('cici')[0];
    root.classList.add("chatbot-root--active");
    ciciContainer.focus();
  };

  removeActiveClassRootNode = () => {
    const root = document.getElementById("chatbot-root");
    root.classList.remove("chatbot-root--active");
  };

  getCiciMinimizedButtonImage = () => configURLs.url.BASE_URL + "/images/botImages/chatbot_bot_icon.svg";

  createListeners = () => {
    //if (performance.navigation.type !== 1)
    //{
    var tmpTabCount = localStorage.getItem("tabCount");
    localStorage.setItem("tabCount", tmpTabCount === undefined ? 1 : tmpTabCount + 1);
    //}

    //tmpTabCount = localStorage.getItem('tabCount')
    //console.log(tmpTabCount)
    let deleteBrowserStorageItems = this.deleteBrowserStorageItems;

    const _this = this;

    window.onbeforeunload = function (e) {
      if (_this.hasPiiInConversation()) {
        sendEventToDataLayer({
          event: "dotComClick",
          eventDetails: {
            category: "CiCi",
            action: "Transactional Flow",
            label: "User Closed or Refreshed Page"
          }
        });
      }
      var tmpTabCount = localStorage.getItem("tabCount");
      var tmpLiveChatSession = localStorage.getItem("liveChatSession");

      if (
        tmpTabCount.length === 1 &&
        tmpLiveChatSession !== undefined &&
        tmpLiveChatSession !== null &&
        (document.activeElement.href === undefined ||
          document.activeElement.href.split(
            /\s*https*:\/\/((coveredca.com)|(localhost:3002)|(ccaldev1fullux.azurewebsites.net)|(uat-proxy.azurewebsites.net))\/*/gm
          ).length <= 1)
      ) {
        return "Are you sure you want to close this window";
      }
    };

    window.onunload = (e) => {
      var tmpTabCount = localStorage.getItem("tabCount");
      var tmpLiveChatSession = localStorage.getItem("liveChatSession");
      // if(performance.navigation.type == performance.navigation.TYPE_RELOAD)
      // {
      //   //do nothing
      // }
      if (
        tmpTabCount.length === 1 &&
        tmpLiveChatSession !== undefined &&
        tmpLiveChatSession !== null &&
        (document.activeElement.href === undefined ||
          document.activeElement.href.split(
            /\s*https*:\/\/((coveredca.com)|(localhost:3002)|(ccaldev1fullux.azurewebsites.net)|(uat-proxy.azurewebsites.net))\/*/gm
          ).length <= 1)
      ) {
        //console.log(document.activeElement.href)
        localStorage.removeItem("tabCount");
        localStorage.removeItem("liveChatSession");

        localStorage.setItem("isSessionStorageSet", true);

        // Call the function to send end chat message from user
        endLiveAgentButtonClick(this);
      }
      // else if(document.activeElement.href === undefined || document.activeElement.href.split(/\s*https*:\/\/((coveredca.com)|(localhost:3002)|(ccaldev1fullux.azurewebsites.net))\/*/gm).length <= 1)
      // {
      //   localStorage.removeItem('tabCount')
      //   localStorage.removeItem('liveChatSession')
      // }
      //else
      //{
      var resetSessionStorage = localStorage.getItem("isSessionStorageSet");
      if (tmpTabCount.length === 1 && resetSessionStorage === "true") {
        this.clearBrowserStorage();
      }

      tmpTabCount = tmpTabCount.slice(0, -1);
      localStorage.setItem("tabCount", tmpTabCount);
      //}
      deleteBrowserStorageItems();
    };

    //Listeners to end animation
    this.state.animateDiv.addEventListener("animationend", () => {
      this.state.animateDiv.classList.remove("animation");
    });
    this.state.animateDiv.addEventListener("animationcancel", () => {
      this.state.animateDiv.classList.remove("animation");
    });
  };

  deleteBrowserStorageItems = () => {
    localStorage.removeItem("sessionStart");
    localStorage.removeItem("conversationId");
    localStorage.removeItem("token");
    localStorage.removeItem("streamUrl");
    localStorage.removeItem("lang");
    localStorage.removeItem("trendingTopicsShown");
    localStorage.removeItem("didYouMeanCount");
    localStorage.removeItem("chatbotConversationWatermark");
  };

  isClickOutOfCici = (event) => {
    let parent = document.getElementById("cici");
    return !(parent.contains(event.target));
  };

  deactivateModals = () => {
    this.setState({
      isPrivacyActive: false,
      isHowToTalkToCiciActive: false,
      isLiveChatDisplayed: false,
      isATWarningActive: false,
    });
  };

  handlePrivacyClick = () => {
    // console.log('clicked privacy modal');
    this.setState({
      isPrivacyActive: true,
    });
    window.setTimeout(function () {
      document.getElementById("privacy__close").focus();
    }, 0);
  };

  handleATWarningClick = () => {
    this.setState({
      isATWarningActive: true,
      isAffordabilityToolExitOutsideOfCici: false
    });
    window.setTimeout(function () {
      document.getElementById("ATWarning__close").focus();
    }, 0);
    const lang = getLocaleValue();
    const intentName = lang === "en" ? "ExitModalInApp" : "ExitModalInAppSpanish"
    const text = lang === "en" ? "Exit Modal In app" : "Exit Modal In App Spanish"
    this.sendLoggingEventToBackend({
        intentName: intentName,
        text: text
    })
  };

  handleTalkToCiciClick = () => {
    // console.log('click how to talk to cici');
    this.setState({
      isHowToTalkToCiciActive: true,
    });
    window.setTimeout(function () {
      document.getElementById("talk-to-cici__close").focus();
    }, 0);
  };

  initiateCici = async () => {
    // console.log("initiate cici");
    // this creates a click listener to the body to close the modal,
    // also creates listener for animation end to stop notification bounce
    this.createListeners();

    // get the token from direcline api
    let tokenFromDirectline = await this.getToken();

    // use the token and create a directline object to be passed to ReactWebChat component
    let directlineObject = await this.createDirectlineObject(tokenFromDirectline.token);

    // save token, conversationId, streamUrl in local storage and browser storage
    this.saveTokenInBrowserStorage(
      tokenFromDirectline.conversationId,
      tokenFromDirectline.token,
      tokenFromDirectline.streamUrl,
      localStorage.getItem("isWelcomed"),
      tokenFromDirectline.version
    );

    // setting the directlineObject and conversationId states create the webchat.
    // the webchat may be hidden, but its on the page.
    this.setState({
      directlineObject: directlineObject,
      conversationId: tokenFromDirectline.conversationId,
      userId: this.getUserId(),
      lang: this.getLang(),
    });

    // push conversationId to dataLayer to track in google analytics
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "chatbot",
      chatbotValues: {
        conversationId: tokenFromDirectline.conversationId,
      },
    });

    // set up feedback timer
    //this.startTimerForFeedback();

    // initiate new message notification to 2 if CiCi has not been opened
    if (!this.state.welcomed) {
      // remove notification bubble because welcome image has one built in
      // this.setState({newMessages: 2});
      newMessageAnimation(this);
    }
  };

  handleOpen = async () => {
    if (this.state.open) {
      return;
    }

    if (!this.state.isInitialized) {
      this.initiateCici();
      this.setState({
        isInitialized: true,
      });
    }

    this.setState({ newMessages: 0 });

    this.setState({
      open: true,
    });

    if (this.state.welcomed !== true) {
      if (this.state.directlineObject !== null && this.isNewSession()) {
        this.welcomeUser();
      }
    }

    this.addActiveClassRootNode();
  };

  handleShowCiCi = async () => {
    this.setState({
      showCiCi: false,
    });
  };

  welcomeUser = () => {
    if (!this.isAutoTrigger()) {
      this.postAnActivity(this.state.directlineObject);
    }
    localStorage.setItem("isWelcomed", true);
    sessionStorage.setItem("isWelcomed", true);
    this.setState({
      welcomed: true,
    });
    // console.log('user was welcomed');
  };

  postAnActivity = (directlineObject) => {
    directlineObject
      .postActivity({
        type: "event",
        value: document.documentElement.lang,
        locale: getLocaleValue() || document.documentElement.lang,
        from: { id: "me" },
        name: "updateLanguage",
        pageUrl: window.location.pathname
      })
      .subscribe(
        (id) => {
          /*console.log(id)*/
        },
        (error) => {
          /*console.log('Error posting activity', error)*/
        }
      );
  };

  triggerFeedback = (directlineObject) => {
    directlineObject
      .postActivity({
        type: "event",
        value: document.documentElement.lang,
        locale: document.documentElement.lang,
        from: { id: "me" },
        name: "provideFeedback",
      })
      .subscribe(
        (id) => {
          /*console.log(id)*/
        },
        (error) => {
          /*console.log('Error posting activity', error)*/
        }
      );

    localStorage.removeItem("didYouMeanCount");
  };

  startTimerForFeedback = () => {
    if (this.state.directlineObject !== null) {
      let directline = this.state.directlineObject;
      let triggerFeedback = this.triggerFeedback;
      setTimeout(function () {
        triggerFeedback(directline);
      }, 3000);
    }
  };

  handleKeyPress = (event, handler) => {
    // console.log(event.charCode);
    if (event.charCode === 13 || event.charCode === 32) {
      handler();
      event.stopPropagation();
    }
  };

  handleHeaderDropdownClick = () => {
    this.setState({ headerDropdown: !this.state.headerDropdown });
  };

  // Handler for new message counter to give access to state
  handleNewMessageCount = () => {
    newMessageCount(this);
  };

  // Handlers for live agent timeouts to give access to state
  handleClearInactivityTimeout = () => {
    clearInactivityTimeout(this);
  };
  handleEndLiveAgentButtonClick = () => {
    endLiveAgentButtonClick(this);
  };
  handleSetInactivityTimeout = () => {
    setInactivityTimeout(this);
  };
  handleDeactivateLiveAgentTimeout = () => {
    deactivateLiveAgentTimeout(this);
  };

  handleShowEndChatButton = () => {
    showEndChatButton(this);
  };
  handleHideEndChatButton = () => {
    hideEndChatButton(this);
  };

  hasPiiInConversation = () => {
    const conversationMayContainPiiFlag = localStorage.getItem("conversationMayContainPii");
    if (conversationMayContainPiiFlag && JSON.parse(conversationMayContainPiiFlag)) {
      return true;
    }
    return false;
  }

  handleExistWithoutSaving = () => {
    this.setState({
      showCiCi: false,
      open: false,
      isOpenAffordabilityTool: false
    });
    this.removeActiveClassRootNode();
    localStorage.removeItem("trendingTopicsShown");
  }

  handleAffordabilityToolClickOutOfCici = () => {
    this.setState({
      isATWarningActive: true,
      isAffordabilityToolExitOutsideOfCici: true,
    })
    window.setTimeout(function () {
      document.getElementById("ATWarning__close").focus();
    }, 0);
  }

  isCiciClose = () => {
      if(this.state.open) return false;
      if(this.state.showCiCi) return false;
      return true;
  }

  render() {
    let privacyStatus = this.state.isPrivacyActive === true ? " ciciModal--active" : "";
    let talkToCiciStatus = this.state.isHowToTalkToCiciActive === true ? " ciciModal--active" : "";
    let liveAgentTimeoutStatus = this.state.isLiveAgentTimeoutActive === true ? " ciciModal--active" : "";
    let ATWarningStatus = this.state.isATWarningActive === true ? " ciciModal--active" : "";

    let liveAgentEndChatDisplayed = this.state.isLiveAgentEndChatButtonDisplayed === true ? " ciciModal--active" : "";

    //let LiveChatDisplayed = this.state.isLiveChatDisplayed === true ? ' ciciModal--active' : "";

    let minimizedCiciImage = this.getCiciMinimizedButtonImage();
    let minimizedCiciImageHover = configURLs.url.BASE_URL + "/images/botImages/chatbot_bot_icon_hover.svg";
    let showNewMessage = this.state.newMessages > 0 ? true : false;
    const styleOptions = {
      botAvatarImage: configURLs.url.BASE_URL + "/images/botImages/chatbot_bot_avatar.svg",
      botAvatarInitials: "BOT",
      botAvatarBackgroundColor: "#31C0D1",
      avatarSize: "30",
      hideUploadButton: true,
      rootHeight: "100%",
    };

    let talkBubbleMessage = messageGenerator(talkBubbleMessages[this.state.lang]);
    const lang = document.documentElement.lang;

    return (
      <>
      {this.state.open && this.state.isAffordabilityTool ? <ClearOverlay onClickHandler={this.handleAffordabilityToolClickOutOfCici} /> : null}
      <div id="cici" className="cici" tabIndex={0} onKeyPress={(e) => this.handleKeyPress(e, this.handleOpen)}>

        {this.state.directlineObject ? (
          <div className={this.state.open === true ? "cici__window cici__window--active" : "cici__window"}>

            <CiciModal
              status={privacyStatus}
              modalType={"privacy"}
              handleKeyPress={this.handleKeyPress}
              deactivateModals={this.deactivateModals}
              localeValue={this.state.localeValue}
            />
            <CiciModal
              status={talkToCiciStatus}
              modalType={"talk-to-cici"}
              handleKeyPress={this.handleKeyPress}
              deactivateModals={this.deactivateModals}
              localeValue={this.state.localeValue}
            />
            <CiciModal
              status={liveAgentTimeoutStatus}
              modalType={"live-agent-timeout"}
              handleKeyPress={this.handleKeyPress}
              deactivateModals={this.handleDeactivateLiveAgentTimeout}
              endLiveAgentButtonClick={this.handleEndLiveAgentButtonClick}
              localeValue={this.state.localeValue}
            />

            <CiciModal
              status={liveAgentEndChatDisplayed}
              localeValue={this.state.localeValue}
              modalType={"live-agent-end-chat"}
              handleKeyPress={this.handleKeyPress}
              endLiveAgentButtonClick={this.handleEndLiveAgentButtonClick}
            />
            
            {this.state.isATWarningActive ?
              <AffordabilityToolExitModal
                isFixed={this.state.isAffordabilityToolExitOutsideOfCici}
                closeHandler={this.deactivateModals}
                title={this.state.localeValue === "es" ? "Antes de que salgas." : "Before You Exit"}
                copy={this.state.localeValue === "es" ? "Si sales ahora, perderás toda la información que ingresaste." : "If you leave now, you\’ll lose any information you entered."}
                exitButtonText={this.state.localeValue === "es" ? "Salir de la herramienta sin guardar" : "Exit tool without saving"}
                exitHandler={this.handleExistWithoutSaving}
                returnButtonText={this.state.localeValue === "es" ? "Volver a la herramienta": "Return to tool"}
                returnHandler={this.deactivateModals}
              />
            : null}

            {true ?
              <ATHelpModal
                openATHelpModal={this.openATHelpModal}
                localeValue={this.state.localeValue}
                isOpenHelpModal={this.state.isOpenHelpModal}
                serviceHourData={this.state.serviceHourData}
                sendLoggingEventToBackend={this.sendLoggingEventToBackend}
              />
              :
              <ATHelpModalAfterServiceHour
                openATHelpModal={this.openATHelpModal}
                localeValue={this.state.localeValue}
                isOpenHelpModal={this.state.isOpenHelpModal}
                sendLoggingEventToBackend={this.sendLoggingEventToBackend}
                serviceHourData={this.state.serviceHourData}
              />}

            {this.state.isAffordabilityTool ?
              <AffordabilityToolHeader
                closeHandler={this.handleATWarningClick}
                headerText={this.state.localeValue === 'es' ? 'Herramienta de asequibilidad' : 'Affordability Tool' }
              />
              : <CiciHeader
                handlePrivacyClick={this.handlePrivacyClick}
                handleTalkToCiciClick={this.handleTalkToCiciClick}
                handleMinimize={this.handleMinimize}
                handleKeyPress={this.handleKeyPress}
                handleHeaderDropdownClick={this.handleHeaderDropdownClick}
                headerDropdown={this.state.headerDropdown}
                lang={this.state.lang}
                store={this.state.store}
                liveAgent={this.state.liveAgent}
              />}

            {this.state.activeBanner && (
              <Banner
                title={this.state.activeBanner.title}
                subtitle={this.state.activeBanner.subtitle}
                background="#FFCE56"
                onStateChange={this.handleBannerStateChange}
                active={this.state.isBannerOpen}
              />
            )}

            {this.state.isOpenAffordabilityTool ?
              (this.state.isAffordabilityTool ?
                <AffordabilityCalculatorModal
                  closeCici={this.handleExistWithoutSaving}
                  isCiciClose={this.isCiciClose()}
                  close={() => {
                    this.setState({ isOpenAffordabilityTool: false });
                    this.sendMessage((document.documentElement.lang === "es" ? 'Asequibilidad de la cobertura del empleador' : 'Employer-coverage affordability'));
                    // #affordability-tool-logging
                    sendEventToDataLayer({
                      event: "chatbot",
                      eventDetails: {
                        category: "CiCi",
                        action: "Affordability Tool Clicks",
                        label: "Affordability Tool Closed"
                      }
                    });
                    this.sendLoggingEventToBackend({
                      intentName: lang === "en" ? "affordabilityToolBackToMenu" : "affordabilityToolBackToMenuSpanish",
                      text: lang === "en" ? "Affordability Tool Back to Menu" : "Affordability Tool Back to Menu Spanish"
                    });
                  }}
                  onHelpHover={() => {
                    this.setState({ isOpenAffordabilityTool: true });
                    this.sendMessage((document.documentElement.lang === "es" ? 'Asequibilidad de la cobertura del empleador' : 'Employer-coverage affordability'));
                    // #affordability-tool-logging
                    sendEventToDataLayer({
                      event: "chatbot",
                      eventDetails: {
                        category: "CiCi",
                        action: "Affordability Tool Clicks",
                        label: "Affordability Tool Help Tool Tip"
                      }
                    });
                    this.sendLoggingEventToBackend({
                      intentName: lang === "en" ? "Affordability Tool Clicks" : "Affordability Tool Clicks Spanish",
                      text: lang === "en" ? "Affordability Tool Help Tool Tip" : "Affordability Tool Help Tool Tip Spanish"
                    });
                  }}
                  onHelpClick={() => {
                    this.setState({ isOpenAffordabilityTool: true });
                    this.sendMessage((document.documentElement.lang === "es" ? 'Asequibilidad de la cobertura del empleador' : 'Employer-coverage affordability'));
                    // #affordability-tool-logging
                    sendEventToDataLayer({
                      event: "chatbot",
                      eventDetails: {
                        category: "CiCi",
                        action: "Affordability Tool Clicks",
                        label: "Help"
                      }
                    });
                    this.sendLoggingEventToBackend({
                      intentName: lang === "en" ? "affordabilityToolHelpClick" : "affordabilityToolHelpClickSpanish",
                      text: lang === "en" ? "Affordability Tool Help Click" : "Affordability Tool Help Click Spanish"
                    });
                  }}
                  openATHelpModal={this.openATHelpModal}
                  contactUs={() => {
                    this.sendMessage('Contact Us');
                    this.setState({ isOpenAffordabilityTool: false });
                  }}
                  sendMessage={this.sendMessage}
                  sendLoggingEventToBackend={this.sendLoggingEventToBackend}
                  lang={document.documentElement.lang}
                  sendEventToBackend={this.sendEventToBackend}
                  localeValue={this.state.localeValue}
                /> :
                <CalculatorModal
                  close={() => {
                    this.setState({ isOpenAffordabilityTool: false });
                    this.sendMessage((document.documentElement.lang === "es" ? 'Asequibilidad de la cobertura del empleador' : 'Employer-coverage affordability'));
                    // #affordability-tool-logging
                    sendEventToDataLayer({
                      event: "chatbot",
                      eventDetails: {
                        category: "CiCi",
                        action: "Affordability Tool Clicks",
                        label: "Affordability Tool Closed"
                      }
                    });
                    this.sendLoggingEventToBackend({
                      intentName: lang === "en" ? "affordabilityToolBackToMenu" : "affordabilityToolBackToMenuSpanish",
                      text: lang === "en" ? "Affordability Tool Back to Menu" : "Affordability Tool Back to Menu Spanish"
                    });
                  }}
                  onHelpHover={() => {
                    this.setState({ isOpenAffordabilityTool: true });
                    this.sendMessage((document.documentElement.lang === "es" ? 'Asequibilidad de la cobertura del empleador' : 'Employer-coverage affordability'));
                    // #affordability-tool-logging
                    sendEventToDataLayer({
                      event: "chatbot",
                      eventDetails: {
                        category: "CiCi",
                        action: "Affordability Tool Clicks",
                        label: "Affordability Tool Help Tool Tip"
                      }
                    });
                    this.sendLoggingEventToBackend({
                      intentName: lang === "en" ? "Affordability Tool Clicks" : "Affordability Tool Clicks Spanish",
                      text: lang === "en" ? "Affordability Tool Help Tool Tip" : "Affordability Tool Help Tool Tip Spanish"
                    });
                  }}
                  // onHelpClick={() => {
                  //   this.setState({ isOpenAffordabilityTool: true });
                  //   this.sendMessage((document.documentElement.lang === "es" ? 'Asequibilidad de la cobertura del empleador' : 'Employer-coverage affordability'));
                  //   // #affordability-tool-logging
                  //   sendEventToDataLayer({
                  //     event: "chatbot",
                  //     eventDetails: {
                  //       category: "CiCi",
                  //       action: "Affordability Tool Clicks",
                  //       label: "Help"
                  //     }
                  //   });
                  //   this.sendLoggingEventToBackend({
                  //     intentName: lang === "en" ? "affordabilityToolHelpClick" : "affordabilityToolHelpClickSpanish",
                  //     text: lang === "en" ? "Affordability Tool Help Click" : "Affordability Tool Help Click Spanish"
                  //   });
                  // }}
                  onHelpClick={() => {
                    this.setState({ isOpenAffordabilityTool: true });
                    this.sendMessage((document.documentElement.lang === "es" ? 'Asequibilidad de la cobertura del empleador' : 'Employer-coverage affordability'));
                    // #affordability-tool-logging
                    sendEventToDataLayer({
                      event: "chatbot",
                      eventDetails: {
                        category: "CiCi",
                        action: "Affordability Tool Clicks",
                        label: "Help"
                      }
                    });
                  
                    this.sendLoggingEventToBackend({
                      intentName: lang === "en" ? "affordabilityToolHelpClick" : "affordabilityToolHelpClickSpanish",
                      text: lang === "en" ? "Affordability Tool Help Click" : "Affordability Tool Help Click Spanish"
                    });
                  }}
                  openATHelpModal={this.openATHelpModal}
                  contactUs={() => {
                    this.sendMessage('Contact Us');
                    this.setState({ isOpenAffordabilityTool: false });
                  }}
                  sendMessage={this.sendMessage}
                  sendLoggingEventToBackend={this.sendLoggingEventToBackend}
                  lang={document.documentElement.lang}
                  sendEventToBackend={this.sendEventToBackend}
                  localeValue={this.state.localeValue}
                />) :
              null
            }


            {this.state.isOpenSb260Wizard ?
              <Sb260Wizard
                lang={document.documentElement.lang}
                sendEventToDataLayer={sendEventToDataLayer}
                sendActivity={(activity) => {
                  this.state.store.dispatch({
                    meta: {
                      method: "keyboard"
                    },
                    type: 'DIRECT_LINE/POST_ACTIVITY',
                    payload: {
                      activity: {
                        locale: document.documentElement.lang,
                        pageUrl: window.location.pathname,
                        gaId: getGaId(),
                        ...activity
                      }
                    }
                  });
                }}
                event={this.state.sb260Event}
                serviceHourData={this.state.serviceHourData}
                isOpen={this.state.isServiceHour}
                close={() => {
                  this.setState({ isOpenSb260Wizard: false });
                  this.sendMessage((document.documentElement.lang === "es" ? 'Mi Medi-Cal está terminando' : 'My Medi-Cal is ending'))
                  sendEventToDataLayer({
                    event: "chatbot",
                    eventDetails: {
                      name: "cici_sb260_exit_menu_click"
                    }
                  });
                  this.sendLoggingEventToBackend({
                    intentName: "Exit",
                    text: "Exit Flow"
                  })
                }}
                chatWithRepresentative={() => {
                  this.setState({ isOpenSb260Wizard: false });
                  this.sendMessage((document.documentElement.lang === "es" ? 'Chatear con un representante' : 'Chat with representative'));
                  sendEventToDataLayer({
                    event: "chatbot",
                    eventDetails: {
                      name: "cici_mct_chat_with_representative"
                    }
                  });
                  this.sendLoggingEventToBackend({
                    intentName: "Chat with representative",
                    text: "Chat with representative"
                  })
                }}
                closeContactSupport={() => {
                  this.setState({ isOpenSb260Wizard: false });
                  this.sendMessage((document.documentElement.lang === "es" ? 'Comunícate con el Centro de apoyo' : 'Contact Support'));
                  sendEventToDataLayer({
                    event: "chatbot",
                    eventDetails: {
                      name: "cici_mct_contact_support"
                    }
                  });
                  this.sendLoggingEventToBackend({
                    intentName: "Contact Support",
                    text: "Contact Support"
                  })
                }}

                // onSkip={() =>{
                //   this.setState({ isOpenSb260Wizard: false });
                //   this.sendMessage((document.documentElement.lang === "es" ? 'Asequibilidad de la cobertura del empleador' : 'My medi-cal is ending'));
                // }}

                onHelpClick={() => {
                  this.setState({ isOpenSb260Wizard: true });
                  this.sendMessage((document.documentElement.lang === "es" ? 'Asequibilidad de la cobertura del empleador' : 'My Medi-Cal is ending'));
                  // #SB-260-tool-logging
                  sendEventToDataLayer({
                    event: "chatbot",
                    eventDetails: {
                      name: "cici_sb260_help_menu_click"
                    }
                  });
                  this.sendLoggingEventToBackend({
                    intentName: "SB260HelpClick",
                    text: "SB-260 Help Click"
                  });
                }}
                contactUs={() => {
                  this.sendMessage('Contact Us');
                  this.setState({ isOpenAffordabilityTool: false });
                }}
                sendMessage={this.sendMessage}
                sendLoggingEventToBackend={this.sendLoggingEventToBackend}
              /> :
              null
            }

              {this.state.isOpenEnrollmentStatusWizard ?
              <EnrollmentStatusWizard
                lang={document.documentElement.lang}
                sendEventToDataLayer={sendEventToDataLayer}
                sendActivity={(activity) => {
                  this.state.store.dispatch({
                    meta: {
                      method: "keyboard"
                    },
                    type: 'DIRECT_LINE/POST_ACTIVITY',
                    payload: {
                      activity: {
                        locale: document.documentElement.lang,
                        pageUrl: window.location.pathname,
                        gaId: getGaId(),
                        ...activity
                      }
                    }
                  });
                }}
                event={this.state.enrollmentStatusEvent}
                close={() => {
                  this.setState({ isOpenEnrollmentStatusWizard: false });
                  this.sendMessage((document.documentElement.lang === "es" ? 'Mi cuenta de Covered California' : 'My Covered California account'))
                  sendEventToDataLayer({
                    event: "chatbot",
                    eventDetails: {
                      name: "cici_sb260_exit_menu_click"
                    }
                  });
                  this.sendLoggingEventToBackend({
                    intentName: "Exit",
                    text: "Exit Flow"
                  })
                }}
                chatWithRepresentative={() => {
                  this.setState({ isOpenEnrollmentStatusWizard: false });
                  this.sendMessage((document.documentElement.lang === "es" ? 'Chatear con un representante' : 'Chat with representative'));
                  sendEventToDataLayer({
                    event: "chatbot",
                    eventDetails: {
                      name: "cici_mct_chat_with_representative"
                    }
                  });
                  this.sendLoggingEventToBackend({
                    intentName: "Chat with representative",
                    text: "Chat with representative"
                  })
                }}
                closeContactSupport={() => {
                  this.setState({ isOpenEnrollmentStatusWizard: false });
                  this.sendMessage((document.documentElement.lang === "es" ? 'Comunícate con el Centro de apoyo' : 'Contact Support'));
                  sendEventToDataLayer({
                    event: "chatbot",
                    eventDetails: {
                      name: "cici_mct_contact_support"
                    }
                  });
                  this.sendLoggingEventToBackend({
                    intentName: "Contact Support Enrollment Support",
                    text: "Contact Support Enrollment status"
                  })
                }}

                // onSkip={() =>{
                //   this.setState({ isOpenSb260Wizard: false });
                //   this.sendMessage((document.documentElement.lang === "es" ? 'Asequibilidad de la cobertura del empleador' : 'My medi-cal is ending'));
                // }}

                onHelpClick={() => {
                  this.setState({ isOpenEnrollmentStatusWizard: true });
                  this.sendMessage((document.documentElement.lang === "es" ? 'Mi cuenta de Covered California' : 'My Covered California account'));
                  // #SB-260-tool-logging
                  sendEventToDataLayer({
                    event: "chatbot",
                    eventDetails: {
                      name: "cici_enrollment_status_help_menu_click"
                    }
                  });
                  this.sendLoggingEventToBackend({
                    intentName: "EnrollmentStatusHelpClick",
                    text: "Enrollment status Help Click"
                  });
                }}
                contactUs={() => {
                  this.sendMessage('Contact Us');
                  this.setState({ isOpenAffordabilityTool: false });
                }}
                sendMessage={this.sendMessage}
                sendLoggingEventToBackend={this.sendLoggingEventToBackend}
                serviceHourData={this.state.serviceHourData}
                isOpen={this.state.isServiceHour}
              /> :
              null
            }

            <ReactWebChat
              activityMiddleware={activityMiddleware}
              attachmentMiddleware={attachmentMiddleware}
              directLine={this.state.directlineObject}
              locale={this.state.lang}
              styleOptions={styleOptions}
              userID={this.state.userId}
              store={this.state.store}
              handleNewMessageNotification={this.handleNewMessageCount}
              setInactivityTimeout={this.handleSetInactivityTimeout}
              clearInactivityTimeout={this.handleClearInactivityTimeout}
              showEndChatButton={this.handleShowEndChatButton}
              hideEndChatButton={this.handleHideEndChatButton}
              deactivateLiveAgentTimeout={this.handleDeactivateLiveAgentTimeout}
            //showPrivacyDisclaimer={this.handleShowPrivacyDisclaimer}
            />
          </div>
        ) : (
          <div
            style={{ backgroundColor: "#dfe6f3 !important", height: "100%" }}
            className={this.state.open === true ? "cici__window cici__window--active" : "cici__window"}
          >
            <CiciHeaderLoading
              handleMinimize={this.handleMinimize}
              handleKeyPress={this.handleKeyPress}
              lang={this.state.lang}
            />
            <div className="d-flex h-100 w-100" style={{ display: "flex", height: "100%", width: "100%" }}>
              <span className="m-auto" style={{ margin: "auto" }}>
                <img src="https://www.coveredca.com/images/loading.gif" alt="loading" />
              </span>
            </div>
          </div>
        )}

        {/* Change code below only! */}
        {this.state.showCiCi === true ? (
          <>
            <div className="closed-cici-section">
              {/* <div id="calculator-hq"></div> */}
              {!this.state.welcomed &&
                <div id="talk-bubble-container">
                  <TalkBubble cici={this} text={talkBubbleMessage} lang={this.state.lang} />
                </div>
              }

              <div id="cici-icon-container">
                <div id="nmt-cb-container">
                  {/* Close Button */}
                  <button onClick={this.handleShowCiCi} className="close-cici-button">
                    <span className="material-icons">clear</span>
                  </button>

                  {/* New Message notification icon */}
                  {showNewMessage && !this.isAutoTrigger() && <div className="new-message-noti">{this.state.newMessages}</div>}
                </div>


                {/* CiCi icon */}
                <img
                  onClick={this.handleOpen}
                  className={
                    this.state.open === false
                      ? "cici__minimized-icon cici__minimized-icon--active cici__target"
                      : "cici__minimized-icon cici__target"
                  }
                  src={minimizedCiciImage}
                  alt="cici minimized"
                >
                </img>
                {/* <img onClick={this.handleOpen} className="cici__minimized-icon__hover__active" src={minimizedCiciImageHover} alt="cici minimized hover"></img> */}

                <img
                  onClick={this.handleOpen}
                  className={
                    this.state.open === false
                      ? "cici__minimized-icon cici__minimized-icon__hover__active cici__target--hover"
                      : "cici__minimized-icon cici__target--hover"
                  }
                  src={minimizedCiciImageHover}
                  alt="cici minimized hover"
                >
                </img>
              </div>
            </div>
          </>
        ) : null}
      </div>
      </>
    );
  }
}

export default Cici;
