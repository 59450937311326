import React from "react";
import './ClearOverlay.css';

function ClearOverlay(props){
    return(
        <div onClick={props.onClickHandler} className="clear-overlay">

        </div>
    )
}

export default ClearOverlay;