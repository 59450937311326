function getMatchedIdealQuestions(userInput, idealQuestions){
  let matchedIdealQuestions = [];
  for(var i=0; i<idealQuestions.length && matchedIdealQuestions.length<4; i++ ){
    if(idealQuestions[i].toLowerCase().indexOf(userInput.toLowerCase()) > -1){
      matchedIdealQuestions.push(idealQuestions[i]);
    }
  }
  return matchedIdealQuestions;
}

export default getMatchedIdealQuestions;