import React, {useEffect} from 'react';
import WizardUtilityOption from '../../components/Wizard/WizardUtilityOption';

function Main({onClose, lang}){

    const menuText = lang === "en" ? "Menu" : "Menú";
    
    return(
        <WizardUtilityOption
            onClick={onClose}
            onHover={()=>console.log("hover")}
        >
            {menuText}
        </WizardUtilityOption>
    )
}

export default Main;