import React from 'react';

function WizardContent(props) {
    const { children } = props;
    return (
        <div className='wizard__content'>
            {children}
        </div>
    )
}

export default WizardContent;