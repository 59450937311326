import ConsoleLogger from "../console-logger/ConsoleLogger";
const logger = new ConsoleLogger();
class LocalStorageWatermarkRepository {
    /**
     * dependency: localStorage client (globally available)
     * storage should have an interface of:
     *   getItem(key) 
     *   setItem(key, value)
     *   removeItem(key)
     *   clear()
     */
    constructor(localStorage){
        this.localStorage = localStorage;
    }
    initialize(conversationId){
        logger.log("LocalStorageWatermarkRepository->initialize->conversationId", conversationId);
        logger.log("this.localStorage.getItem(conversationId)", this.localStorage.getItem(conversationId));
        if(!this.localStorage.getItem(conversationId)){
            return 0;
        }
        return this.localStorage.getItem(conversationId);
    }
    getByConversationId(conversationId){
        const watermark = this.localStorage.getItem(conversationId);
        if(!watermark){
            throw new Error("conversationId does not exist");
        }
        return watermark;
    }
    update(conversationId, watermarkValue) {
        this.localStorage.setItem(conversationId, watermarkValue);
    }
}

export default LocalStorageWatermarkRepository;