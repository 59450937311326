const prod = {
  url: {
    BASE_URL: '/cici-assets',
    API_URL: '' // uses relative path from proxy
  }
};

const dev = {
  url: {
    BASE_URL: '/cici-assets',
    API_URL: '' // uses relative path from proxy
  }
};

const local = {
  url: {
    BASE_URL: 'http://localhost:3000',
    API_URL: 'http://localhost:3005'
  }
};

const inApp = {
  url: {
    BASE_URL: "https://hbexstorage.blob.core.windows.net/zzz-test-ramon/cici-assets",
    API_URL: "https://www.coveredca.com"
  }
}

let environment = '';

if(isInApp() && isProduction()){
  environment = inApp;
}else{
  switch(process.env.NODE_ENV) { 
    case 'local':
      environment = local;
      break;
    case 'development':
      environment = dev;
      break;
    default: 
      environment = prod;
  }
}

function isInApp(){
  return process.env.REACT_APP_IN_APPLICATION === "true";
}

function isProduction(){
  return process.env.NODE_ENV === "production";
}


export const configURLs = environment;