import React from 'react';

class FeedbackButton extends React.Component {
  
  render() {
    const positive = {
      color: "#008B00",
      transitionProperty: "color",
      transitionDuration: ".2s",
      transistionTimingFunction: "ease-out"
    }
    const negative = {
      color: "#D50000",
      transitionProperty: "color",
      transitionDuration: ".2s",
      transistionTimingFunction: "ease-out"
    }

    if (!this.props.activity){
      return null;
    }
    return (
      <div className="button-bar">
        <ul className="button-bar__list">
          <li><label className="feedback-button" onClick={ this.props.handleUpvoteButton }><i className="material-icons feedback-button__positive" style={this.props.positive ? positive : null}>thumb_up</i></label></li>
          <li><label className="feedback-button" onClick={ this.props.handleDownvoteButton }><i className="material-icons feedback-button__negative" style={this.props.negative ? negative : null}>thumb_down</i></label></li>
        </ul>
      </div>
    );
  }
  
}

export default FeedbackButton;