import { configURLs } from '../../Constants'

function avatarManager() {
  var messageList = document.querySelectorAll('.css-1qyo5rb'); 
  var agentMessage = false;
  
  for (var i = 0; i < messageList.length; i++) {

    if (messageList[i].getElementsByClassName('image').length > 0) {

      //Check to see if there is a card i.e. non text message so that it is skipped and does not cause style application to fail!
      
      if(messageList[i].getElementsByClassName("attachment").length > 0 || messageList[i].getElementsByClassName("suggestedActions").length > 0)
      {
        //console.log("card exists so skipping it!!")
        continue;
      }
      if (!messageList[i].getElementsByClassName('markdown css-o3xlyv')[0]) {
        if (agentMessage) {
          messageList[i].getElementsByClassName('css-1tdb3h1 image')[0].getElementsByTagName('img')[0].setAttribute('src', configURLs.url.BASE_URL + '/images/botImages/chatbot_avatar.svg');
        }
        break;
      } else {
        
        if (agentMessage) {
          if(messageList[i].getElementsByClassName('message-container').length > 0)
              messageList[i].getElementsByClassName('message-container')[0].getElementsByClassName('css-1tdb3h1 image')[0].getElementsByTagName('img')[0].setAttribute('src', configURLs.url.BASE_URL + '/images/botImages/chatbot_avatar.svg');
        }
        // Adding check that <p> tag exists
        if(typeof messageList[i].getElementsByClassName('markdown css-o3xlyv')[0].getElementsByTagName('p')[0] !== 'undefined'){
          //console.log(messageList[i].getElementsByClassName('markdown css-o3xlyv')[0].getElementsByTagName('p'))
          if (messageList[i].getElementsByClassName('markdown css-o3xlyv')[0].getElementsByTagName('p')[0].textContent.indexOf('Your request has been accepted by an agent.') > -1) {
            agentMessage = true;
          } else if (messageList[i].getElementsByClassName('markdown css-o3xlyv')[0].getElementsByTagName('p')[0].textContent.indexOf('The chat is currently unavailable. Please try again later.') > -1 ||
            messageList[i].getElementsByClassName('markdown css-o3xlyv')[0].getElementsByTagName('p')[0].textContent.indexOf('Your live chat session has ended.') > -1) {
            
              //console.log(messageList[i].getElementsByClassName('markdown css-o3xlyv')[0].getElementsByTagName('p')[0].textContent)
            agentMessage = false;
            messageList[i].getElementsByClassName('message-container')[0].getElementsByClassName('css-1tdb3h1 image')[0].getElementsByTagName('img')[0].setAttribute('src', configURLs.url.BASE_URL + '/images/botImages/chatbot_bot_avatar.svg');
          }
        }
      }
    }
}
}

export default avatarManager;