import React from 'react';
import WizardUtilities from './WizardUtilities';
import WizardContent from './WizardContent';
import './styles/Wizard.css';
import WizardUtilityOption from './WizardUtilityOption';
import WizardPage from './WizardPage';

function Wizard(props){
    const {utilityOptions, pages} = props;
    const wizardOptions = utilityOptions.map((utilityOption, index) => {return index ? <>| {utilityOption}</> : utilityOption}) ;
    const wizardPages = pages.length ? pages.map(page => <WizardPage>{page}</WizardPage>) : null;
    return (
        <div className='wizard'>
            {props.children}
            <WizardUtilities>
                {wizardOptions}
            </WizardUtilities>
            <WizardContent>
                {wizardPages}
            </WizardContent>
        </div>
    );
}

export default Wizard;