import React from 'react';
import './ATPopupModal.css';

function ATHelpModalAfterServiceHour(props) {
    const { localeValue, openATHelpModal, isOpenHelpModal, serviceHourData, sendLoggingEventToBackend } = props;
    const modalTitle = localeValue === "en" ? "We’ll be back soon!" : "¡Regresamos pronto!";
    const supportLink = localeValue === "en" ? "https://www.coveredca.com/support/" : "https://www.coveredca.com/espanol/support/";

    const closeModal = () => openATHelpModal(false);

    let weekDayHours = [];
    let extendedHours = [];

    if (serviceHourData && serviceHourData.en && serviceHourData.es) {
        weekDayHours = localeValue === "en" ? serviceHourData.en.weekDayHours : serviceHourData.es.weekDayHours;
        extendedHours = localeValue === "en" ? serviceHourData.en.extendedHours : serviceHourData.es.extendedHours;
    } else {
        console.error("serviceHourData is not defined or missing properties");
    }

    const validExtendedHours = extendedHours.filter(hour => hour.trim() !== "");

    if (isOpenHelpModal) {
        const intentName = localeValue === "en" ? "HelpModalOpenInAppAfterHours" : "HelpModalOpenInAppAfterHoursSpanish";
        const text = localeValue === "en" ? "Help Modal Open In App After Hours" : "Help Modal Open In App After Hours Spanish";
        sendLoggingEventToBackend({
            intentName,
            text,
        });
    }

    return (
        <>
            {isOpenHelpModal && (
                <div className="atModal ciciModal--active">
                    <div className="atModal__modal">
                        <div className="atModal__header">
                            <span className="material-icons atModal__close" tabIndex={0} onClick={closeModal} onKeyPress={closeModal}>
                                close
                            </span>
                        </div>
                        <div className="atModal__body">
                            <div className="text-left">
                                <span className='atModal__modal-title'>{modalTitle}</span>
                                <div className='atModal__modal-content after-service-hour'>
                                    <p className='p__first'>
                                        {localeValue === "en" ? "Our hours are:" : "Nuestro horario es:"} <br />
                                        {weekDayHours.join('\n')}
                                    </p>
                                    {validExtendedHours.length > 0 && (
                                        <>
                                            <br /><br />
                                            <p className='p__first'>
                                                {localeValue === "en" ? "Extended hours are:" : "Horas extendidas:"} <br />
                                                {validExtendedHours.map((hour, index) => <span key={index}>{hour}<br /></span>)}
                                            </p>
                                        </>
                                    )}
                                    <p className='p__second'>
                                        {localeValue === "en" ?
                                            <>For general questions, you can visit our <a href={supportLink}>Support Page</a>.</>
                                            :
                                            <>Si tienes preguntas generales, puedes visitar nuestra <a href={supportLink}>página de Apoyo</a>.</>
                                        }
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default ATHelpModalAfterServiceHour;