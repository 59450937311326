import { useState, useEffect } from 'react';

const useAppId = () => {
  const [AppId, setAppId] = useState('');

  useEffect(() => {
    setAppId(window.appValue);
  }, []);

  return { AppId, setAppId };
};

export default useAppId;
