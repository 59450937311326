import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import translations from "../EnrollmentStatusTranslations";

function EnrollmentStatusResultsNoResults({onClose, onNext, lang, sendLoggingEventToBackend}){
    const [serviceCenterHours, setServiceCenterHours] = useState({
        en: "",
        es: "",
      });

    const{ noEnrollmentStatusHeading, noEnrollmentStatusBody, serviceCenterHeading, provideFeedback, exitButton} = translations[lang]

    useEffect(() => {
        const fetchData = async () => {
          const url = "https://charming-mallard.cloudvent.net/api/hours.json";
          const response = await fetch(url);
          const data = await response.json();
          Object.keys(data).forEach(key => {
            if (key === "en") {
              data[key] = data[key].replace('p.m.Saturday', 'p.m.\nSaturday');
            } else if (key === "es") {
              data[key] = data[key].replace('p.m.Sábado', 'p.m.\nSábado');
            }
          });
          setServiceCenterHours(data);
        };
        fetchData().catch((error) => console.error("Error fetching data:", error));
      }, []);

    useEffect(() => {
        lang === "es" ?
        sendLoggingEventToBackend({
            intentName: "enrollmentStatusAuthenticateSpanishSuccess",
            text: "enrollmentStatusAuthenticateSpanishSuccess"
      }
      ) : sendLoggingEventToBackend({
          intentName: "enrollmentStatusAuthenticateSuccess",
          text: "enrollmentStatusAuthenticateSuccess"
      }
      )
    },[]);


    useEffect(() => {
        lang === "es" ?
        sendLoggingEventToBackend({
            intentName: "enrollmentStatusNoResultsSpanish",
            text: "enrollmentStatusNoResultSpanish"
      }
      ) : sendLoggingEventToBackend({
          intentName: "enrollmentStatusNoResult",
          text: "enrollmentStatusNoResult"
      }
      )
      },[]);

    return(
        <div className="position-relative h-100 d-flex flex-column">
            <h3 style={{marginBottom:'20px'}}><b>{noEnrollmentStatusHeading}</b></h3>
            <div style={{marginBottom:'20px'}}>
                <p>{noEnrollmentStatusBody}</p>
            </div>
            <div style={{marginTop:'10px'}}>
                <h5><b className="medicalEnrollment">{serviceCenterHeading}</b></h5>
                <hr style={{height:'2px', marginTop:'20px', marginBottom:'20px', border:'0', borderTop:'5px',color: '#A2AFCC'}}/>
                <p style={{textDecoration: 'underline', cursor: 'pointer', color: '#4F63F9', marginBottom:'0px'}}>(855) 708-4590</p>
                <p className="pb-5" >
                    {serviceCenterHours[lang].split("\n")[1]}<br/>  
                    {serviceCenterHours[lang].split("\n")[2]}
            </p>
            </div>
                <div className="form-group mt-auto row">
                    <button className="btn btn-block ac-pushButton" onClick={onNext}>{provideFeedback}</button>
                    <button className="btn btn-block btn-outline ac-pushButton" type="button" onClick={onClose}>{exitButton}</button>
                </div>
        </div>
    )

}

export default EnrollmentStatusResultsNoResults;