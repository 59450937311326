import React, { Component } from 'react';
import './CiciModal.css';

class CiciModal extends Component {

  render() {
    const lang = document.documentElement.lang;
    const { localeValue } = this.props;
    let modalContent;
    let modalTitle;

    if (this.props.modalType === 'privacy') {
      if (lang === 'es') {
        modalTitle = 'privacidad';
        modalContent = (
          <div>
            <p>No ingrese ninguna información de identificación personal, como SSN, fecha de nacimiento, número de caso o apellido.</p>
            <p><a href="https://www.coveredca.com/espanol/privacy/">Política de privacidad</a></p>
          </div>
        )
      } else {
        modalTitle = "Privacy";
        modalContent = (
          <div>
            <p>Please do not enter any personally identifiable information such as SSN, Date of Birth, Case Number or last name.</p>
            <p><a href="https://www.coveredca.com/privacy/">Privacy Policy</a></p>
          </div>
        )
      }
    }
    else if (this.props.modalType === 'talk-to-cici') {
      if (lang === 'es') {
        modalTitle = "Cómo hacer preguntas";
        modalContent = (
          <div>
            <p>CiCi funciona mejor con frases cortas y simples.</p>
            <p>Por favor, pregúntele a CiCi una pregunta a la vez.</p>
            <p>CiCi no puede responder preguntas sobre su solicitud o detalles específicos del caso, así que por favor no use su información personal.</p>
            <p><strong>Gracias y disfruta hablando con CiCi!</strong></p>
          </div>
        );
      } else {
        modalTitle = "How to Ask Questions";
        modalContent = (
          <div>
            <p>CiCi does best with short and simple sentences.</p>
            <p>Please ask CiCi one question at a time.</p>
            <p>CiCi can't answer questions about your application or case specific details, so please don't use your personal information.</p>
            <p><strong>Thank you and enjoy talking to CiCi!</strong></p>
          </div>
        );
      }
    }
    else if (this.props.modalType === 'live-agent-notification') {
      if (lang === 'es') {
        modalTitle = "Cómo hablar con CiCi";
        modalContent = (
          <div>
            <div>
              <p>CiCi funciona mejor con frases cortas y simples.</p>
              <p>Por favor, pregúntele a CiCi una pregunta a la vez.</p>
              <p>CiCi no puede responder preguntas sobre su solicitud o detalles específicos del caso, así que por favor no use su información personal.</p>
              <p><strong>Gracias y disfruta hablando con CiCi!</strong></p>
            </div>
            <div className="ciciModal__btn-container--inactivity">
              <button className="btn btn-primary" onClick={this.props.hidePrivacyDisclaimer} onKeyPress={(e) => { this.props.handleKeyPress(e, this.props.hidePrivacyDisclaimer) }}>Okay. I understand.</button>
            </div>
          </div>
        );
      } else {
        modalTitle = "Privacy Disclaimer For Live Chat";
        modalContent = (
          <div>
            <div>
              <p>To protect your Personal Identifiable Information/Protected Health Information Chat is not able to assist with case specific questions.</p>
              <div className="ciciModal__p-tag-style">Please <b>Do Not</b> send any Personal Identifiable Information or Protected Health Information such as:<ul className="ciciModal__ul-style"> <li>social security number</li> <li>place of birth</li> <li>mother’s maiden name</li> <li>education</li> <li>financial transactions</li> <li>medical or employment information.</li></ul></div>
              <p>For case specific questions please contact the Covered California service center at 1-800-300-1506.</p>
            </div>
            <div className="ciciModal__btn-container--inactivity">
              <button className="btn btn-primary" onClick={this.props.hidePrivacyDisclaimer} onKeyPress={(e) => { this.props.handleKeyPress(e, this.props.hidePrivacyDisclaimer) }}>Okay. I understand.</button>
            </div>
          </div>
        );
      }
    }
    else if (this.props.modalType === 'live-agent-timeout') {
      if (lang === 'es') {
        modalTitle = "Alerta de inactividad";
        modalContent = (
          <div>
            <div className="text-left">
              <p>Debido a la inactividad, estás a punto de desconectarte del chat en vivo.</p>
              <p>Elija 'Continuar' para seguir chateando o 'Finalizar chat' para desconectarse.</p>
            </div>
            <div className="ciciModal__btn-container--inactivity">
              <button className="btn btn-primary" onClick={this.props.deactivateModals} onKeyPress={(e) => { this.props.handleKeyPress(e, this.props.deactivateModals) }}>Continuar</button>
              <button className="btn btn-primary" onClick={this.props.endLiveAgentButtonClick} onKeyPress={(e) => { this.props.handleKeyPress(e, this.props.endLiveAgentButtonClick) }}>Finalizar chat</button>
            </div>
          </div>
        );
      } else {
        modalTitle = "Inactivity Alert";
        modalContent = (
          <div>
            <div className="text-left">
              <p>Because of inactivity you are about to be disconnected from live chat.</p>
              <p>Please choose 'Continue' to keep chatting, or 'End Chat' to disconnect.</p>
            </div>
            <div className="ciciModal__btn-container--inactivity">
              <button className="btn btn-primary" onClick={this.props.deactivateModals} onKeyPress={(e) => { this.props.handleKeyPress(e, this.props.deactivateModals) }}>Continue</button>
              <button className="btn btn-primary" onClick={this.props.endLiveAgentButtonClick} onKeyPress={(e) => { this.props.handleKeyPress(e, this.props.endLiveAgentButtonClick) }}>End Chat</button>
            </div>
          </div>
        );
      }
    }
    else if (this.props.modalType === 'ATConfirmation') {
      if (localeValue === 'es') {
        modalTitle = 'Wait! Before You Exit:';
        modalContent = (
          <div>
            <div className="text-left">
              <p>If you leave now, you'll lose any information you entered.</p>
              <p>Are you sure you want to exit?</p>
            </div>
            <div className="atModal__btn-container">
              <button className="btn btn-outline-primary" onClick={this.props.endLiveAgentButtonClick}
                onKeyPress={(e) => { this.props.handleKeyPress(e, this.props.endLiveAgentButtonClick) }}>
                Yes, Exit Without Saving
              </button>
              <button className="btn btn-primary" onClick={this.props.deactivateModals}
                onKeyPress={(e) => { this.props.handleKeyPress(e, this.props.deactivateModals) }}>
                No, Return to Tool
              </button>
            </div>
          </div>
        )
      } else {
        modalTitle = "Wait! Before You Exit:";
        modalContent = (
          <div>
            <div className="text-left">
              <p>If you leave now, you'll lose any information you entered.</p>
              <p>Are you sure you want to exit?</p>
            </div>
            <div className="atModal__btn-container">
              <button className="btn btn-outline-primary" onClick={this.props.endLiveAgentButtonClick}
                onKeyPress={(e) => { this.props.handleKeyPress(e, this.props.endLiveAgentButtonClick) }}>
                Yes, Exit Without Saving
              </button>
              <button className="btn btn-primary" onClick={this.props.deactivateModals}
                onKeyPress={(e) => { this.props.handleKeyPress(e, this.props.deactivateModals) }}>
                No, Return to Tool
              </button>
            </div>
          </div>
        )
      }
    }

    if (this.props.modalType !== 'live-agent-end-chat') {
      return (
        <div className={"ciciModal" + this.props.status}>
          <div className="ciciModal__modal">
            <div className="ciciModal__header">
              <h4>{modalTitle}</h4>
              {(this.props.modalType === 'live-agent-timeout' || this.props.modalType === 'live-agent-notification') ? <span></span> :
                <span className="ciciModal__close" tabIndex={0} id={this.props.modalType + "__close"} onKeyPress={(e) => { this.props.handleKeyPress(e, this.props.deactivateModals) }}>×</span>
              }
            </div>
            <div className="ciciModal__body">
              {modalContent}
            </div>
          </div>
        </div>
      );
    }
    else if (this.props.modalType === 'live-agent-end-chat') {
      return (
        <div className={"ciciModal__end__chat__button" + this.props.status}>
          <button className="btn btn-primary" onClick={this.props.endLiveAgentButtonClick} onKeyPress={(e) => { this.props.handleKeyPress(e, this.props.endLiveAgentButtonClick) }}>End Live Chat</button>
        </div>
      )
    }
  }
}

export default CiciModal;