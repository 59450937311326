import React from 'react';
import ConnectedAttachmentWithFeedback from './ConnectedAttachmentWithFeedback';
import ConnectedAttachmentWithLiveChat from './ConnectedAttachmentWithLiveChat'
import ConnectedAttachmentWithWelcomeMessage from './ARP-welcome-message/ConnectedAttachmentWithWelcomeMessage'
import ConnectedAttachmentWithPasscode from './password-reset/ConnectedAttachmentWithPasscode';
import ConnectedAttachmentWithPassword from './password-reset/ConnectedAttachmentWithPassword';
import ConnectedAttachmentWithUsernameDob from './password-reset/ConnectedAttachmentWithUsernameDob';
import ConnectedAttachmentWithOtpType from './password-reset/ConnectedAttachementWithOtpType';
import ConnectedAttachmentWithEmailDob from './password-reset/ConnectedAttachmentWithEmailDob';
import ConnectedAttachmentWithContactUs from './password-reset/ConnectedAttachmentWithContactUs';

const attachmentMiddleware = () => next => card => {
  const welcomeRole = card.activity.from.role === "user";
  if (card.activity.type !== 'typing' && !welcomeRole && card.activity.hasOwnProperty("entities") && card.activity.entities[0].type === "welcomeMessageARP") {
    return (
      <ConnectedAttachmentWithWelcomeMessage activity={card.activity}>
        {next(card)}
      </ConnectedAttachmentWithWelcomeMessage>
    
    );
  }
  
  switch(card.attachment.contentType) {
    case 'application/contactUsCard':
      return (
        <ConnectedAttachmentWithContactUs activity={card.activity}>
          {next(card)}
        </ConnectedAttachmentWithContactUs>
      );
    case 'application/usernameDobCardForgotUsername':
      return (
        <ConnectedAttachmentWithEmailDob activity={card.activity}>
          {next(card)}
        </ConnectedAttachmentWithEmailDob>
      );
    case 'application/feedbackcard':
      //console.log(card)
      return (
        <ConnectedAttachmentWithFeedback activity={card.activity}>
          {next(card)}
        </ConnectedAttachmentWithFeedback>
      );
    case 'application/usernameDobCard':
      return (
        <ConnectedAttachmentWithUsernameDob activity={card.activity}>
          {next(card)}
        </ConnectedAttachmentWithUsernameDob>
      );

    case 'application/otpTypeCard':
      return (
        <ConnectedAttachmentWithOtpType activity={card.activity}>
          {next(card)}
        </ConnectedAttachmentWithOtpType>
      );
    case 'application/passcodeCard':
      return (
        <ConnectedAttachmentWithPasscode activity={card.activity}>
          {next(card)}
        </ConnectedAttachmentWithPasscode>
      );
    case 'application/passwordCard':
      return (
        <ConnectedAttachmentWithPassword activity={card.activity}>
          {next(card)}
        </ConnectedAttachmentWithPassword>
      );
    case 'application/liveChatCard':
      //console.log(card)
      return (
        <ConnectedAttachmentWithLiveChat activity={card.activity}>
          {next(card)}
        </ConnectedAttachmentWithLiveChat>
      );   
    case 'application/vnd.microsoft.card.adaptive':
      // if(card.attachment.content.actions[0].data.title !== "helpOnDemand")
      // {
      //   return next(card);
      // }
      return next(card);
    default:
      return next(card);
  }
};

export default attachmentMiddleware;