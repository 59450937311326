import { useEffect, useState } from "react";
import React from "react";
import sendEventToDataLayer from "../../../datalayer-event-logger-for-transactions/sendEventToDataLayer";
import translations from "../EnrollmentStatusTranslations";

function EnrollmentStatusContactSupport({
  onClose,
  sendLoggingEventToBackend,
  lang,
  chatWithRepresentative
}) {
  const [serviceCenterHours, setServiceCenterHours] = useState({
    en: "",
    es: "",
  });
  const {
    serviceCenterHoursHeading,
    contactSupportHeading,
    contactSupportBody1,
    contactSupportBody2,
    authButton1,
    onNext,
    exitButton,
  } = translations[lang];

  useEffect(() => {

    const fetchData = async () => {
      const url = "https://charming-mallard.cloudvent.net/api/hours.json";
      const response = await fetch(url);
      const data = await response.json();
      Object.keys(data).forEach(key => {
        if (key === "en") {
          data[key] = data[key].replace('p.m.Saturday', 'p.m.\nSaturday');
        } else if (key === "es") {
          data[key] = data[key].replace('p.m.Sábado', 'p.m.\nSábado');
        }
      });
      setServiceCenterHours(data);
    };
  
    fetchData().catch((error) => console.error("Error fetching data:", error));
  }, []);
  

  return (
    <div className="h-100 d-flex flex-column">
      <h2>{contactSupportHeading}</h2>
      <div className="pt-3"> 
        <p>{contactSupportBody1}</p>
        <p>{contactSupportBody2}</p>
      </div>
      {lang === "en" ? (
                <p className='pb-5'>
                     You can <span onClick={chatWithRepresentative} style={{textDecoration: 'underline', cursor: 'pointer', color: '#4F63F9'}}>Live Chat</span> or call us at (855) 708-4590.
                </p>
            ) : (
                <p className='pb-5'>
                    Puedes <span onClick={chatWithRepresentative} style={{textDecoration: 'underline', cursor: 'pointer', color: '#4F63F9'}}>Chatear en vivo</span> o llamarnos al (855) 708-4590.
                </p>
            )}
      <h4 style={{fontSize: "19px", fontWeight: "bold", marginTop: "-21px"}}>{serviceCenterHoursHeading}</h4>
      <p className="pb-5" >
        {serviceCenterHours[lang].split("\n")[1]}<br />  
        {serviceCenterHours[lang].split("\n")[2]}
      </p>
      <div className="form-group mt-auto">
        <button className="btn btn-block ac-pushButton" onClick={onNext}>{authButton1}</button>
        <button className='btn btn-block btn-outline ac-pushButton' onClick={onClose}>{exitButton}</button>
      </div>
    </div>
  );
}

export default EnrollmentStatusContactSupport;
