import enrollmentStatusResults from "./EnrollmentStatusResults";
import React from "react";
import { useState } from "react";
import ReactInputMask from "react-input-mask";
import moment from "moment";
import translations from "../EnrollmentStatusTranslations";
import { useEffect } from "react";
import sendEventToDataLayer from "../../../datalayer-event-logger-for-transactions/sendEventToDataLayer";
import WizardErrorMessage from "../../../components/Wizard/WizardErrorMessage";

function EnrollStatusAuthentication(props) {
  const { lang = "en", onBack } = props;
  const {
    authHeading,
    intro,
    ssnLabel,
    zipLabel,
    dobLabel,
    authButton1,
    dobErrorMessage,
    ssnErrorMessage,
    zipErrorMessage,
    exitButton,
  } = translations[lang];

  const [ssn, setSsn] = useState(
    props.data && props.data.ssn ? props.data.ssn : ""
  );
  const [ssnClass, setSsnClass] = useState("form-control");

  const [dob, setDob] = useState(
    props.data && props.data.dob
      ? moment(props.data.dob).format("YYYY-MM-DD")
      : ""
  );
  const [dobClass, setDobClass] = useState("form-control");

  const [zip, setZip] = useState(
    props.data && props.data.zip ? props.data.zip : ""
  );
  const [zipClass, setZipClass] = useState("form-control");

  const [errors, setErrors] = useState(
    props.error && props.error !== "" ? [props.error] : []
  );
  const [inputType, setInputType] = useState(lang === "es" ? "text" : "date");
  const [isDobEmpty, setIsDobEmpty] = useState(true);

  const submit = props.onSubmit;
  const datePattern = lang === "es" ? "[0-9]{2}/[0-9]{2}/[0-9]{4}" : null;


  const isDobValid = (dob) => {
    if(dob.split("-")[0].length !== 4){
        return false;
    }
    if (!dob || dob === "") {
        return false;
    }
    return true;
}
const isSsnValid = (ssn) => {
    const cleanSsn = ssn.replaceAll("-", "");
    if(cleanSsn === ""){
        return false;
    }

    if (cleanSsn.indexOf("_") !== -1) {
        return false;
    }
    if (isNaN(cleanSsn)) {
        return false;
    }
    return true;
}
const isZipValid = (zip) => {
    if (zip.length !== 5) {
        return false;
    }
    if (isNaN(zip)) {
        return false;
    }
    return true;
}

const onDobUpdate = (dob) => {
    const year = dob.split("-")[0];
    const rest = dob.split("-").slice(1); 
    if (year.length <= 4) {
      setDob(`${year}-${rest.join("-")}`); 
    }
  };
  
  

const onSsnUpdate = (ssn) => {
    setSsn(ssn);
};
const onZipUpdate = (zip) => {
    setZip(zip);
}
const onSubmit = () => {
    const formErrors = []
    if (!isDobValid(dob)) {
        formErrors.push(dobErrorMessage);
    }
    if (!isSsnValid(ssn)) {
        formErrors.push(ssnErrorMessage);
    }
    if (!isZipValid(zip)) {
        formErrors.push(zipErrorMessage);
    }
    setErrors(formErrors);
    if (formErrors.length < 1) {
        submit({
            ssn: ssn.replaceAll("-",""),
            dob: moment(dob).format('YYYY-MM-DD'),
            zip
        });
        
    }
}


  useEffect(() => {
    if (props.error && props.error !== "") {
      setErrors([props.error]);
    }
  }, [props]);

  useEffect(() => {
    if (errors.indexOf(props.error) !== -1) {
      setErrors(errors.filter((error) => error !== props.error));
    }
  }, [zip, dob, ssn]);

  useEffect(() => {
    if (errors.indexOf(dobErrorMessage) === -1) {
      setDobClass("form-control");
    } else {
      setDobClass("form-control is-invalid");
    }
    if (errors.indexOf(ssnErrorMessage) === -1) {
      setSsnClass("form-control");
    } else {
      setSsnClass("form-control is-invalid");
    }
    if (errors.indexOf(zipErrorMessage) === -1) {
      setZipClass("form-control");
    } else {
      setZipClass("form-control is-invalid");
    }
    if (errors && errors.length > 0) {
      sendEventToDataLayer({
        event: "chatbot",
        eventDetails: {
          name: "cici_sb260_authentication_failure",
        },
      });
    }
  }, [errors]);

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      onSubmit();
    }
  };

  return (
    <form className="form h-100 d-flex flex-column" onKeyPress={handleKeyPress}>
      <h2>{authHeading}</h2>
      <p>{intro}</p>
      {errors && errors.length > 0 ? (
        <WizardErrorMessage> {errors[0]} </WizardErrorMessage>
      ) : null}
      <div className="form-group">
        <label className="form-label">{dobLabel}</label>
        <div className="date-input-container" style={{ position: "relative" }}>
            <input
              type="date"
              className={dobClass}
              value={dob}
              onChange={(e) => onDobUpdate(e.target.value)}
              onFocus={() => setInputType("text")}
              onBlur={(e) => {
                setInputType("date");
                setIsDobEmpty(e.target.value === "");
              }}
              id="dob"
            />
          
        </div>
      </div>

      <div className="form-group">
        <label className="form-label">{ssnLabel}</label>
        <ReactInputMask
          mask="999-99-9999"
          maskChar="_"
          id="ssn"
          className={ssnClass}
          type={"text"}
          placeholder="000-00-0000"
          value={ssn}
          onChange={(e) => onSsnUpdate(e.target.value)}
        />
      </div>

      <div className="form-group">
        <label className="form-label">{zipLabel}</label>
        <input
          className={zipClass}
          value={zip}
          maxLength={5}
          onBlur={(e) => onZipUpdate(e.target.value)}
          onChange={(e) => {
            const newValue = e.target.value.replace(/\D/g, "");
            if (newValue.length <= 5) {
              onZipUpdate(newValue);
            }
          }}
          type="text"
        />
      </div>

      <div className="form-group mt-auto">
        <button
          className="btn btn-block ac-pushButton"
          type="button"
          onClick={() => onSubmit({ ssn, dob, zip })}
        >
          {authButton1}
        </button>
        <button
          className="btn btn-block btn-outline ac-pushButton"
          onClick={onBack}
        >
          {exitButton}
        </button>
      </div>
    </form>
  );
}

export default EnrollStatusAuthentication;